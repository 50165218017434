import React, {useCallback, useMemo, useEffect, useState} from "react"
import {CircularProgress, Grid, IconButton, Paper, Tooltip, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {Close} from "@mui/icons-material";
import {useDropzone} from "react-dropzone";

export default function GeneratorUploader(props: any) {

    const {title, onDrop, onReject, fileType, onRemove, name, type, uploading, disabled} = props
    const {t} = useTranslation(["common"])

    if (uploading) {
        return (
            <Paper sx={{ p: 2 }} variant={"outlined"}>
                <Grid container spacing={2} justifyContent={"center"}>
                    <Grid item xs={"auto"} textAlign={"center"}>
                        <CircularProgress size={30} sx={{ mb: 1 }} />
                        <Typography variant={"body2"}>{t("loading")}</Typography>
                    </Grid>
                </Grid>
            </Paper>
        )
    }

    if (!!name) {
        return (
            <Paper sx={{ p: 2 }} variant={"outlined"}>
                <Grid container spacing={2} alignContent={"center"}>
                    <Grid item xs={12} md={9}>
                        <Typography variant={"body2"}><b>{t("name")}</b> {name}</Typography>
                        {!!type && <Typography variant={"body2"}><b>{t("type")}</b> {type}</Typography>}
                    </Grid>
                    <Grid item xs={12} md={3} textAlign={"right"}>
                        <Tooltip title={`${t("Delete")}`} placement={"top"} arrow>
                            <IconButton onClick={onRemove} sx={{ color: "black", mt: "-8px" }}>
                                <Close />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>

            </Paper>
        )
    }

    return <PaperDropZone accept={fileType} msg={title} {...{onDrop, onReject, disabled, t}} />
}

function PaperDropZone(props: any) {

    const {disabled, t} = props

    const [accept, setAccept] = useState<string>('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    const [msg, setMsg] = useState<string>(t("upload_template"));

    const styles = {
        base: {
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            padding: '20px',
            borderWidth: 2,
            borderRadius: 2,
            borderColor: '#eeeeee',
            borderStyle: 'dashed',
            backgroundColor: '#fafafa',
            color: '#bdbdbd',
            outline: 'none',
            transition: 'border .24s ease-in-out',
            cursor: disabled ? "default" : "pointer"
        },
        active: { borderColor: '#2196f3' },
        accept: { borderColor: '#00e676' },
        reject: { borderColor: '#ff1744' }
    }

    const onDrop = useCallback((acceptedFiles, fileRejections) => {
        if (acceptedFiles.length > 0 && props.onDrop) {
            props.onDrop(acceptedFiles);
        } else if (fileRejections.length > 0 && props.onDropReject) {
            props.onReject(fileRejections);
        }
    }, [props]);

    useEffect(() => {
        if (!!props?.accept) setAccept(props.accept)
        if (!!props?.msg) setMsg(props.msg)
    }, [props])

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
    } = useDropzone({onDrop, accept: accept});

    const style = useMemo(() => ({
        ...styles.base,
        ...(isDragActive ? styles.active : {}),
        ...(isDragAccept ? styles.accept : {}),
        ...(isDragReject ? styles.reject : {})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [isDragActive, isDragReject, isDragAccept]);

    return (
        <div className='container'>
            <div {...getRootProps({style})}>
                <input {...getInputProps()} {...{disabled}} />
                <p style={{textAlign: 'center'}}>{msg}</p>
            </div>
        </div>
    );
}