import React from "react";
// MUI
import {CircularProgress, Grid, TextField} from "@mui/material";
import {Button} from "@components";

export default function IndividualAccountForm(props: any) {

    const {
        credentials,
        setCredentials,
        canFinalize,
        loading,
        finish,
        t
    } = props

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <TextField
                    variant={"outlined"}
                    onChange={(e) => setCredentials({ ...credentials, attributes:
                            { ...credentials.attributes, name: e.target.value }
                    })}
                    id="name"
                    label={t("First_name")}
                    placeholder={t("First_name")}
                    autoFocus
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    variant={"outlined"}
                    onChange={(e) => setCredentials({ ...credentials, attributes:
                            { ...credentials.attributes, family_name: e.target.value }
                    })}
                    id="surname"
                    label={t("Surname")}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} textAlign={"center"}>
                <Button fullWidth secondary disabled={!canFinalize || loading} onClick={finish}>
                    {loading ? <CircularProgress size={21}/>: t("Finish")}
                </Button>
            </Grid>
        </Grid>
    );
}
