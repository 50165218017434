import React from "react"
// MUI
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Table, TableBody, TableCell,
    TableContainer,
    TableHead, TableRow,
    Typography
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
// custom icons
import {ReactComponent as QRCodesIcon} from "@assets/svg/apps/qrcode.svg";
import {ReactComponent as VcardsIcon} from "@assets/svg/apps/vcard.svg";
import {ReactComponent as UsersIcon} from "@assets/svg/services/users.svg";
import {ReactComponent as PackagingIcon} from "@assets/svg/apps/packaging.svg";
import {ReactComponent as DomainsIcon} from "@assets/svg/services/domains.svg";
import {ReactComponent as CampaignIcon} from "@assets/svg/apps/campaign.svg";
import {ReactComponent as HealthchecksIcon} from "@assets/svg/apps/healthcheck.svg";
// custom components
import ColorChip from "@components/Chips/ColorChip";


export default function PlansDetailedFeatures(props: any) {

    const {currency, t} = props

    const renderAccordionsList = () => {

        // Data functions
        function createData(name: string, standard: any, gold: any, platinum: any) {
            return {name, standard, gold, platinum};
        }

        const getChipTable = (value: any) => {
            switch (typeof value) {
                case "string":
                    if (value as string === "Free") {
                        return (
                            <ColorChip color="blue" value="Free"/>
                        );
                    } else if (value as string === "Unlimited") {
                        return <ColorChip color="blue" value="Unlimited"/>
                    }
                    return (
                        <Typography>{value as string}</Typography>
                    );
                case "number":
                    return (
                        <Typography>{currency}{value as any as string}</Typography>
                    );
                case "boolean":
                    const available = value as boolean;
                    if (available) {
                        return <CheckIcon style={{color: '#b4c74a'}}/>;
                    }
                    return <CloseIcon style={{color: '#bfbfbf'}}/>;
            }
        }

        // Data content
        const packagingFeaturesRows = [
            createData(t("Included_in_plan"), false, "Extra module", "Extra module"),
            createData(t("Precise_location_module"), false, true, true),
            createData(t("SKU_management"), false, true, true),
            createData(t("Brands_Ranges_design"), false, true, true),
        ];

        const qrcodesFeaturesRows = [
            createData(t("Included_in_plan"), "100", "500", "Unlimited"),
            createData(t("Price_per_scan"), "Free", "Free", "Free"),
            createData(t("Full_customization"), true, true, true),
            createData(t("Reusable_templates"), true, true, true),
            createData(t("Quick_analytics"), true, true, true),
            createData(t("HD_downloads"), "Unlimited", "Unlimited", "Unlimited"),
            createData(t("Document_hosting"), true, true, true),
            createData(t("Dynamic_pro"), false, true, true),
            createData(t("Campaign_management"), false, true, true),
            createData(t("Filters"), false, true, true),
            createData(t("Custom_domain_names"), false, "extra", "extra"),
            createData(t("Healthchecks"), false, "extra", "extra"),
        ];

        const vcardsFeaturesRows = [
            createData(t("Included_in_plan"), "Extra module", "Extra module", "Extra module"),
            createData(t("Strong_encryption"), true, true, true),
            createData(t("Self_managed_encryption_keys"), false, false, true)
        ];

        const campaignsFeaturesRows = [
            createData(t("Included_in_plan"), false, true, true),
            createData(t("Campaign_performance_analytics"), false, true, true),
            createData(t("Sub_campaigns"), false, false, true)
        ];

        const healthcheckFeaturesRows = [
            createData(t("Price_per_activation"), false, 0.70, 0.70),
        ];

        const domainNamesFeaturesRows = [
            createData(t("Price_per_domain"), false, 5.99, 7.99),
            createData(t("Sharing_option"), false, false, true)
        ];

        const atlasChecksFeaturesRows = [
            createData(t("Included_in_plan"), true, true, true),
            createData(t("Qrcodes"), true, true, true),
            createData(t("Qrcodes_advanced"), false, true, true),
            createData(t("Vcards"), true, true, true),
            createData(t("Packaging"), false, false, true)
        ];

        const usersFeaturesRows = [
            createData(t("Price_per_activation"), false, 4.79, 8.29),
            createData(t("Role_management"), false, true, true),
            createData(t("Sub_orgs"), false, false, true),
            createData(t("Consolidate_billing"), false, false, true)
        ];


        return (
            <>
                <Accordion expanded>
                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                        <QRCodesIcon style={{
                            fill: "rgba(0,0,0,0.8)",
                            maxHeight: '22px',
                            maxWidth: '22px',
                            alignSelf: "center",
                            marginRight: '16px'
                        }}/><Typography variant="h6">Unitag QR Codes</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box style={{width: '100%'}}>
                            <TableContainer style={{maxWidth: '100%'}}>
                                <Table aria-label="Feature table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{width: '30%'}}>{t("Feature")}</TableCell>
                                            <TableCell style={{width: '17.5%'}}
                                                       align="right">{t("Standard")}</TableCell>
                                            <TableCell style={{width: '17.5%'}} align="right">{t("Gold")}</TableCell>
                                            <TableCell style={{width: '17.5%'}}
                                                       align="right">{t("Platinium")}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {qrcodesFeaturesRows.map((row) => (
                                            <TableRow key={row.name}>
                                                <TableCell style={{width: '30%'}} component="th" scope="row">
                                                    {row.name}
                                                </TableCell>
                                                <TableCell style={{width: '17.5%'}}
                                                           align="right">{getChipTable(row.standard)}</TableCell>
                                                <TableCell style={{width: '17.5%'}}
                                                           align="right">{getChipTable(row.gold)}</TableCell>
                                                <TableCell style={{width: '17.5%'}}
                                                           align="right">{getChipTable(row.platinum)}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                        <DomainsIcon style={{
                            fill: "rgba(0,0,0,0.8)",
                            maxHeight: '22px',
                            maxWidth: '22px',
                            alignSelf: "center",
                            marginRight: '16px'
                        }}/><Typography variant="h6">Domain Names</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box style={{width: '100%'}}>
                            <TableContainer style={{maxWidth: '100%'}}>
                                <Table aria-label="Feature table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{width: '30%'}}>{t("Feature")}</TableCell>
                                            <TableCell style={{width: '17.5%'}}
                                                       align="right">{t("Standard")}</TableCell>
                                            <TableCell style={{width: '17.5%'}} align="right">{t("Gold")}</TableCell>
                                            <TableCell style={{width: '17.5%'}}
                                                       align="right">{t("Platinium")}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {domainNamesFeaturesRows.map((row) => (
                                            <TableRow key={row.name}>
                                                <TableCell style={{width: '30%'}} component="th" scope="row">
                                                    {row.name}
                                                </TableCell>
                                                <TableCell style={{width: '17.5%'}}
                                                           align="right">{getChipTable(row.standard)}</TableCell>
                                                <TableCell style={{width: '17.5%'}}
                                                           align="right">{getChipTable(row.gold)}</TableCell>
                                                <TableCell style={{width: '17.5%'}}
                                                           align="right">{getChipTable(row.platinum)}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                        <HealthchecksIcon style={{
                            fill: "rgba(0,0,0,0.8)",
                            maxHeight: '22px',
                            maxWidth: '22px',
                            alignSelf: "center",
                            marginRight: '16px'
                        }}/><Typography variant="h6">Healthchecks</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box style={{width: '100%'}}>
                            <TableContainer style={{maxWidth: '100%'}}>
                                <Table aria-label="Feature table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{width: '30%'}}>{t("Feature")}</TableCell>
                                            <TableCell style={{width: '17.5%'}}
                                                       align="right">{t("Standard")}</TableCell>
                                            <TableCell style={{width: '17.5%'}} align="right">{t("Gold")}</TableCell>
                                            <TableCell style={{width: '17.5%'}}
                                                       align="right">{t("Platinium")}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {healthcheckFeaturesRows.map((row) => (
                                            <TableRow key={row.name}>
                                                <TableCell style={{width: '30%'}} component="th" scope="row">
                                                    {row.name}
                                                </TableCell>
                                                <TableCell style={{width: '17.5%'}}
                                                           align="right">{getChipTable(row.standard)}</TableCell>
                                                <TableCell style={{width: '17.5%'}}
                                                           align="right">{getChipTable(row.gold)}</TableCell>
                                                <TableCell style={{width: '17.5%'}}
                                                           align="right">{getChipTable(row.platinum)}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                        <VcardsIcon style={{
                            fill: "rgba(0,0,0,0.8)",
                            maxHeight: '22px',
                            maxWidth: '22px',
                            alignSelf: "center",
                            marginRight: '16px'
                        }}/><Typography variant="h6">Unitag VCards</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box style={{width: '100%'}}>
                            <TableContainer style={{maxWidth: '100%'}}>
                                <Table aria-label="Feature table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{width: '30%'}}>{t("Feature")}</TableCell>
                                            <TableCell style={{width: '17.5%'}}
                                                       align="right">{t("Standard")}</TableCell>
                                            <TableCell style={{width: '17.5%'}} align="right">{t("Gold")}</TableCell>
                                            <TableCell style={{width: '17.5%'}}
                                                       align="right">{t("Platinium")}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {vcardsFeaturesRows.map((row) => (
                                            <TableRow key={row.name}>
                                                <TableCell style={{width: '30%'}} component="th" scope="row">
                                                    {row.name}
                                                </TableCell>
                                                <TableCell style={{width: '17.5%'}}
                                                           align="right">{getChipTable(row.standard)}</TableCell>
                                                <TableCell style={{width: '17.5%'}}
                                                           align="right">{getChipTable(row.gold)}</TableCell>
                                                <TableCell style={{width: '17.5%'}}
                                                           align="right">{getChipTable(row.platinum)}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                        <UsersIcon style={{
                            fill: "rgba(0,0,0,0.8)",
                            maxHeight: '22px',
                            maxWidth: '22px',
                            alignSelf: "center",
                            marginRight: '16px'
                        }}/><Typography variant="h6">{t("Users_orgs")}</Typography>

                    </AccordionSummary>
                    <AccordionDetails>
                        <Box style={{width: '100%'}}>
                            <TableContainer style={{maxWidth: '100%'}}>
                                <Table aria-label="Feature table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{width: '30%'}}>{t("Feature")}</TableCell>
                                            <TableCell style={{width: '17.5%'}}
                                                       align="right">{t("Standard")}</TableCell>
                                            <TableCell style={{width: '17.5%'}} align="right">{t("Gold")}</TableCell>
                                            <TableCell style={{width: '17.5%'}}
                                                       align="right">{t("Platinium")}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {usersFeaturesRows.map((row) => (
                                            <TableRow key={row.name}>
                                                <TableCell style={{width: '30%'}} component="th" scope="row">
                                                    {row.name}
                                                </TableCell>
                                                <TableCell style={{width: '17.5%'}}
                                                           align="right">{getChipTable(row.standard)}</TableCell>
                                                <TableCell style={{width: '17.5%'}}
                                                           align="right">{getChipTable(row.gold)}</TableCell>
                                                <TableCell style={{width: '17.5%'}}
                                                           align="right">{getChipTable(row.platinum)}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                        <CloudUploadIcon style={{
                            fill: "rgba(0,0,0,0.8)",
                            maxHeight: '22px',
                            maxWidth: '22px',
                            alignSelf: "center",
                            marginRight: '16px'
                        }}/><Typography variant="h6">Atlas</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box style={{width: '100%'}}>
                            <TableContainer style={{maxWidth: '100%'}}>
                                <Table aria-label="Feature table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{width: '30%'}}>{t("Feature")}</TableCell>
                                            <TableCell style={{width: '17.5%'}}
                                                       align="right">{t("Standard")}</TableCell>
                                            <TableCell style={{width: '17.5%'}} align="right">{t("Gold")}</TableCell>
                                            <TableCell style={{width: '17.5%'}}
                                                       align="right">{t("Platinium")}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {atlasChecksFeaturesRows.map((row) => (
                                            <TableRow key={row.name}>
                                                <TableCell style={{width: '30%'}} component="th" scope="row">
                                                    {row.name}
                                                </TableCell>
                                                <TableCell style={{width: '17.5%'}}
                                                           align="right">{getChipTable(row.standard)}</TableCell>
                                                <TableCell style={{width: '17.5%'}}
                                                           align="right">{getChipTable(row.gold)}</TableCell>
                                                <TableCell style={{width: '17.5%'}}
                                                           align="right">{getChipTable(row.platinum)}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                        <CampaignIcon style={{
                            fill: "rgba(0,0,0,0.8)",
                            maxHeight: '22px',
                            maxWidth: '22px',
                            alignSelf: "center",
                            marginRight: '16px'
                        }}/><Typography variant="h6">Campaigns</Typography>

                    </AccordionSummary>
                    <AccordionDetails>
                        <Box style={{width: '100%'}}>
                            <TableContainer style={{maxWidth: '100%'}}>
                                <Table aria-label="Feature table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{width: '30%'}}>{t("Feature")}</TableCell>
                                            <TableCell style={{width: '17.5%'}}
                                                       align="right">{t("Standard")}</TableCell>
                                            <TableCell style={{width: '17.5%'}} align="right">{t("Gold")}</TableCell>
                                            <TableCell style={{width: '17.5%'}}
                                                       align="right">{t("Platinium")}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {campaignsFeaturesRows.map((row) => (
                                            <TableRow key={row.name}>
                                                <TableCell style={{width: '30%'}} component="th" scope="row">
                                                    {row.name}
                                                </TableCell>
                                                <TableCell style={{width: '17.5%'}}
                                                           align="right">{getChipTable(row.standard)}</TableCell>
                                                <TableCell style={{width: '17.5%'}}
                                                           align="right">{getChipTable(row.gold)}</TableCell>
                                                <TableCell style={{width: '17.5%'}}
                                                           align="right">{getChipTable(row.platinum)}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                        <PackagingIcon style={{
                            fill: "rgba(0,0,0,0.8)",
                            maxHeight: '22px',
                            maxWidth: '22px',
                            alignSelf: "center",
                            marginRight: '16px'
                        }}/><Typography variant="h6">Packaging</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box style={{width: '100%'}}>
                            <TableContainer style={{maxWidth: '100%'}}>
                                <Table aria-label="Feature table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{width: '30%'}}>{t("Feature")}</TableCell>
                                            <TableCell style={{width: '17.5%'}}
                                                       align="right">{t("Standard")}</TableCell>
                                            <TableCell style={{width: '17.5%'}} align="right">{t("Gold")}</TableCell>
                                            <TableCell style={{width: '17.5%'}}
                                                       align="right">{t("Platinium")}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {packagingFeaturesRows.map((row) => (
                                            <TableRow key={row.name}>
                                                <TableCell style={{width: '30%'}} component="th" scope="row">
                                                    {row.name}
                                                </TableCell>
                                                <TableCell style={{width: '17.5%'}}
                                                           align="right">{getChipTable(row.standard)}</TableCell>
                                                <TableCell style={{width: '17.5%'}}
                                                           align="right">{getChipTable(row.gold)}</TableCell>
                                                <TableCell style={{width: '17.5%'}}
                                                           align="right">{getChipTable(row.platinum)}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </AccordionDetails>
                </Accordion>
            </>
        )
    }

    return renderAccordionsList()
}