// REACT
import React from 'react';
// MUI
import {
    Box,
    Container,
    Divider,
    Grid,
    Tab, Tabs,
    Typography,
} from '@mui/material';
// COMPONENTS
import BillingOverviewTab from "../components/BillingAndPlans/overview/BillingOverviewTab";
import BillingPerServiceTab from "../components/BillingAndPlans/perService/BillingPerServiceTab";
// TRANSLATION
import {useTranslation} from 'react-i18next';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`billing-tabpanel-${index}`}
            aria-labelledby={`billing-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ my: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `billing-tab-${index}`,
        'aria-controls': `billing-tabpanel-${index}`,
    };
}



export default function SettingsBillingTab(props: any) {
    const {t} = useTranslation(['settings', 'common']);
    const {handleSuccess, handleError} = props;

    const [tabValue, setTabValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    return (
        <main>
            <Container maxWidth={'xl'}>
                <Grid container justifyContent='flex-start'>
                    <Grid item xs={12} sx={{ py: 1 }}>
                        <Typography variant='h5' gutterBottom>{t('Billing_Plans')}</Typography>
                        <Divider/>
                    </Grid>
                </Grid>

                <Box>
                    <Box>
                        <Tabs value={tabValue} onChange={handleChange} aria-label="billing tabs">
                            <Tab label={t("Overview")} sx={{ borderRadius: "4px 4px 0 0"}} {...a11yProps(0)} data-testid={"tabOverview"} />
                            <Tab label={t("Per_service")} sx={{ borderRadius: "4px 4px 0 0"}} {...a11yProps(1)} data-testid={"tabDetails"} />
                        </Tabs>
                    </Box>
                    <TabPanel value={tabValue} index={0}>
                        <BillingOverviewTab {...{handleSuccess, handleError}} />
                    </TabPanel>
                    <TabPanel value={tabValue} index={1}>
                        <BillingPerServiceTab />
                    </TabPanel>
                </Box>
                </Container>
        </main>
    );
}
