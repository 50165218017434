import React from "react"
import {Box, Paper, Stack, Typography} from "@mui/material";

export default function GeneratorPaper(props: any) {

    const {children, label, background, bold, sx, paperPadding, paperStyle, adornment, paperMaxHeight} = props

    const styles = {
        wrapper: {
            ...sx,
            position: "relative"
        },
        paper: {
            ...paperStyle,
            p: 0,
            position: "relative",
            backgroundColor: background ? background : "#fff",
            transition: "0.25s ease-in-out",
            overflow: "hidden"
        },
        label: {
            position: "absolute",
            zIndex: 999,
            left: 15,
            top: -10,
            backgroundColor: "#fff",
            px: 1,
            borderRadius: 3,
            "&::before": {
                content: "''",
                position: "absolute",
                height: 15
            }
        }
    }

    return (
        <Box sx={styles.wrapper}>
            {/* @ts-ignore */}
            <Stack direction={"row"}  spacing={1} sx={styles.label}>
                <Typography variant={"body2"}  fontWeight={bold ? 800 : 400}>
                    {label}
                </Typography>
                {adornment && <div>{adornment}</div>}
            </Stack>
            <Paper variant={"outlined"} sx={styles.paper}>
                <Box className={"fancyScroll"} sx={{ overflowY: "auto", p: paperPadding ? paperPadding : 2, maxHeight: paperMaxHeight ? `calc(${paperMaxHeight}px + 2px)` : 9999 }}>
                    {children}
                </Box>
            </Paper>
        </Box>
    )
}