import React from 'react';
import {Link} from "react-router-dom";
// MUI
import {Box, Breadcrumbs, Grid, Link as MaterialLink, Paper, Typography} from '@mui/material';
// TRANSLATION
import {useTranslation} from "react-i18next";
// Unitag UI
import {Button} from "@components";


type MyPaths = {
    name: string;
    path: string;
}

type MyProps = {
    // exemple :
    // [{name: "Unitag_console", path: "/"}, {name: "Applications", path: "/applications"}]
    paths: MyPaths[];
    positionName: string; // exemple : Packaging_control_panel
    title?: string;
    subTitle?: string;
    docLabel?: string;
    docLink?: string;
    smallDoc?: boolean
}

export default function AppHeader(props: MyProps) {

    const {t} = useTranslation(['common']);
    const { title, subTitle, docLabel, docLink, paths, smallDoc } = props;

    const styles = {
        headerWrapper: {
            justifyContent: {
                xs: 'center',
                md: 'space-between'
            }
        },
        titleWrapper: { my: 3 },
        docButtonWrapper: { mt: 3 },
        docPaper: { p: 3, textAlign: "center", borderRadius: 5 }
    };

    return (
        <>
        <Grid container sx={styles.headerWrapper} justifyContent={"space-between"}>
            <Grid item xs={12} sm={8} md={7} xl={8}>
                <Breadcrumbs aria-label="breadcrumb">
                    {paths.map((path, index) => {
                        return (
                            <MaterialLink className={"link"} key={index} color="inherit" component={Link} to={path.path}>
                                {t(path.name)}
                            </MaterialLink>
                        );
                    })}
                    <Typography color="textPrimary">
                        {t(props.positionName)}
                    </Typography>
                </Breadcrumbs>

                {
                    title &&
                    <Box sx={styles.titleWrapper}>
                        <Typography component="h1" variant={"h1"}>
                            {t(title)}
                        </Typography>
                            <Typography
                                component="h2"
                                variant={"h5"}
                                color={"textSecondary"}
                                fontWeight={"400"}
                            >
                                {subTitle ? t(subTitle) : ""}
                            </Typography>
                    </Box>
                }


            </Grid>

            { (docLabel && docLink) &&
                <Grid item xs={11} sm={4} md={smallDoc ? 3 : 4}>
                    <Paper variant={"outlined"} sx={styles.docPaper}>
                            <Typography variant="body2">
                                {t(docLabel)}
                            </Typography>
                            <Box sx={styles.docButtonWrapper}>
                                <Button
                                    primary
                                    small
                                    href={docLink}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {t("common:Documentation")}
                                </Button>
                            </Box>
                    </Paper>
                </Grid>
            }
        </Grid>
        </>
    );
}
