import React from 'react';
// MUI
import {
    Box,
    Button,
    DialogActions,
    DialogTitle,
    IconButton,
    Typography,
    Alert,
    Dialog,
    DialogContent,
    TextField
} from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
// API
import {APIDelete} from "@api";


const {REACT_APP_API_URL} = process.env;

export default function DeleteDomainDialog(props: any) {

    const {open, onClose, handleSuccess, handleError, t} = props
    const [domainName, setDomainName] = React.useState("");

    const classes = {
        deleteButton: {
            color: 'white', backgroundColor: '#ff4646',
            "&:hover": {backgroundColor: '#e00000',}
        },
    }

    const deleteDomain = () => {

        APIDelete<any>(REACT_APP_API_URL + "/domains/" + props.domainId, {}).then((res) => {
            if (res.status > 200) handleError(t("error_delete_domain"))
            else handleSuccess(t("success_delete_domain"))
            setDomainName("")
            onClose()
        }).catch(() => handleError(t("error_delete_domain")))
    }

    return (
        <Dialog
            open={open}
            maxWidth={"xs"}
            fullWidth
        >
            <DialogTitle>
                <Box display="flex" alignItems="center">
                    <Box flexGrow={1}>{t("delete_domain_dialog")}</Box>
                    <IconButton onClick={onClose}><CloseIcon/></IconButton>
                </Box>
            </DialogTitle>
            <DialogContent style={{overflow: 'hidden', paddingBottom: '24px'}}>
                <Alert severity={"warning"}>
                    {t("delete_domain_alert")}
                </Alert>
                <br/>
                <br/>
                <Typography style={{fontWeight: 300}}>
                    {t("delete_domain_confirm")}
                </Typography>
                <br/>
                <TextField
                    focused
                    fullWidth
                    variant={"outlined"}
                    size={"small"}
                    label={t("domain_label")}
                    value={domainName}
                    placeholder={props.domainName}
                    onChange={(event: any) => {
                        setDomainName(event.target.value as string)
                    }}
                />
            </DialogContent>
            <DialogActions style={{marginRight: '24px', paddingBottom: '24px'}}>
                <Button disableElevation onClick={onClose}>{t("common:back")}</Button>
                <Button
                    variant="contained"
                    disabled={domainName !== props.domainName || props.domainName === ""}
                    disableElevation
                    onClick={deleteDomain}
                    sx={classes.deleteButton}>
                    {t("common:delete")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
