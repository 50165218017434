import React, {useState, useEffect} from 'react';
// MUI
import {
    Autocomplete,
    Box,
    Checkbox, Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel, Grid,
    IconButton, List, ListItem, ListItemText,
    TextField,
    Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
// UI
import {Button} from "@components";
// API
import {APIGet, APIPut} from "@api";
// Interfaces
import {User} from "@interfaces";

const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;


const {REACT_APP_API_URL} = process.env;

export default function ShareDomainDialog(props: any) {

    const {open, onClose, handleSuccess, handleError, t} = props

    const [orgID, setOrgID] = useState('');
    const [subOrgIDs, setSubOrgIDs] = useState<string[]>([]);
    const [domainID, setDomainID] = useState('');

    // Autocomplete State For Organisations
    const [openUsers, setOpenUsers] = useState(false);
    const [optionsUsers, setOptionsUsers] = useState<User[]>([]);
    const [loadingUsers, setLoadingUsers] = useState<boolean>(true);

    // Sharing
    const [selectUsers, setSelectedUsers] = useState<User[]>([]);
    const [orgShared, setOrgShared] = useState(false);


    useEffect(() => {
        setOrgID(props.org_id);
        setDomainID(props.domain_id);
        setSelectedUsers([]);
        setLoadingUsers(true);

        if (props.is_shared_with_orgs !== undefined) {
            setOrgShared(props.is_shared_with_orgs);
        }

        if (!!props.sub_orgs) {
            let suborgsids: string[] = [];
            for (let i = 0; i < props.sub_orgs.length; i++) {
                suborgsids.push(props.sub_orgs[i].organisation_id);
            }
            setSubOrgIDs(suborgsids);
        }

    }, [props]);

    useEffect(() => {
        if (!orgID || !loadingUsers) return

        let domainIDBegining = domainID;

        APIGet<User[]>(REACT_APP_API_URL + "/org/" + orgID + "/users").then((data) => {
            if (data.status === 200 && !!data.parsedBody) {
                let users: User[] = [];
                data.parsedBody.forEach((v) => {
                    if (!users.some((inside) => (inside.user_id === v.user_id))) {
                        if (props.selectedUsers !== undefined && props.selectedUsers.some((selected: User) => (selected.uuid === v.user_id))) {
                            setSelectedUsers((oldVal) => ([...oldVal, v]));
                        }
                        users.push(v);
                    }
                });

                // handle case where we receive the response after the user switch to another domain
                if (domainIDBegining === domainID) {
                    setOptionsUsers(users);
                    setLoadingUsers(false);
                }

                if (orgShared) { setSelectedUsers(users) }
            }

        }).catch(() => handleError(t("error_domain_sharing")))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingUsers, orgID]);

    const handleOrgSharedChange = () => {
        setOrgShared(!orgShared);
        setSelectedUsers(optionsUsers);
    }

    const removeFromSelectedUsers = (uuid: string) => {
        setSelectedUsers(selectUsers.filter((user) => {
            return user.user_id !== uuid
        }))
    }

    const handleSelectedUsersChange = (event: object, value: User[]) => {
        setSelectedUsers(value);
    }

    const updateDomain = () => {

        let body: {}

        if (orgShared) {
            body = { orgs_ids: [orgID, ...subOrgIDs] }
        } else {
            body = { users_ids: selectUsers?.map((user: User) => user.user_id) }
        }

        APIPut<any>(REACT_APP_API_URL + "/domains/" + domainID, body).then((data) => {
            if (data.status > 200) {
                handleError(t("error_domain_sharing"))
            } else {
                handleSuccess(t("success_domain_sharing"))
            }
        }).catch(() => handleError(t("error_domain_sharing"))
        ).finally(() => onClose());
    }

    return (
        <Dialog open={open} maxWidth={"lg"}>
            <DialogTitle>
                <Box display="flex" alignItems="center">
                    <Box flexGrow={1}><Typography variant="h5">{t("Domain_sharing")} - {props.domaine_name}</Typography></Box>
                    <IconButton onClick={onClose}>
                        <CloseIcon/>
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Grid>
                    <Typography variant="body2">{t("Domain_sharing_text")}</Typography>
                    <br/>

                    <hr/>
                    <Typography variant={"h6"}>{t("Domain_sharing_orglevel")}</Typography>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={orgShared}
                                onChange={handleOrgSharedChange}
                                name="share-all"
                                color="secondary"
                            />
                        }
                        label={t("Domain_security_access_all") as string}
                    />
                    <hr/>

                    <Container sx={{marginTop: '15px'}}>
                        <Grid container spacing={2} sx={{marginTop: '15px'}}>
                            <Grid item xs={12} md={6} sx={{
                                borderRight: '1px solid rgb(100,100,115)',
                                paddingRight: '15px'
                            }}>
                                <Typography sx={{marginBottom: '30px'}} variant="h6">{t("Domain_security_access_indiv")}</Typography>

                                <Autocomplete
                                    disabled={orgShared || loadingUsers}
                                    multiple
                                    size="small"
                                    fullWidth={true}
                                    id="orgs-checkboxes"
                                    disableCloseOnSelect
                                    options={optionsUsers}
                                    value={selectUsers}
                                    onChange={handleSelectedUsersChange}
                                    isOptionEqualToValue={(option, value) => {
                                        const o = option as User;
                                        const v = value as User;
                                        return o.user_id === v.user_id;
                                    }}
                                    getOptionLabel={(option) => {
                                        const o = option as User;
                                        return o.email;
                                    }}
                                    open={openUsers}
                                    onOpen={() => {
                                        setOpenUsers(true);
                                    }}
                                    onClose={() => {
                                        setOpenUsers(false);
                                    }}
                                    renderOption={(props, option, {selected}) => {
                                        const o = option as User;
                                        return (
                                            <li {...props}>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{marginRight: '8px'}}
                                                    checked={selected}
                                                />
                                                {o.email}
                                            </li>
                                        );
                                    }}
                                    renderInput={(params) => (
                                        <TextField {...params} variant="outlined" label={t("Users")}
                                                   placeholder="Select users"/>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} sx={{
                                overflowY: 'scroll',
                                maxHeight: '500px'
                            }}>
                                <Typography sx={{marginBottom: '5px'}} variant="h6">{t("Domain_users_affected")}</Typography>
                                <List sx={{width: '80%'}}>
                                    {
                                        selectUsers.map((value) => {
                                            const uuid: string = value.user_id;
                                            return <ListItem
                                                key={value.user_id}
                                                disableGutters
                                                secondaryAction={
                                                    <IconButton aria-label="comment"
                                                                disabled={orgShared}
                                                                onClick={() => removeFromSelectedUsers(uuid)}>
                                                        <RemoveCircleIcon color={orgShared ? "inherit" : "primary"}/>
                                                    </IconButton>
                                                }
                                            >
                                                <ListItemText primary={value.email}/>
                                            </ListItem>
                                        })
                                    }
                                </List>
                            </Grid>
                        </Grid>

                    </Container>

                    <br/>

                    <br/>
                </Grid>
            </DialogContent>
            <DialogActions style={{padding: '24px'}}>
                <Button text onClick={onClose}>
                    {t("Cancel")}
                </Button>
                <Button primary onClick={updateDomain}>
                    {t("Domain_update")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
