import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Paper, Divider, Button, Typography } from "@mui/material";
// i18n
import { useTranslation } from 'react-i18next';

export default function PrivacyPolicy() {
    const {t} = useTranslation(['privacy', 'common']);

    return (
        <Container maxWidth="lg" sx={{ my: 5 }}>
            <Button component={Link} to="/login" variant="outlined" color="secondary" disableElevation>{t("Back")}</Button>
            <Paper variant="outlined" sx={{ p: 7, my: 5 }}>

                <Typography variant="h2" component="h1" style={{ margin: "16px 0px" }}>
                    {t("privacy_policy")}
                </Typography>

                <Divider sx={{ my: 3 }} />

                <Typography>
                    {t("pre_text")}
                </Typography>

                <Typography variant="h4" component="h2" color="primary" style={{ margin: "24px 0px 24px 0px" }}>
                    {t("Welcome")}
                </Typography>

                <Typography variant="body1" component="p" style={{ margin: "16px 0px 16px 0px"}}>
                    {t("welcome_p_1_1")}
                </Typography>

                <Typography variant="body1" component="p" style={{ margin: "16px 0px 16px 0px"}}>
                    {t("welcome_p_2_1")}<b>{t("policy")}</b>{t("welcome_p_2_2")}
                </Typography>

                <Typography variant="body1" component="p" style={{ margin: "16px 0px 16px 0px"}}>
                    <b>{t("unitag")}</b>, <b>{t("we")}</b>, <b>{t("our")}, </b> {t("or")} <b>{t("us")}, </b>{t("welcome_p_3_1")}
                </Typography>

                <Typography variant="body1" component="p" style={{ margin: "16px 0px 16px 0px"}}>
                    <b>{t("personal_data")}</b> {t("welcome_p_4_1")}
                </Typography>

                <Typography variant="body1" component="p" style={{ margin: "16px 0px 16px 0px"}}>
                    <b>{t("services")}</b> {t("welcome_p_5_1")}
                </Typography>

                <Typography variant="body1" component="p" style={{ margin: "16px 0px 16px 0px"}}>
                    <b>{t("business_services")}</b> {t("welcome_p_6_1")}<b>{t("business_users")}</b>{t("welcome_p_6_2")}<b>{t("end_user")}</b>{t("welcome_p_6_3")}
                </Typography>

                <Typography variant="body1" component="p" style={{ margin: "16px 0px 16px 0px"}}>
                    <b>{t("sites")}</b> {t("welcome_p_8_1")}
                </Typography>

                <Typography variant="body1" component="p" style={{ margin: "16px 0px 16px 0px"}}>
                    {t("welcome_p_9_1")}<b>{t("you")}</b>{t("welcome_p_9_2")}
                </Typography>

                <Typography variant="body1" component="p" style={{ margin: "16px 0px 16px 0px"}}>
                    <ul>
                        <li>{t("welcome_p_9_li_1")}<b>{t("end_user")}</b></li><br/>
                        <li>{t("welcome_p_9_li_2")}<b>{t("business_user")}</b></li><br/>
                        <li>{t("welcome_p_9_li_3")}<b>{t("representative")}</b></li><br/>
                        <li>{t("welcome_p_9_li_4")}<b>{t("visitor")}</b></li>
                    </ul>
                </Typography>

                <Typography variant="h4" component="h2" color="primary" style={{ margin: "24px 0px 24px 0px" }}>
                    {t("1_title")}
                </Typography>

                <Typography variant="h6" color="primary" component="p" style={{ margin: "16px 0px 16px 0px"}}>
                    <b>{t("1_1_title")}</b>
                </Typography>

                <Typography variant="body1" component="p" style={{ margin: "16px 0px 16px 0px"}}>
                    {t("1_1_content")}
                </Typography>

                <Typography variant="body1" component="p" style={{ margin: "16px 0px 16px 0px"}}>
                    {t("1_1_1")}
                </Typography>

                <Typography variant="body1" component="p" style={{ margin: "16px 0px 16px 0px"}}>
                    <ul>
                        <li><b>{t("1_1_1_li_1")}</b>{t("1_1_1_li_2")}</li>
                    </ul>
                </Typography>

                <Typography variant="body1" component="p" style={{ margin: "16px 0px 16px 0px"}}>
                    {t("1_1_2")}
                </Typography>

                <Typography variant="body1" component="p" style={{ margin: "16px 0px 16px 0px"}}>
                    <ul>
                        <li><b>{t("1_1_2_li_1")}</b>{t("1_1_2_li_2")}</li>
                    </ul>
                </Typography>

                <Typography variant="h6" color="primary" component="p" style={{ margin: "16px 0px 16px 0px"}}>
                    <b>{t("1_2_title")}</b>
                </Typography>

                <Typography variant="body1" component="p" style={{ margin: "16px 0px 16px 0px"}}>
                    {t("1_2_content")}
                </Typography>

                <Typography variant="body1" component="p" style={{ margin: "16px 0px 16px 0px"}}>
                    {t("1_2_1")}
                </Typography>

                <Typography variant="body1" component="p" style={{ margin: "16px 0px 16px 0px"}}>
                    <ul>
                        <li><b>{t("1_2_1_li_1_1")}</b>{t("1_2_1_li_1_2")}</li><br/>
                        <li><b>{t("1_2_1_li_2_1")}</b>{t("1_2_1_li_2_2")}</li>
                    </ul>
                </Typography>

                <Typography variant="h6" color="primary" component="p" style={{ margin: "16px 0px 16px 0px"}}>
                    <b>{t("1_3_title")}</b>
                </Typography>

                <Typography variant="body1" component="p" style={{ margin: "16px 0px 16px 0px"}}>
                    {t("1_3_content")}
                </Typography>

                <Typography variant="body1" component="p" style={{ margin: "16px 0px 16px 0px"}}>
                    {t("1_3_1")}
                </Typography>

                <Typography variant="body1" component="p" style={{ margin: "16px 0px 16px 0px"}}>
                    <ul>
                        <li><b>{t("1_3_1_li_1")}</b>{t("1_3_1_li_2")}</li>
                    </ul>
                </Typography>

                <Typography variant="h4" component="h2" color="primary" style={{ margin: "24px 0px 24px 0px" }}>
                    {t("2_title")}
                </Typography>

                <Typography variant="body1" component="p" style={{ margin: "16px 0px 16px 0px"}}>
                    {t("2_content")}
                </Typography>

                <Typography variant="h6" color="primary" component="p" style={{ margin: "16px 0px 16px 0px"}}>
                    <b>{t("2_1_title")}</b>
                </Typography>

                <Typography variant="body1" component="p" style={{ margin: "16px 0px 16px 0px"}}>
                    <ul>
                        <li><b>{t("2_1_li_1_1")}</b>{t("2_1_li_1_2")}</li><br/>
                        <ul>
                            <li>{t("2_1_li_1_2_li_1")}</li><br/>
                            <li>{t("2_1_li_1_2_li_2")}</li>
                        </ul>
                    </ul>
                </Typography>

                <Typography variant="body1" component="p" style={{ margin: "16px 0px 16px 0px"}}>
                    <ul>
                        <li><b>{t("2_1_li_2_1")}</b>{t("2_1_li_2_2")}</li><br/>
                    </ul>
                </Typography>

                <Typography variant="h6" color="primary" component="p" style={{ margin: "16px 0px 16px 0px"}}>
                    <b>{t("2_2_title")}</b>
                </Typography>

                <Typography variant="body1" component="p" style={{ margin: "16px 0px 16px 0px"}}>
                    {t("2_2_content")}
                </Typography>

                <Typography variant="body1" component="p" style={{ margin: "16px 0px 16px 0px"}}>
                    <ul>
                        <li><b>{t("2_2_li_1_1")}</b>{t("2_2_li_1_2")}</li><br/>
                        <li><b>{t("2_2_li_2_1")}</b>{t("2_2_li_2_2")}</li><br/>
                        <li><b>{t("2_2_li_3_1")}</b>{t("2_2_li_3_2")}</li><br/>
                        <li><b>{t("2_2_li_4_1")}</b>{t("2_2_li_4_2")}</li><br/>
                    </ul>
                </Typography>

                <Typography variant="h6" color="primary" component="p" style={{ margin: "16px 0px 16px 0px"}}>
                    <b>{t("2_3_title")}</b>
                </Typography>

                <Typography variant="body1" component="p" style={{ margin: "16px 0px 16px 0px"}}>
                    <ul>
                        <li><b>{t("2_3_li_1")}</b>{t("2_3_li_2")}</li><br/>
                        <ul>
                            <li>{t("2_3_li_2_li_1")}</li><br/>
                            <li>{t("2_3_li_2_li_2")}</li><br/>
                            <li>{t("2_3_li_2_li_3")}</li><br/>
                            <li>{t("2_3_li_2_li_4")}</li>
                        </ul>
                    </ul>
                </Typography>

                <Typography variant="h4" component="h2" color="primary" style={{ margin: "24px 0px 24px 0px" }}>
                    {t("3_title")}
                </Typography>

                <Typography variant="body1" component="p" style={{ margin: "16px 0px 16px 0px"}}>
                    {t("3_content")}
                </Typography>

                <Typography variant="body1" component="p" style={{ margin: "16px 0px 16px 0px"}}>
                    <b>{t("3_a_1")}</b>{t("3_a_2")}
                </Typography>

                <Typography variant="body1" component="p" style={{ margin: "16px 0px 16px 0px"}}>
                    <ul>
                        <li>{t("3_a_li_1")}</li><br/>
                        <li>{t("3_a_li_2")}</li>
                    </ul>
                </Typography>

                <Typography variant="body1" component="p" style={{ margin: "16px 0px 16px 0px"}}>
                    <b>{t("3_b_1")}</b>{t("3_b_2")}
                </Typography>

                <Typography variant="body1" component="p" style={{ margin: "16px 0px 16px 0px"}}>
                    <b>{t("3_c_1")}</b>{t("3_c_2")}
                </Typography>

                <Typography variant="body1" component="p" style={{ margin: "16px 0px 16px 0px"}}>
                    <ul>
                        <li>{t("3_c_li_1")}</li><br/>
                        <li>{t("3_c_li_2")}</li><br/>
                        <li>{t("3_c_li_3")}</li><br/>
                        <li>{t("3_c_li_4")}</li><br/>
                        <li>{t("3_c_li_5")}</li><br/>
                        <li>{t("3_c_li_6")}</li>
                    </ul>
                </Typography>

                <Typography variant="h4" component="h2" color="primary" style={{ margin: "24px 0px 24px 0px" }}>
                    {t("4_title")}
                </Typography>

                <Typography variant="body1" component="p" style={{ margin: "16px 0px 16px 0px"}}>
                    {t("4_content")}
                </Typography>

                <Typography variant="body1" component="p" style={{ margin: "16px 0px 16px 0px"}}>
                    <b>{t("4_a")}</b>
                </Typography>

                <Typography variant="body1" component="p" style={{ margin: "16px 0px 16px 0px"}}>
                    {t("4_a_content")}
                </Typography>

                <Typography variant="body1" component="p" style={{ margin: "16px 0px 16px 0px"}}>
                    <b>{t("4_b")}</b>
                </Typography>

                <Typography variant="body1" component="p" style={{ margin: "16px 0px 16px 0px"}}>
                    {t("4_b_content")}
                </Typography>

                <Typography variant="body1" component="p" style={{ margin: "16px 0px 16px 0px"}}>
                    <ul>
                        <li>{t("4_b_li_1")}</li><br/>
                        <li>{t("4_b_li_2")}</li><br/>
                        <li>{t("4_b_li_3")}</li><br/>
                        <li>{t("4_b_li_4")}</li><br/>
                        <li>{t("4_b_li_5")}</li><br/>
                        <li>{t("4_b_li_6")}</li><br/>
                        <li>{t("4_b_li_7")}</li>
                    </ul>
                </Typography>

                <Typography variant="h4" component="h2" color="primary" style={{ margin: "24px 0px 24px 0px" }}>
                    {t("5_title")}
                </Typography>

                <Typography variant="body1" component="p" style={{ margin: "16px 0px 16px 0px"}}>
                    {t("5_1")}
                </Typography>

                <Typography variant="body1" component="p" style={{ margin: "16px 0px 16px 0px"}}>
                    {t("5_2")}
                </Typography>

                <Typography variant="body1" component="p" style={{ margin: "16px 0px 16px 0px"}}>
                    {t("5_3")}
                </Typography>

                <Typography variant="h4" component="h2" color="primary" style={{ margin: "24px 0px 24px 0px" }}>
                    {t("6_title")}
                </Typography>

                <Typography variant="body1" component="p" style={{ margin: "16px 0px 16px 0px"}}>
                    {t("6_1")}
                </Typography>
            </Paper>
        </Container>


    )
}