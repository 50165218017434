
function roundToCent(value: number) {
    if (value === 0) return 0
    return Math.round(value * 100) / 100
}

// TODO: improve currency accuracy in the BillingDetails component by using data retrieved from UserProvider
function returnUsedCurrency(locale?: any) {
    if (locale) {
        // ...
    }
    return "€"
}

function returnFractionFromDiscountPercentage(percentage: number) {
    return (100 - percentage) / 100
}

function returnYearlyPriceWithDiscount(value: number, discount: number) {
    let percentage = returnFractionFromDiscountPercentage(discount)
    return (value * 12) * percentage
}

export { roundToCent, returnUsedCurrency, returnFractionFromDiscountPercentage, returnYearlyPriceWithDiscount }