import {useSnackbar as handleSnackbar} from "notistack";

function useSnackbar() {
    // This hook prevents from importing Notistack useSnackbar hook and defining new success/error handlers
    // per file. This can be useful for creating custom action snackbars as well (see Notistack documentation)
    const {enqueueSnackbar} = handleSnackbar()

    const handleError = (message: string) => {
        enqueueSnackbar(message, {variant: "error"})
    }
    const handleSuccess = (message: string) => {
        enqueueSnackbar(message, {variant: "success"})
    }

    const handleWarning = (message: string) => {
        enqueueSnackbar(message, {variant: "warning"})
    }

    const handleInfo = (message: string) => {
        enqueueSnackbar(message, {variant: "info"})
    }

    return {handleError, handleSuccess, handleInfo, handleWarning}
}

export {useSnackbar}