import React, {useEffect, useState} from "react"
// MUI
import {Chip} from "@mui/material";
import {
    Email,
    Event, Inventory,
    Language,
    Notes,
    Phone,
    Place,
    PlayArrow,
    PostAdd,
    RememberMe,
    Sms,
    Wifi
} from "@mui/icons-material";
// hooks
import {useTranslation} from "react-i18next";

export default function QRCodeTypeChip(props: any) {

    const {type, resolution, small, clickable} = props
    const {t} = useTranslation(["qrcodes"])
    const [label, setLabel] = useState<string | null>(null)
    const [icon, setIcon] = useState<any>(null)
    const [color, setColor] = useState<string>("#efefef")

    const styles = {
        icon: {
            fill: "white"
        }
    }

    useEffect(() => {
        if (!!type) {
            if (type === "qrcode") setLabel("URL")
            else setLabel(t(type))
            switch (type) {
                // from apps
                case "packaging": setColor("#81c0cb"); setIcon(<Inventory sx={styles.icon} />); break;
                case "vcard": setColor("#81cbaa"); setIcon(<RememberMe sx={styles.icon} />); break;
                // from generator
                case "qrcode":
                case "url":
                case "URL": setColor("#b7cb81"); setIcon(<Language sx={styles.icon} />); break;
                case "call": setColor("#cb9d6b"); setIcon(<Phone sx={styles.icon} />); break;
                case "email": setColor("#cbb181"); setIcon(<Email sx={styles.icon} />); break;
                case "smsto": setColor("#cb8381"); setIcon(<Sms sx={styles.icon} />); break;
                case "geoloc": setColor("#81a1cb"); setIcon(<Place sx={styles.icon} />); break;
                case "calendar": setColor("#c181cb"); setIcon(<Event sx={styles.icon} />); break;
                case "text": setColor("#b4b6ad"); setIcon(<Notes sx={styles.icon} />); break;
                case "media": setColor("#cb81ad"); setIcon(<PlayArrow sx={styles.icon} />); break;
                case "wifi": setColor("#95b68c"); setIcon(<Wifi sx={styles.icon} />); break;
                case "vcard_classic": setColor("#9f8cb6"); setIcon(<RememberMe sx={styles.icon} />); break;
                case "document": setColor("#93989d"); setIcon(<PostAdd sx={styles.icon} />); break;
                default: return;
            }
        } else if (!!resolution) {
            setLabel(t(resolution))
            switch (resolution) {
                case "dynamic": setColor("#afc928"); break;
                case "static": setColor("darkgrey"); break;
                case "dyn-pro": setColor("#0077cc"); break;
                default: return;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);


    if (!label) return <></>

    return (
        <Chip
            size={small ? "small" : "medium"}
            icon={icon ? icon : null}
            variant={!!resolution ? "outlined" : "filled"}
            {...{label, clickable}}
            sx={{
                backgroundColor: !!resolution ? "transparent" : color,
                borderColor: !!resolution ? color : "transparent",
                color: "#313131",
                textTransform: "uppercase",
                fontWeight: 700,
                "&:hover": {
                    backgroundColor: !!resolution ? "transparent" : color
                }
            }}
        />
    )
}