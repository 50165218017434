import React from 'react';
// MUI
import {Paper, Grid, Typography, Skeleton, Box} from "@mui/material";
// API
import {APIPost} from "@api";
// TRANSLATION
import {useTranslation} from "react-i18next";
// Unitag UI
import {Button} from "@components";
// styles
import {styles} from "../styles";

const {REACT_APP_API_URL} = process.env;

export default function InvitationsPanel(props: any) {

    const {t} = useTranslation(['settings', 'common']);
    const  {handleError} = props

    const [pendingInvitation, setPendingInvitation] = React.useState<any>(null);

    React.useEffect(() => {
        if (!!props.invitation) {
            setPendingInvitation(props.invitation);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props])

    const acceptPendingInvitation = () => {
        APIPost<object>(REACT_APP_API_URL + "/org/" + pendingInvitation?.organisation?.organisation_id + "/invite/" + pendingInvitation?.invitation_id + "/accept", {})
            .then(() => {
                setPendingInvitation(null);
                window.location.reload();
        }).catch(() => handleError(t("Snackbar_invitation_accept_error")))
    }

    return (
        <>
            <Box sx={styles.blockBalance}>
                <Paper variant={"outlined"} sx={styles.paper}>
                    {pendingInvitation === null ?
                        <Skeleton variant="text" />
                        :
                        <Grid container spacing={1} alignItems="center" justifyContent={"space-between"}>
                            <Grid item xs={12} md={9}>
                                <Typography variant={"body1"}>
                                    {t("Invitation_received_to")}<b>{pendingInvitation.organisation?.name}</b>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={3} sx={styles.alignRight}>
                                <Button primary onClick={acceptPendingInvitation}>{t("Accept")}</Button>
                            </Grid>
                        </Grid>
                    }
                </Paper>
            </Box>
        </>
    )
}
