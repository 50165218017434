import React, {useEffect} from 'react';
import {Grid} from '@mui/material';
// AUTH
import {Auth} from "aws-amplify";
// USER
import {useUser} from "@context";
import {useTranslation} from "react-i18next";
import {useSnackbar} from "@hooks";

export default function RestoAppComponent() {

    const {user, getUser} = useUser()
    const [currentURL, setCurrentURL] = React.useState('');
    const [iframeHeight, setIframeHeight] = React.useState(0);
    const {t} = useTranslation(["common"])
    const {handleError} = useSnackbar()

    const styles = {
        contentIFrame: {
            flexGrow: 1,
            zIndex: 1,
            margin: 0,
            height: "100%",
        },
        iframe: {
            overflow: "hidden",
            width: "100%",
        }
    };

    useEffect(() => {
        getUser()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!!user) {
            if (user.account_type === "standard" || user.account_type === "gold" || user.account_type === "platinum" || !!user.org) {
                Auth.currentSession().then((sess) => {
                    setIframeHeight(window.outerHeight - 64);
                    setCurrentURL("https://externals.ngine.fr/#/chefcloud/landing_chefcloud/" + sess.getIdToken().getJwtToken());
                }, () => {
                    handleError(t("error_get_identification"))
                }).catch(() => handleError(t("error_get_identification")))
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);


    return (
        <main className="eq" style={{zIndex: 0}}>
            {
                !!user &&
                (user.account_type === "standard" ||
                user.account_type === "gold" ||
                user.account_type === "platinum" ||
                (user.org !== undefined && user.org !== "")) ?
                    <React.Fragment>
                        <Grid sx={styles.contentIFrame} style={{minHeight: iframeHeight}}>
                            <iframe
                                style={{minHeight: iframeHeight, ...styles.iframe}}
                                referrerPolicy="strict-origin-when-cross-origin"
                                src={currentURL}
                                title="Unitag Resto"
                                allow="clipboard-write"
                            />
                        </Grid>
                    </React.Fragment>
                : <></>
            }
        </main>
    );
}
