import React from "react"
// MUI
import {Container, Button, Divider, Grid, Paper, Typography, Collapse, Alert} from "@mui/material";
import {Upload} from "@mui/icons-material";
// Translation
import {useTranslation} from "react-i18next";
// Hooks
import {useStyles} from "@/Context/Ui/DashboardUiProvider";
import {useUser} from "@context";
// Components
import {GeneratorColorPicker} from "@apps/QRCodesGenerator/components/UI";
import {platinumSubscription} from "@/constants/plans_en";

export default function SettingsThemingTab() {

    const {t} = useTranslation(["settings"]);
    const {complexUser} = useUser()
    const {
        primaryColor, setPrimaryColor,
        secondaryColor, setSecondaryColor,
        setLogo, handleSaveLogo,
        saveUserCustomStyles, unsavedChanges
    } = useStyles()

    const styles = {
        topDivider: { mt: 1, mb: 2 },
        paperSection: { p: 3, width: "100%", my: 2 },
        importPaper: {
            p: 2, backgroundColor: "#efefef", mt: 2, transition: ".25s ease-in-out",
            "&:hover": {
                backgroundColor: "#dfdfdf",
            }
        }
    }

    const renderHeader = () => {
        return (
            <>
                <Grid item sx={{flexGrow: 1, my: 1}}>
                    <Typography variant="h5">
                        {t("theming")}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider sx={styles.topDivider} />
                </Grid>
            </>
        )
    }

    const renderSave = () => {

        return (
            <Grid item xs={12}>
                <Collapse in={unsavedChanges}>
                    <Alert severity={"warning"} action={
                        <Button
                            variant={"contained"} disableElevation
                            onClick={() => saveUserCustomStyles()}
                        >
                            {t("save_styles")}
                        </Button>
                    }>
                        {t("unsaved_changes")}

                    </Alert>
                </Collapse>
            </Grid>
        )
    }

    const renderColorsSection = () => {

        const handleChangeColor = (colorType: any, color: string) => {
            switch (colorType) {
                case "secondary": setSecondaryColor(color); break;
                case "primary":
                default: setPrimaryColor(color)
            }
        }

        return (
            <Paper variant={"outlined"} sx={styles.paperSection}>
                <Typography>{t("colors")}</Typography>

                <Typography sx={{ mt: 2 }} variant={"body2"}>
                    {t("colors_helper")}
                </Typography>

                <Grid container spacing={2} sx={{ mt: 1 }}>
                    <Grid item xs={11} md={6}>
                        <GeneratorColorPicker
                            label={t("primary_color")}
                            initialColor={primaryColor}
                            onChangeComplete={(color: string) => handleChangeColor("primary", color)}
                        />
                    </Grid>
                    <Grid item xs={11} md={6}>
                        <GeneratorColorPicker
                            label={t("secondary_color")}
                            initialColor={secondaryColor}
                            onChangeComplete={(color: string) => handleChangeColor("secondary", color)}
                        />
                    </Grid>
                </Grid>
            </Paper>
        )
    }

    const renderLogoSection = () => {
        return (
            <Paper variant={"outlined"} sx={styles.paperSection}>
                <Typography>{t("logo")}</Typography>

                <Typography sx={{ mt: 2 }} variant={"body2"}>
                    {t("theme_logo_helper")}
                </Typography>

                <label htmlFor={"upload-logo"} style={{ cursor: "pointer" }}>
                    <Paper variant="outlined" sx={styles.importPaper}>
                        <Upload sx={{ mb: "-6px" }} /> {t("import_logo")}
                    </Paper>
                </label>
                <input
                    accept="image/*"
                    id="upload-logo"
                    type="file"
                    onChange={handleSaveLogo}
                    style={{display: 'none'}}
                />

                <Button
                    color={"secondary"}
                    disableElevation
                    sx={{ mt: 1 }}
                    onClick={() => setLogo("https://unitag-public-assets.s3.eu-west-1.amazonaws.com/website/logos/unitagLogoWhite.svg")}
                >
                    {t("reset_logo")}
                </Button>
            </Paper>
        )
    }

    if (!!complexUser && (complexUser.organisation_role !== "OWNER" || complexUser.account_type !== platinumSubscription)) {
        return (
            <main>
                <Typography align={"center"} sx={{ mt: 3 }}>{t("theming_unavailable")}</Typography>
            </main>
        )
    }

    return (
        <main>
            <Container maxWidth="xl">
                <Grid container spacing={0} justifyContent="flex-start" alignItems="baseline">
                    {renderHeader()}
                    {/*<Typography>{t("theming_introduction")}</Typography>*/}
                    {renderSave()}
                    {renderColorsSection()}
                    {renderLogoSection()}
                </Grid>
            </Container>
        </main>
    )
}