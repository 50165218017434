import React, {useState, useEffect} from 'react';
// MUI
import {Typography, Accordion, AccordionSummary, AccordionDetails, Divider, Box} from '@mui/material';
import {ExpandMore} from "@mui/icons-material";
// Components
import {Button} from "../index";
// styles
import {styles} from "./styles";
// API
import {NotificationProps} from "@interfaces";
import DateDisplay from '../../Utils/DateDisplay';
// Trad
import {useTranslation, getI18n} from "react-i18next";



export default function  NotificationDetails(props: NotificationProps | any) {
    const {t} = useTranslation(['notifications', 'common']);

    const {expanded, handleExpand} = props;
    const {published_at, level, message, title, section, uuid, action, action_title} = props.notification;

    const [lang, setLang] = useState<string>("");

    useEffect(() => {
        setLang(getI18n().languages[1])
    }, [props]);

    // For now, styles only have "info", "default" and "warning". No "error" provided
    const returnSeverityStyles = (severity: string) => {
        switch (severity) {
            case "warning": return  {...styles.notificationAccordion, ...styles.notificationWarning};
            case "info": return  {...styles.notificationAccordion, ...styles.notificationInfo};
            default: return {...styles.notificationAccordion, ...styles.notificationDefault}
        }
    }

    const displaySection = () => {
        if (section ===  "") {
            return (
                <Typography>
                    &nbsp;|&nbsp;{t("no_section_found")}&nbsp;|&nbsp;
                </Typography>
            )
        } else {
            return (
                <Typography>
                    &nbsp;|&nbsp;{section}&nbsp;|&nbsp;
                </Typography>
            )
        }
    }

    const displayTitle = () => {
        if (title === null || title === undefined) {
            return (
                <Typography>
                    {t("no_title_found")}
                </Typography>
            );
        } 
        if (title[lang] === undefined || title[lang] === "") {
            if (title["en"] !== "") {
                return (
                    <Typography>
                        {title[lang]}
                    </Typography>
                );
            }
            return (
                <Typography>
                    {t("no_title_found")}
                </Typography>
            );
        }
        return (
            <Typography>
                {title[lang]}
            </Typography>
        );
    }

    const displayMessage = () => {
        if (message === null || message === undefined) {
            return (
                <Typography>
                     {t("no_message_found")}
                </Typography>
            );
        } 
        if (message[lang] === undefined || message[lang] === "") {
            if (title["en"] !== "") {
                return (
                    <Typography>
                        {message[lang]}
                    </Typography>
                );
            }
            return (
                <Typography>
                    {t("no_message_found")}
                </Typography>
            );
        }
        return (
            <Typography>
                {message[lang]}
            </Typography>
        );
    }

    const displayActionTitle = () => {
        if (action_title === null || action_title === undefined) {
            return t("proceed");
        }

        if (action_title[lang] === undefined || action_title[lang] === "") {
            if (action_title["en"] !== "") {
                return action_title[lang];
            }

            return t("proceed");
        }

        return action_title[lang];
    }

    const displayAction = () => {
        if (Object.keys(action).length > 1) {
            if (action[lang] === undefined || action[lang] === "") {
                if (action["en"] !== "") {
                    return action[lang];
                }
            }
        }

        return action[lang]
    }

    return (
        <Accordion
            expanded={expanded === uuid}
            onChange={handleExpand(uuid)}
            sx={returnSeverityStyles(level.name)}
        >
            <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography variant={"body2"} sx={styles.accordionHeaderTypoFix}>
                    <DateDisplay  originTime={published_at}/>
                </Typography>
                {displaySection()}
                
                {displayTitle()}
            </AccordionSummary>

            <Divider />

            <AccordionDetails>
                {displayMessage()}

                {
                    action &&
                    <>
                        <Box sx={styles.actionWrapper}>
                            <Button secondary href={displayAction()}>
                                {displayActionTitle()}
                            </Button>
                        </Box>
                    </>
                }
            </AccordionDetails>
        </Accordion>
    )
}