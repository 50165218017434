import React, {useEffect, useState} from "react"
import {
    Box, Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Paper,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import {APIPost} from "@api";
import {LoadingCircle} from "@components";

const {REACT_APP_API_URL} = process.env;

export default function ShareCreditsDialog(props: any) {

    const {open, onClose, subOrganisation, handleError, handleSuccess, sharingDialog, credits, t} = props

    const [vcardsCredits, setvCardCredits] = useState<any>(0)
    const [vcardsToTransfer, setVcardsToTransfer] = useState<number>(0)

    // const [subOrgCredits, setSubOrgCredits] = useState<any>([])

    // Lifecycles
    useEffect(() => {
        if (open && !!credits) {
            let _credits = credits.find((app: any) => app.service_name === "vcards")
            setvCardCredits(_credits.credits_left)
        }
    }, [open, credits]);

    const shareCredits = (_name: string, _credits: number) => {
        if (!sharingDialog || !sharingDialog.subOrganisation) return null

        let body = {
            "credits_amount": Number(vcardsToTransfer),
            "service": "vcards",
            "org_id": subOrganisation
        }

        APIPost(REACT_APP_API_URL + "/billing/credits/delegate", body).then((res) => {
            if (res.status === 200) {
                handleSuccess(t("credits_transferred"))
            } else handleError(t("error_transferring_credits"))
        }, () => {
            handleError(t("error_transferring_credits"))
        }).catch(() => {
            handleError(t("error_transferring_credits"))
        })

        return
    }

    // Renders
    const renderProductsTransferTable = () => {

        if (!credits) return <LoadingCircle />

        const products = [
            {
                label: t("vcards_pro"),
                name: "vcards",
                credits: vcardsCredits,
                state: vcardsToTransfer,
                setState: (num: number) => setVcardsToTransfer(num),
            }
        ]

        return (
            <>

                <Typography variant={"body2"} sx={{ mb: 2}}>
                    {t("share_credits_intro")}
                </Typography>

                <Paper elevation={0} variant={"outlined"}>
                    <Stack direction={"row"} justifyContent={"space-between"} sx={{ p: 2 }}>
                        <Typography>{t("product")}</Typography>
                        <Stack direction={"row"} justifyContent={"flex-end"} spacing={2}>
                            <Box sx={{ minWidth: 150 }}>
                                <Typography>{t("credits_owned")}</Typography>
                            </Box>
                            <Box sx={{ minWidth: 280 }}></Box>
                        </Stack>
                    </Stack>
                    {
                        products.map((product: any, i: number) => (
                            <Stack direction={"row"} justifyContent={"space-between"} key={i} sx={{ p: 2, backgroundColor: "rgba(239,239,239,0.51)" }}>
                                <Typography sx={{ mt: "6px" }}>{product.label}</Typography>
                                <Stack direction={"row"} justifyContent={"flex-end"} spacing={2}>
                                    <Box sx={{ minWidth: 150 }}>
                                        <Typography sx={{ mt: "6px" }}>{product.credits}</Typography>
                                    </Box>
                                    <TextField
                                        size={"small"}
                                        type={"number"}
                                        value={product.state}
                                        label={t("products_to_share")}
                                        sx={{ minWidth: 200 }}
                                        inputProps={{
                                            min: 0,
                                            max: 50
                                        }}
                                        fullWidth
                                        onChange={(e) => {
                                            let val = e.target.value as unknown as number
                                            if (val < 0) {
                                                product.setState(0);
                                                return;
                                            }
                                            product.setState(val)
                                        }}
                                    />

                                    <Button
                                        onClick={() => shareCredits(product.name, product.state)}
                                        disabled={product.credits < vcardsToTransfer}
                                    >
                                        Share
                                    </Button>
                                </Stack>
                            </Stack>
                        ))
                    }
                </Paper>
            </>
        )
    }

    return (
        <>
            <Dialog open={open} onClose={onClose} maxWidth={"md"} fullWidth>
                <DialogTitle>{t("share_credits")}</DialogTitle>
                <DialogContent>
                    {renderProductsTransferTable()}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => onClose()}>
                        {t("Close")}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}