import React, {useEffect, useState} from "react"
import {Alert, Collapse, Divider, Grid, Link, Paper, Slider, TextField, Typography} from "@mui/material";
import {roundToCent} from "@functions";
import {KeyboardArrowDown, KeyboardArrowUp} from "@mui/icons-material";


export default function AlacarteImageModule(props: any) {

    const {module, currency, yearly, large, t} = props
    const styles = {
        modulePaperWrapper: {
            backgroundColor: "transparent",
            p: 4,
            mb: 5,
            borderRadius: 6
        },
    }

    // features collapse
    const [openFeatures, setOpenFeatures] = useState<boolean>(false)
    // prices
    const [rawTotal, setRawTotal] = useState<number>(0)
    const [netTotal, setNetTotal] = useState<number>(0)
    // discounts
    const [discountValue, setDiscountValue] = useState<number>(0)

    useEffect(() => {
        // cleaner
        if (module.value && module.value > module.maxCredits) module.setValue(module.maxCredits)
        else if (!module.value) module.setValue(0)

        calculateRawTotal()
        calculateDiscountValue()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [module.value])

    // calculate net total when price data are changed
    useEffect(() => {
        calculateNetTotal()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rawTotal, yearly])

    // Calculation
    const calculateRawTotal = () => { setRawTotal(roundToCent(module.value * module.basePrice)) }

    const calculateNetTotal = () => {
        let value: number

        if (discountValue !== 0) {
            value = roundToCent(module.value * (module.basePrice * discountValue))
            module.setPrice(value)
            if (yearly) setNetTotal(roundToCent(value * 12 * 0.9))
            else setNetTotal(value)
        }
        else {
            value = roundToCent(module.value * module.basePrice)
            module.setPrice(value)
            if (yearly) setNetTotal(roundToCent(value * 12 * 0.9))
            else setNetTotal(value)
        }
    }

    // discount
    const calculateDiscountValue = () => {
        if (!module.discounts || module.discounts.length < 1) setDiscountValue(0)
        else module.discounts.forEach((discount: any) => {
            if (module.discounts[0].tier > module.value) setDiscountValue(0)
            if (discount.tier <= module.value) setDiscountValue(discount.value)
        })
    }

    // Render parts
    const renderOwnedItems = () => {
        if (module.owned < 1) return <></>
        return (
            <Grid item xs={12}>
                <Collapse in={module.owned >= 1}>
                    <Typography variant={"body1"} fontWeight={700} color={"secondary"} align={"left"}>
                        {t("you_have_")} {module.owned} {module.productName}
                    </Typography>
                </Collapse>
            </Grid>
        )
    }

    const renderFeaturesList = () => {

        if (!module.features) return <>no feats</>

        const handleOpenFeatures = (e: any) => {
            e.preventDefault()
            setOpenFeatures(!openFeatures)
        }

        return (
            <Grid item xs={12}>
                <Link
                    onClick={(e) => handleOpenFeatures(e)}
                    color={"secondary"}
                    sx={{ cursor: "pointer" }}
                >
                    {t("features_included")}&nbsp;
                    {openFeatures ?
                        <><KeyboardArrowUp fontSize={"inherit"} /></> :
                        <><KeyboardArrowDown fontSize={"inherit"} /></>
                    }
                </Link>

                <Collapse in={openFeatures}>
                    <ul style={{ marginTop: 16 }}>
                        {module.features.map((feat: any, index: number) => (
                            <li style={{ margin: '8px 0 8px 8px'}} key={index}>
                                <Typography variant={"body2"}>
                                    <i>{t(feat)}</i>
                                </Typography>
                            </li>
                        ))}
                    </ul>
                </Collapse>
            </Grid>
        )
    }

    const renderSliderAndUnitPrice = () => {
        return (
            <>
                <Grid item xs={12} sm={9} md={4} lg={6} sx={{ px: 2 }}>
                    <Slider
                        disabled={module.owned >= module.maxTotal}
                        value={module.value}
                        onChange={(e, val) => module.setValue(val as number)}
                        min={0}
                        max={module.maxCredits}
                        marks={[ { value: module.maxCredits, label: module.maxCredits } ]}
                        sx={{ "& .MuiSlider-mark": { opacity: 0 } }}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={6} justifyContent={"center"} sx={{ px: 2 }}>
                    <TextField
                        disabled={module.owned >= module.maxTotal}
                        label={module.total}
                        value={module.value}
                        onChange={(e:any) => module.setValue(e.target.value as number)}
                        size={"small"}
                        fullWidth
                    />
                </Grid>
            </>
        )
    }

    const renderTotalPrice = () => {

        if (Boolean(
            (module.value && (module.value >= module.maxCredits || Number(module.owned) + Number(module.value) >= module.maxTotal))
            || (!!module.owned && (Number(module.owned) >= module.maxTotal)))
        ) {
            return (
                <Alert severity={"warning"} sx={{ mt: 1}}>
                    {t("max_credits_reached")}
                </Alert>
            )
        }

        const returnUnitPrice = () => {
            let _unitPrice

            if (discountValue !== 0) _unitPrice = (module.basePrice * discountValue).toFixed(2)
            else _unitPrice = module.basePrice

            let discountedUnitPrice = (_unitPrice * 0.9).toFixed(2)

            if (yearly) return `${discountedUnitPrice} ${currency}`
            else return `${_unitPrice} ${currency}`
        }

        return (
            <>
                <Grid item xs={12} sm={6} md={4} lg={6} textAlign={"left"}>
                    <Typography fontWeight={600} gutterBottom>
                        {t("price_per_unit")}
                    </Typography>
                    {returnUnitPrice()}
                </Grid>
                <Grid item xs={6} textAlign={"right"}>
                    <Typography variant={"subtitle1"} component={"p"} fontWeight={600}>
                        &nbsp;{yearly ? t("total_net_yearly") : t("total_net_monthly")}
                    </Typography>
                    <Typography variant={"h5"} component={"p"} color={"secondary"}>
                        {netTotal} {currency}
                    </Typography>
                </Grid>
            </>
        )
    }

    return (
        <>
            <Paper variant={"outlined"} sx={styles.modulePaperWrapper}>
                <Grid container justifyContent={"center"} rowSpacing={3}>
                    {/* Intro */}
                    <Grid item xs={12}>
                        <Typography variant={"h5"} component={"p"} gutterBottom>
                            {module.icon} {module.name}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} textAlign={"center"}>
                        <img width={large ? 500 : 400} height={large ? 500 : 400} alt={module.label} src={module.image} />
                    </Grid>

                    {renderOwnedItems()}
                    {renderFeaturesList()}
                    {renderSliderAndUnitPrice()}
                    <Grid item xs={10}><Divider /></Grid>
                    {renderTotalPrice()}
                </Grid>
            </Paper>
        </>
    )
}