import React, {createContext, useContext, useState} from "react"
// API methods
import {APIGet, APIPost} from "@api";
// Types
import {
    Campaign,
    CreditsResponse,
    GetCurrentInvoiceResponse,
    GetCurrentSubscriptionDetails,
    GetOrganisationResponse,
    GetOrgAsMember, GetPlansResponse,
    Invitation,
    PaymentMethods,
    PostLoginResponse, User
} from "@interfaces";
// Error management
import {useSnackbar} from "notistack";
// translation
import {useTranslation} from "react-i18next";

const UserContext = createContext<any>(null)

export const useUser = () => useContext(UserContext)

const {REACT_APP_API_URL} = process.env;

export function UserProvider({children}: any) {

    const {enqueueSnackbar} = useSnackbar()
    const {t} = useTranslation(["common"])

    // May be changed in the future.
    // This state allows for this file's alerts to be displayed, and could be used as a global Connected state.
    // It is triggered by the PostLoginUser (getUser) call, which confirms connection, called within the Navbar comp.
    const [connected, setConnected] = useState(false)
    const [credentials, setCredentials] = useState<any>(null)
    // User (postLogin & complex)
    const [user, setUser] = useState<PostLoginResponse | undefined>(undefined)
    const [complexUser, setComplexUser] = useState<User | undefined>(undefined)
    // Credits, billing, invoice
    const [credits, setCredits] = useState<CreditsResponse[] | undefined>(undefined)
    const [creditsDetails, setCreditsDetails] = useState<GetCurrentSubscriptionDetails | undefined>(undefined)
    const [billingInvoice, setBillingInvoice] = useState<GetCurrentInvoiceResponse | undefined>(undefined)
    const [billingMethod, setBillingMethod] = useState<PaymentMethods | null | undefined>(undefined)
    const [plans, setPlans] = useState<GetPlansResponse | undefined>(undefined)
    // Organisations
    const [organisation, setOrganisation] = useState<GetOrganisationResponse | undefined>(undefined)
    const [organisationInvitations, setOrganisationInvitations] = useState<Invitation[] | undefined>(undefined)
    const [filteredOrganisationInvitations, setFilteredOrganisationInvitations] = useState<Invitation[] | undefined>(undefined)
    const [organisationAsMember, setOrganisationAsMember] = useState<GetOrgAsMember | undefined>(undefined)
    const [organisationUsers, setOrganisationUsers] = useState<any | undefined>(undefined)
    // Campaigns
    const [campaigns, setCampaigns] = useState<Campaign[] | undefined>(undefined)
    // Apps authorisations
    const [authorisations, setAuthorisations] = useState<any>({
        domains: false,
        apiKeys: false,
        organisations: false,
        subOrganisations: false
    })
    // Specific billing alerts
    const [billingAlert, setBillingAlert] = useState<boolean>(false)

    const handleError = (message: any) => connected ? enqueueSnackbar(message, {variant: "error"}) : null

    const getUser = async () => {
        await APIPost<PostLoginResponse>(REACT_APP_API_URL + "/login", {}).then((data) => {
            if (data.status === 200 && !!data.parsedBody) {
                let _user = data.parsedBody;
                setUser(_user);
                setConnected(true)
                if (data.parsedBody.display_alert) setBillingAlert(data.parsedBody.display_alert)
            }
        }, () => {
            // Error not explicitely catched because might throw useless UI when disconnected
            throw new Error()
        });
    }

    const getComplexUser = async () => {
        APIGet<User>(REACT_APP_API_URL + "/user").then((data) => {
            if (data.status === 200 && !!data.parsedBody) setComplexUser(data.parsedBody)
        }, (error) => {
            handleError(t("error_get_complex_user"))
            throw new Error(error)
        })
    }

    const getCredits = async () => {
        await APIGet<CreditsResponse[]>(REACT_APP_API_URL + "/billing/credits").then((data) => {
            if (data.status === 200 && !!data.parsedBody) setCredits(data.parsedBody)
        }, (error) => {
            handleError(t("error_get_credits"))
            throw new Error(error)
        });
    }

    const getCreditsDetails = async () => {
        await APIGet<GetCurrentSubscriptionDetails>(REACT_APP_API_URL + "/billing/subscription_details").then((data) => {
            if (data.status === 200 && !!data.parsedBody) setCreditsDetails(data.parsedBody)
        }, (error) => {
            handleError(t("error_get_credits"))
            throw new Error(error)
        });
    }

    const getBillingMethod = async () => {
        await APIGet<PaymentMethods>(REACT_APP_API_URL + "/billing/payment_methods").then((data) => {
            if (data.status === 404) {
                setBillingMethod(null)
                throw new Error()
            }
            if (data.status === 200 && !!data.parsedBody) setBillingMethod(data.parsedBody)
            return data.parsedBody
        }, (error) => {
            handleError(t("error_get_payment_method"))
            throw new Error(error)
        });
    }

    const getBillingInvoice = async () => {
        await APIGet<GetCurrentInvoiceResponse>(REACT_APP_API_URL + "/billing/last_invoice").then((data) => {
            if (data.status === 200 && !!data.parsedBody) setBillingInvoice(data.parsedBody)
        }, (error) => {
            handleError(t("error_get_last_invoice"))
            throw new Error(error)
        });
    }

    const getPlans = async () => {
        await APIGet<GetPlansResponse>(REACT_APP_API_URL + "/billing/plans").then((data: any) => {
            if (data.status === 200 && !!data.parsedBody && !!data.parsedBody.plans) setPlans(data.parsedBody.plans)
        }, error => {
            handleError(t("error_get_plans"))
            throw new Error(error)
        })
    }

    const getOrganisation = async () => {
        await APIGet<GetOrganisationResponse>(REACT_APP_API_URL + "/orgs").then((data) => {
            if (data.status === 200 && !!data.parsedBody) setOrganisation(data.parsedBody)
        }, (error) => {
            handleError(t("error_get_organisation"))
            throw new Error(error)
        });
    }

    const getOrganisationInvitations = async (organisationId: any) => {
        await APIGet<Invitation[]>(REACT_APP_API_URL + "/org/" + organisationId + "/invitations").then((data) => {
            if (data.status === 200 && !!data.parsedBody) setOrganisationInvitations(data.parsedBody)
            else setOrganisationInvitations([])
        }, (error) => {
            handleError(t("error_get_organisation_members"))
            throw new Error(error)
        });
    }

    const getFilteredOrganisationInvitations = async (organisationId: any, filters?: any) => {
        let params: any = new URLSearchParams("?maxRows=10&sortOrder=desc")

        if (filters) {
            let _filters = {
                sortOrder: filters.order,
                maxRows: filters.maxRows,
                page: filters.page,
                filterPerm: filters.admin ? "admin" : filters.members ? "MEMBER" : "",
                filterEmail: filters.email ? filters.email : ""
            }

            params = new URLSearchParams(_filters)
        }

        await APIGet<Invitation[]>(REACT_APP_API_URL + "/org/" + organisationId + "/invitations?" + params).then((data) => {
            if (data.status === 200 && !!data.parsedBody) setFilteredOrganisationInvitations(data.parsedBody)
            else setFilteredOrganisationInvitations([])
        }, (error) => {
            handleError(t("error_get_organisation_members"))
            throw new Error(error)
        });
    }

    const getOrganisationAsMember = async () => {
        await APIGet<GetOrgAsMember>(REACT_APP_API_URL + "/org").then((data) => {
            if (data.status === 200 && !!data.parsedBody)
                setOrganisationAsMember(data.parsedBody)
        }, (error) => {
            handleError(t("error_get_organisation"))
            throw new Error(error)
        });
    }

    const getOrganisationUsers = async (organisationId: any) => {
        await APIGet<User[]>(REACT_APP_API_URL + "/org/" + organisationId + "/users").then((data) => {
            if (data.status === 200 && !!data.parsedBody) setOrganisationUsers(data.parsedBody)
        }, () => {
            handleError(t("error_get_organisation_users"))
        })
    }

    const getCampaigns = async () => {

        const fetchCampaigns = async () => {
            await APIGet<Array<Campaign>>(REACT_APP_API_URL + "/qrcodes/campaigns").then((data) => {
                if (data.ok) {
                    if (!!data.parsedBody && data.parsedBody.length > 0) setCampaigns(data.parsedBody)
                    else setCampaigns([])
                }
            }, (error) => {
                handleError(t("error_get_campaigns"))
                throw new Error(error)
            });
        }

        if (
            (!!user && (user.account_type !== "free" && user.account_type !== "standard")) ||
            (!!organisationAsMember && (organisationAsMember.role === 'ADMIN' || organisationAsMember.role === 'MEMBER')) ||
            (!!organisation && (organisation.role === 'ADMIN' || organisation.role === 'MEMBER'))
        ) {
            fetchCampaigns().then()
        } else {
            throw new Error("User does not match subscription tier requirement for campaigns")
        }
    }

    const getAuthorisations = async () => {
        if (!!complexUser) {
            let accountType = complexUser?.account_type
            let role = complexUser?.organisation_role
            setAuthorisations({
                domains: (accountType !== "free" && accountType !== "standalone" && accountType !== "standard") || role === "ADMIN",
                apiKeys: (accountType !== "free" && accountType !== "standalone") || role === "ADMIN",
                organisations: (accountType !== "free" && accountType !== "standard") || role === "ADMIN",
                subOrganisations: (accountType === "platinum" || organisationAsMember?.org_account_type === "platinum")
            })
        }
    }

    return (
        <UserContext.Provider
            value={{
                useUser,
                // states
                connected,
                credentials, setCredentials,
                // fetching data
                getUser, user,
                getComplexUser, complexUser,
                getCredits, credits,
                getCreditsDetails, creditsDetails,
                getBillingMethod, billingMethod,
                getBillingInvoice, billingInvoice,
                getPlans, plans,
                getOrganisation, organisation,
                getOrganisationInvitations, organisationInvitations,
                getFilteredOrganisationInvitations, filteredOrganisationInvitations,
                getOrganisationAsMember, organisationAsMember,
                getOrganisationUsers, organisationUsers,
                getCampaigns, campaigns,
                getAuthorisations, authorisations,

                // specific
                billingAlert
            }}
        >
            {children}
        </UserContext.Provider>
    )
}
