import React from 'react';
import {Paper, Typography} from '@mui/material';

export default function PaperWithLabel(props: any) {

    const ref = React.useRef(null);
    const {children, label, style, containerStyle, fullWidth} = props;

    return (
        <Paper
            ref={ref}
            elevation={0}
            square={false}
            variant='outlined'
            style={{
                position: 'relative',
                width: fullWidth ? "100%" : "auto",
                ...style
            }}
        >
            <Typography style={{
                position: 'absolute',
                paddingLeft: '3px',
                paddingRight: '3px',
                top: '-12px',
                left: '15px',
                backgroundColor: 'white'
            }}>
                &nbsp;{label}&nbsp;
            </Typography>

            <div style={{
                padding: '16px',
                ...containerStyle
            }}>
                {children}
            </div>
        </Paper>
    );
}
