// REACT
import React, {useEffect, useState} from 'react';
// MUI
import {Grid, Paper, Skeleton, Typography} from '@mui/material';
// ASSETS
import {ReactComponent as HealthchecksIcon} from "@assets/svg/apps/healthcheck.svg";
import {Domain, GroupWork, QrCode2Outlined, RememberMeOutlined} from "@mui/icons-material";
// API
import {SubscriptionItem} from "@interfaces";
// TRANSLATION
import {useTranslation} from 'react-i18next';
// Components
import {ServiceBillingList} from "./ServiceBillingList";
// Context
import {useUser} from "@context";
// Utils
import {returnUsedCurrency} from "@functions";
import moment from "moment";

export default function BillingPerServiceTab() {

    const {t} = useTranslation(['settings', 'common']);
    const {user, credits, getCredits, creditsDetails, getCreditsDetails} = useUser()

    const styles = {
        iconList: {
            height: '30px',
            fontSize: 30,
            fill: '#525252',
        },
        skeleton: { borderRadius: 1 },
        synthesisPaper: { bgcolor: "#f6f8fa", p: 2, width: '100%' },
        serviceWrapper: { width: '100%' },
        subscriptionPaper: { p: 2, width: '100%', my: 2 },
        trialPaper: { p: 2, width: '100%', my: 2, backgroundColor: "#c0e5bc" },
        subtitle: { marginTop: '16px' }

    };

    // Global states
    const [currency, setCurrency] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(true);
    // Specific credits
    const [creditsQRCodes, setCreditsQRCodes] = useState<any>(null);
    // subscription
    const [subscriptionInterval, setSubscriptionInterval] = useState<string>('');
    const [subscriptionType, setSubscriptionType] = useState<string>('');
    const [subscriptionPrice, setSubscriptionPrice] = useState<number>(0);
    // Services
    const [services, setServices] = React.useState<SubscriptionItem[]>([]);
    // Total
    const [totalDue, setTotalDue] = React.useState<number>(0);

    useEffect(() => {
        getCredits()
        getCreditsDetails()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!!creditsDetails) initSubscription(creditsDetails)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [creditsDetails])

    useEffect(() => {
        if (!!credits) handleQRCodesCredits(credits)
    }, [credits])

    useEffect(() => {
        setTotalDue(0);
        if (!!subscriptionPrice) calculateTotalDue()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [services, subscriptionPrice])

    // Subscription and credits management
    const createServicesList = (services: any) => {
        let usedServices: SubscriptionItem[] = [];
        for (const item of services) {
            if ((!!item.credit.left && item.credit.left > 0) || (!!item.credit.bought && item.credit.bought > 0)) {
                let icon;
                switch (item.type) {
                    case "healthchecks":
                        icon = <HealthchecksIcon style={styles.iconList}/>;
                        break;
                    case "extra_users_platinum" || "extra_users_gold":
                        icon = <GroupWork style={styles.iconList}/>;
                        break;
                    case "extra_vcards":
                        icon = <RememberMeOutlined style={styles.iconList}/>;
                        break;
                    case "domains":
                        icon = <Domain style={styles.iconList}/>;
                        break;
                    default:
                        icon = <QrCode2Outlined style={styles.iconList}/>;
                        break;
                }
                let newItem: SubscriptionItem = {...item, icon: icon};
                usedServices.push(newItem);
            }
        }
        setServices(usedServices);
    }

    const initSubscription = (sub: any) => {

        setCurrency(returnUsedCurrency(sub.currency))

        if (sub.subscription.interval !== "" && sub.subscription.interval !== null) {
            setSubscriptionInterval(sub.subscription.interval);
        }

        setSubscriptionType(sub.subscription.type);
        setSubscriptionPrice(sub.subscription.price);

        if (!!sub.subscription_items) createServicesList(sub.subscription_items)
        setLoading(false);
    }

    const calculateTotalDue = () => {
        if (!!services && services.length > 0) {
            let servicesPrice = services.reduce((acc: any, service: any) => acc + service.price, 0);
            let total = subscriptionPrice + servicesPrice
            let totalRounded = Math.floor(total * 100) / 100
            let due = totalRounded.toFixed(2)
            setTotalDue(due as unknown as number);
        } else {
            setTotalDue(subscriptionPrice)
        }
    }

    // Handle specific information and hydrate credits
    const handleQRCodesCredits = (credits: any) => {
        for (const item of credits) {
            switch (item.service_name) {
                case "qrcodes":
                    setCreditsQRCodes({
                        bought: item.credits_bought,
                        left: item.credits_left,
                        used: item.credits_used
                    });
                    break;
            }
        }
    }

    // Display
    const renderSynthesis = () => {

        if (loading) return (
            <Grid item xs={12}>
                <Skeleton variant={"rectangular"} width={"100%"} height={179} sx={styles.skeleton}/>
            </Grid>
        )

        if (!!user && user.account_type === "free") return <></>

        return (
            <Grid item xs={12} sx={{display: loading ? "none" : "block"}}>

                {
                    (!!creditsDetails && creditsDetails.is_trial) &&
                    <Paper variant={"outlined"} sx={styles.trialPaper}>
                        <Typography variant={"body2"} align={"left"} sx={{mt: "2px"}} color={"success.dark"} fontWeight={700}>
                            {t("trial_still_ongoing_until")}{`${moment.unix(creditsDetails.day_trial_left).format("DD/MM/YYYY")}`}
                        </Typography>
                    </Paper>
                }

                <Paper variant={"outlined"} sx={styles.synthesisPaper}>
                    <Grid container justifyContent={"space-between"}>
                        <Grid item xs={12} sm={6}>
                            <Typography variant={"body1"} align={"left"}>
                                <b>{t("Your_billing_is")}&nbsp;{t(`${subscriptionInterval}`)}</b>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography variant={"body1"} align={"right"}>
                                <b>{totalDue}&nbsp;{currency}</b>
                            </Typography>
                        </Grid>
                    </Grid>
                </Paper>

                <Paper variant={"outlined"} sx={styles.subscriptionPaper}>
                    <Grid container justifyContent={"space-between"}>
                        <Grid item xs={12} sm={6} alignItems={'center'}>
                            <Typography variant={"body2"} align={"left"} sx={{mt: "2px"}}>
                                {t("Subscription_type")}&nbsp;{t(`${subscriptionType}`)}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography variant={"body1"} align={"right"}>
                                {subscriptionPrice}&nbsp;{currency}
                            </Typography>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        )
    }

    return (
        <>
            <Grid container alignItems="center" alignContent="center" data-testid={"billingPerService"}>
                {/* Synthesis section */}
                {renderSynthesis()}
                {/*  Credits details  */}
                <ServiceBillingList {...{
                    creditsQRCodes,
                    currency,
                    subscriptionInterval, subscriptionType,
                    services,
                    styles, t
                }} />
            </Grid>
        </>
    );

}
