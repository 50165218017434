import React, {FunctionComponent, ReactElement, Suspense, useEffect} from 'react';
import {useHistory, useLocation} from "react-router-dom";
// MUI
import {
    Backdrop,
    CircularProgress, Link,
} from '@mui/material';
// EXTERNAL APIS
import {Amplify, Auth} from 'aws-amplify';

import Login from './components/login';
import {sanitizeUrl} from "@functions";

const {
    REACT_APP_COGNITO_POOL_ID,
    REACT_APP_COGNITO_APP_ID,
    REACT_APP_COGNITO_APP_DOMAIN,
    REACT_APP_COGNITO_APP_REDIRECT_SIGNIN,
    REACT_APP_COGNITO_APP_REDIRECT_SIGNOUT
} = process.env;


Amplify.configure({
    Auth: {
        region: 'eu-west-1',
        userPoolId: REACT_APP_COGNITO_POOL_ID,
        userPoolWebClientId: REACT_APP_COGNITO_APP_ID,
        mandatorySignIn: false,
        authenticationFlowType: 'USER_PASSWORD_AUTH',
        oauth: {
            domain: REACT_APP_COGNITO_APP_DOMAIN,
            clientID: REACT_APP_COGNITO_APP_ID,
            responseType: "token",
            scope: ["email","openid","profile"],
            redirectUri: REACT_APP_COGNITO_APP_REDIRECT_SIGNIN,
            redirectSignIn: REACT_APP_COGNITO_APP_REDIRECT_SIGNIN,
            redirectSignOut: REACT_APP_COGNITO_APP_REDIRECT_SIGNOUT
        }
    }
});


function Loader() {
    return (
        <Backdrop style={{color: "white"}} open={true}>
            <CircularProgress color="inherit"/>
        </Backdrop>
    );
}

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function LoginSecurityWrapper() {

    let query = useQuery();
    const history = useHistory();

    const [product, setProduct] = React.useState<null | string>("");

    useEffect(() => {

        if (window.self !== window.top) {
            return
        }
        const eid = query.get("eid");
        if (eid !== undefined && eid !== null) {
            history.push("/signup");
        }

        const followedProduct = query.get("product");
        setProduct(followedProduct);

        // This async call does not need to be catched for it manages redirection and will always throw an error if no credentials
        Auth.currentSession().then((sess) => {
            sess.getIdToken();
            // eslint-disable-next-line no-restricted-globals
            history.push("/");
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const UnsecuredPage: FunctionComponent = (): ReactElement => (
        <div style={{paddingTop: '50px'}}>
            <h1 style={{color: 'red'}}>If you see this page, the Unitag page you're trying to access is under Clickjacking
                security attack.</h1>
            <h2>Please inform the support team with the reference of the application from where you clicked this link.</h2>
            <h2>Click <Link href={sanitizeUrl(window.self.location.href)} title='Unitag console login' target='blank'>here</Link> to
                access the Unitag console safely.</h2>
        </div>
    );

    if (window.self === window.top) {
        return (
            <Suspense fallback={<Loader/>}>
                <Login product={product}/>
            </Suspense>
        );
    } else {
        return (
            <UnsecuredPage/>
        )
    }

}
