import {useEffect, useMemo, useState} from "react";

function useIsInViewport(ref: any) {
    const [isIntersecting, setIsIntersecting] = useState<boolean>(false);

    // Keep value in memory
    const observer = useMemo(() =>
        new IntersectionObserver(([entry]) => setIsIntersecting(entry.isIntersecting)),
    []);

    // Check on ref and intersection if the ref is visible
    useEffect(() => {
        observer.observe(ref.current)
        return () => observer.disconnect()
    }, [ref, observer]);

    // Return simple-ass boolean
    return isIntersecting;
}

export {useIsInViewport}