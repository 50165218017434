import React, {useEffect, useState} from "react"
import {
    Box,
    Button,
    Grid,
    Tooltip,
    tooltipClasses,
    TooltipProps, Typography
} from "@mui/material";
import {styled} from "@mui/material/styles";
import {useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useTour} from "@/Context/Tour/TourProvider";

export default function TourPopup(props: any) {

    const {handleTourEnd} = useTour()

    const {
        state, setState,
        steps, step,
        offset,
        children,
        // to replace content with something more direct or action per-page driven
        hardContent,
        // for specific actions on interactions
        onNextAction,
        // Key for tour end
        tourApiIdentifier,
    } = props

    const {t} = useTranslation("tours")

    // special code to avoid throttle
    // ----------------------------------------------------------------
    const [allow, setAllow] = useState(true)

    const location = useLocation()

    useEffect(() => {
        setAllow(false)
        setTimeout(() => {
            setAllow(true)
        }, 500)
    }, [location])
    // ----------------------------------------------------------------

    const data = !!steps ? steps.find((step: any) => step.step === state) : null

    if (!data || state === undefined || !steps) return children

    const {title, subtitle, content, position, text} = data

    // Action methods
    const onNext = () => {
        // check if step exist
        // knowing that we count steps from 1 and arrays starts at 0, we check for steps[step] instead of steps[step+1]
        if (onNextAction) onNextAction()
        if (!!steps[step]) setState(state + 1)
        else onFinishTour()
    }

    const onPrevious = () => {
        if (!!steps[step-1] && state > 1) setState(state - 1)
        else onFinishTour()
    }

    const onFinishTour = () => {
        setState(undefined)
        if (!!tourApiIdentifier) handleTourEnd(tourApiIdentifier)
    }

    // Tooltip content
    const renderContent = () => {

        const renderHeader = () => {
            return (
                <Grid item xs={12} mx={2} mt={1}>
                    <Typography variant={"subtitle1"} component={"p"} fontSize={13} pb={0} textTransform={"uppercase"} fontWeight={600} color={"darkgrey"}>
                        {title}
                    </Typography>
                    <Typography variant={"h6"} component={"p"} lineHeight={"1.2"} fontSize={16} fontWeight={800}>
                        {subtitle}
                    </Typography>
                </Grid>
            )
        }

        const renderMainContent = () => {
            return (
                <Grid item xs={12} px={2}>
                    {
                        hardContent ? hardContent :
                            content ? content :
                                text ? text : ""
                    }
                </Grid>
            )
        }

        const renderActions = () => {

            const actionStyles = {
                lastSection: {
                    backgroundColor: "lightgrey!important",
                    mt: 1,
                    p: 1,
                    borderRadius: "0 0 4px 4px",
                    borderTop: "1px solid darkgrey"
                },
                finishTour: {
                    color: "grey",
                    display: !steps[step] ? "none" : "block",
                    "&:hover": {
                        textDecoration: "underline",
                        cursor: "pointer"
                    }
                }
            }

            return (
                <>
                    <Grid item xs={12} textAlign={"right"} px={2}>
                        {
                            step !== 1 &&
                            <>
                                <Button
                                    size={"small"}
                                    onClick={() => onPrevious()}
                                    disabled={step === 1}
                                >
                                    {t("previous")}
                                </Button>&nbsp;&nbsp;
                            </>
                        }

                        <Button
                            size={"small"}
                            variant={!steps[step] ? "contained" : "outlined"}
                            disableElevation
                            onClick={() => onNext()}
                        >
                            {!steps[step] ? t("end") : t("next")}
                        </Button>
                    </Grid>

                    <Grid item xs={12} container sx={actionStyles.lastSection}>
                        <Grid item xs={6} >
                            <Typography
                                variant={"subtitle2"}
                                fontSize={12}
                            >
                                {step}/{steps.length}
                            </Typography>
                        </Grid>

                        <Grid item xs={6}>
                            <Typography
                                align={"right"}
                                variant={"subtitle2"}
                                fontSize={12}
                                sx={actionStyles.finishTour}
                                onClick={() => onFinishTour()}
                            >{t("exit_tour")}</Typography>
                        </Grid>
                    </Grid>
                </>
            )
        }

        return (
            <Box>
                <Grid container rowSpacing={1}>
                    {renderHeader()}
                    {renderMainContent()}
                    {renderActions()}
                </Grid>
            </Box>
        )
    }

    // Tooltip itself
    const TourTooltip = styled(({ className, ...props}: TooltipProps) => (
        <Tooltip {...props} arrow classes={{ popper: className }}  />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
            color: position === "" || position.includes("bottom") ? "lightgrey" : "white",
        },
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.white,
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
            fontSize: 11,
            padding: 0
        },

    }))

    return (
        <React.Fragment>
            {/*<Backdrop open={state === step && allow} sx={{ backgroundColor: "transparent" }} />*/}
            <TourTooltip
                title={renderContent()}
                placement={position ? position : "bottom"}
                arrow
                disableFocusListener
                disableHoverListener
                disableTouchListener
                open={state === step && allow}
                slotProps={{
                    popper: {
                        modifiers: [
                            {
                                name: 'offset',
                                options: {
                                    offset: offset ? [offset.x, offset.y] : [0, 0],
                                },
                            },
                        ],
                    },
                }}
                // onClose
                sx={{ zIndex: 9999 }}

            >
                <span>{children}</span>
            </TourTooltip>
        </React.Fragment>
    )
}