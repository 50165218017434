export const styles = {
    paper: {
        p: 1,
        m: 1,
        cursor: "pointer"
    },
    notificationWarning: {
        bgcolor: "#FFF4E5"
    },
    notificationInfo: {
        bgcolor: "#E5F6FD"
    },
    notificationDefault: {
        bgcolor: "white"
    },
    heading: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignContent: "center",
    },
    date: {
        fontSize: 13,
        color: 'grey'
    },
    dot: {
        fontSize: "13px",
    },
    notificationAccordion: {
        borderRadius: 2,
        my: 1,
        "&::before": {
            height: 0
        }
    },
    accordionHeaderTypoFix: {
        alignSelf: "center",
        lineHeight: "unset"
    },
    accordionDetailsFix: {
        padding: "16px"
    },
    accordionAction: {
        px: 2,
        textAlign: "right"
    },
    actionDivider: {
      margin: "24px 0"
    },
    actionWrapper: {
        textAlign: "right",
        width: "100%",
        marginBottom: "16px"
    }
}