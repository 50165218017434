import React from "react"
// MUI
import {
    Autocomplete, Box,
    Divider,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from "@mui/material";
import {Search, SubdirectoryArrowRight} from "@mui/icons-material";
// styles
import {styles} from "../styles";
// Components
import {Button} from "@components";

export default function OrgUsersFilters(props: any) {

    const {
        initOrg,
        params, setParams,
        selectedOrganisation, setSelectedOrganisation,
        subOrganisations,
        t
    } = props

    // Filters
    const filterUsersByEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
        setParams({...params, page: 1, email: e.target.value})
    }

    // Components
    const subOrganisationsAutocomplete = () => {

        let _subOrgs = []

        if (!subOrganisations && !initOrg) return <></>

        if (!subOrganisations) _subOrgs = [initOrg]
        else _subOrgs = [initOrg].concat(subOrganisations)

        if (!!_subOrgs) {
            return (
                <>
                    <Autocomplete
                        size={"small"}
                        disableClearable
                        autoHighlight
                        noOptionsText={t("common:No_elements_found")}
                        value={selectedOrganisation}
                        onChange={(e, newValue) => setSelectedOrganisation(newValue)}
                        disablePortal
                        getOptionLabel={(option: any) => option.organisation_name ? option.organisation_name : ""}
                        options={_subOrgs}
                        renderInput={(params) => <TextField {...params} label={t("Organization")} />}
                        renderOption={(props, option) => (
                            <Box component={"li"} {...props} key={option.organisation_id}>
                                {
                                    option.organisation_name !== initOrg.organisation_name &&
                                    <><SubdirectoryArrowRight fontSize={"inherit"} />&nbsp;</>
                                }
                                {option.organisation_name}
                            </Box>
                        )}
                    />
                </>
            )
        }

        return <>   </>
    }

    return (
        <>
            <Divider textAlign={"left"} sx={styles.blockBalance}>
                <Typography variant={"h6"} component={"p"}>
                    {t("Users_list")}
                </Typography>
            </Divider>

            {/* Select an organisation */}
            <Grid container spacing={2} justifyContent={"flex-start"} alignContent={"center"} mt={2}>
                <Grid item xs={10} md={3} lg={4}>
                    {subOrganisationsAutocomplete()}
                </Grid>

                {/* Filter by email */}
                <Grid item xs={10} sm={8} md={3}>
                    <TextField
                        label={t("Filter_by_email")}
                        size={"small"}
                        onChange={filterUsersByEmail}
                        type={"text"}
                        InputProps={{
                            startAdornment: <Search />
                        }}
                    />
                </Grid>

                {/* Max Rows */}
                <Grid item xs={10} md={2}>
                    <FormControl fullWidth>
                        <InputLabel id={"maxRows"}>{t("max_rows")}</InputLabel>
                        <Select
                            fullWidth
                            labelId={"maxRows"}
                            label={t("max_rows")}
                            size={"small"}
                            value={params.maxRows}
                            onChange={(e) => setParams({...params, maxRows: e.target.value})}
                        >
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={20}>20</MenuItem>
                            <MenuItem value={50}>50</MenuItem>
                            <MenuItem value={100}>100</MenuItem>
                            <MenuItem value={200}>200</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

                {/* Filter by role */}
                <Grid item xs={10} sm={8} md={4} lg={3} sx={styles.filtersButtonJustification}>
                    <Button
                        small spacing dense
                        text={!params.admin}
                        secondary={params.admin}
                        onClick={() => setParams({...params, page: 1, admin: !params.admin, members: false})}
                        disabled={!initOrg}
                    >
                        {t("Admin_short")}
                    </Button>
                    <Button
                        small spacing dense
                        text={!params.members}
                        secondary={params.members}
                        onClick={() => setParams({...params, page: 1, members: !params.members, admin: false})}
                        disabled={!initOrg}
                    >
                        {t("Member")}
                    </Button>

                </Grid>
            </Grid>
        </>
    )
}