import React, {useState} from "react";
// MUI
import {Paper, Typography, TextField, Grid} from "@mui/material";
// API
import {APIPost} from "@api";
// Unitag UI
import {Button} from "@components";
// Styles
import {styles} from "../styles";
// Interfaces
import {GetOrganisationResponse} from "@interfaces";

const {REACT_APP_API_URL} = process.env;

export default function CreateOrganisationPanel(props: any) {

    const {handleError, t} = props

    const [organisationName, setOrganisationName] = useState<string>("");
    // Loading state
    const [loading, setLoading] = useState<boolean>(false);

    const createOrganisation = () => {
        setLoading(true);
        if (organisationName !== "") {
            APIPost<GetOrganisationResponse>(REACT_APP_API_URL + "/org", {
                name: organisationName,
                can_user_consume_credits: true,
                can_user_create_org: true,
            }).then((data) => {
                if (data.parsedBody) {
                    window.location.reload();
                }
            }).catch(() => {
                setLoading(false);
                handleError(t("Snackbar_create_organisation_error"))
            });
            return;
        }
        setLoading(false);
        handleError(t("Snackbar_create_organisation_no_name"))
        return;
    }

    return (
        <>
            <Paper variant={"outlined"} sx={styles.fullWidthPaper}>
                <Grid container justifyContent={"space-between"} rowSpacing={2}>
                    <Grid item xs={12}>
                        <Typography>
                            {t("Create_organisation_text")}
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            variant={"outlined"}
                            value={organisationName}
                            label={t("Organisation_name")}
                            size={"small"}
                            disabled={loading}
                            onChange={(e) => setOrganisationName(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={3} sx={styles.alignRight}>
                        <Button
                            primary
                            dense
                            small
                            disabled={loading}
                            onClick={() => createOrganisation()}
                        >
                            {t("common:Create")}
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </>
    )
}