import React from 'react';
import { Chip } from '@mui/material';

export default function ColorChip(props: any) {

    const colorChip = () => {

        if (props.value === undefined) {
            return null;
        }

        if (props.color === '' && props.value === '') {
            return null;
        }

        let backgroundColor: string;
        let color: string;

        switch (props.color) {
            case 'green':
                backgroundColor = '#c8e6c9';
                color = '#256029';
                break;
            case 'purple':
                backgroundColor = '#eccfff';
                color = '#694382';
                break;
            case '#673ab7':
                backgroundColor = '#eccfff';
                color = '#694382';
                break;
            case 'red':
                backgroundColor = '#ffcdd2';
                color = '#c63737';
                break;
            case 'orange':
                backgroundColor = '#ffd8b2';
                color = '#805b36';
                break;
            case 'blue':
                backgroundColor = '#b3e5fc';
                color = '#23547b';
                break;
            case '#2196f3':
                backgroundColor = '#b3e5fc';
                color = '#23547b';
                break;
            case 'yellow':
                backgroundColor = '#feedaf';
                color = '#8a5340';
                break;
            case '#f44336':
                backgroundColor = '#ffcdd2';
                color = '#c63737';
                break;
            default:
                backgroundColor = '#cdcccc';
                color = '#5e5e5e';
                break;
        }

        if(props.removable) {
            return(
                <Chip
                    variant={props.outlined ? "outlined" : "filled"}
                    clickable={!props.unclickable}
                    label={props.value}
                    icon={props.icon}
                    onDelete={props.onRemove}
                    size={props.small ? "small" : "medium"}
                    style={{
                        backgroundColor: props.outlined ? "transparent" : backgroundColor,
                        color: color,
                        width: props.fullWidth ? '100%' : '',
                        textTransform: 'uppercase',
                        fontWeight: 700,
                        letterSpacing: '.3px',
                        fontSize: '12px'
                    }}
                />
            );
        }

        return (
            <Chip
                variant={props.outlined ? "outlined" : "filled"}
                clickable={!props.unclickable}
                label={props.value}
                icon={props.icon}
                size={props.small ? "small" : "medium"}
                style={{
                    backgroundColor: props.outlined ? "transparent" : backgroundColor,
                    color: color,
                    width: props.fullWidth ? '100%' : '',
                    textTransform: 'uppercase',
                    fontWeight: 700,
                    letterSpacing: '.3px',
                    fontSize: '12px',
                    marginTop: props.spacingTop ? 8 : 0,
                    marginBottom: props.spacingBottom ? 8 : 0
                }}
                onClick={props?.clickable}
            />
        );
    }

    return (
        <React.Fragment>
            {colorChip()}
        </React.Fragment>
    );
}
