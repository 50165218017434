import React from "react"
import {Link} from "react-router-dom";
// Translation
import {useTranslation, Trans} from "react-i18next";
// UI
import {Box, Container, Grid, Paper, Typography} from "@mui/material";
import {Button} from "@components";
// assets
import DualFrame from "@assets/img/landingPages/winelabel/dual-frame.png"
import InsideQRCode from "@assets/img/landingPages/winelabel/inside-qrcode.png"
import Mockups from "@assets/img/landingPages/winelabel/mockups.jpg"
import QRCode from "@assets/img/landingPages/winelabel/qrcode.png"

export default function WineLabelLanding() {

    const {t} = useTranslation(["winelabel", "common"])

    const styles = {
        images: {
            maxWidth: "100%", height: "auto"
        },
        papers: {
            minHeight: 162,
            p: 4
        },
        banner: {
            display: 'flex',
            justifyContent: 'center',
            padding: 5,
            backgroundColor: '#F5F5F5'
        },
        bannerBalance: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"
        }
    }

    const displayCTABanner = () => {
        return (
            <>
                <Container maxWidth={false} sx={styles.banner}>
                    <Grid container justifyContent={"center"}>
                        <Grid item xs={8} md={7} sx={styles.bannerBalance}>
                            <Typography variant={"h5"} fontWeight={700}>
                                {t("get_started")}
                            </Typography>
                        </Grid>
                        <Grid item xs={8} md={3} sx={styles.bannerBalance}>
                            <Button primary large component={Link} to={"/winelabel-buy-credits"}>
                                {t("buy_credits")}
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </>
        )
    }

    return (
        <>
            <Box sx={{ mb: 10, pt: 5, bgcolor: "white" }}>

                {/*  ABOUT  */}
                <Container maxWidth="lg" sx={{ my: 20 }}>
                    <Grid container spacing={3} justifyContent={{ xs: "center", lg: "space-between" }}>
                        <Grid item md={7}>
                            <Typography component={"h2"}>
                                {t("about")}
                            </Typography>
                            <Typography
                                variant={"h1"}
                                component={"h3"}
                                sx={{ fontSize: "35px!important", lineHeight: "45px!important"}}
                            >
                                {t("winelabel_solution_title")}
                            </Typography>
                            <Typography>
                                {t("winelabel_solution_text")}
                            </Typography>

                            <br/>
                            <br/>

                            <Grid container justifyContent={"center"} spacing={3}>
                                <Grid item xs={10} sm={6}>
                                    <Paper elevation={4} sx={styles.papers}>
                                        <Typography variant={"h6"} color={"primary"} fontWeight={700} component={"p"} align={"center"}>
                                            {t("winelabel_solution_qrcode")}
                                        </Typography>
                                        <Typography variant={"body1"} component={"p"} align={"center"}>
                                            {t("winelabel_solution_qrcode_text")}
                                        </Typography>
                                    </Paper>
                                </Grid>
                                <Grid item xs={10} sm={6}>
                                    <Paper elevation={4} sx={styles.papers}>
                                        <Typography variant={"h6"} color={"primary"} fontWeight={700} component={"p"} align={"center"}>
                                            {t("winelabel_solution_application")}
                                        </Typography>
                                        <Typography variant={"body1"} component={"p"} align={"center"}>
                                            {t("winelabel_solution_application_text")}
                                        </Typography>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={5} sx={{ display: { xs: "none", md: "initial" } }}>
                            <img
                                style={styles.images}
                                src={InsideQRCode}
                                width={1191} height={1264}
                                alt={"wine label"}
                            />
                        </Grid>
                    </Grid>
                </Container>

                {displayCTABanner()}

                {/*  DEMO  */}
                <Container maxWidth="lg" sx={{ my: 20 }}>
                    <Grid container spacing={3} justifyContent={"center"}>
                        <Grid item lg={9}>
                            <Typography
                                variant={"h1"}
                                component={"h3"}
                                align={"center"}
                            >
                                {t("winelabel_demo_title")}
                            </Typography>
                            <Typography align={"center"}>
                                <b>{t("winelabel_demo_text")}</b>
                            </Typography>
                            <Typography align={"center"}>
                                {t("winelabel_demo_text_2")}
                            </Typography>
                        </Grid>

                        <Grid item lg={6}>
                            <img
                                style={styles.images}
                                src={DualFrame}
                                width={536} height={327}
                                alt={"wine label"}
                            />
                        </Grid>
                        <Grid item lg={6} sx={{ textAlign: { xs: "center", md: "right" }, display: { xs: "none", md: "initial"} }}>
                            <img
                                style={styles.images}
                                src={QRCode}
                                width={327} height={327}
                                alt={"wine label"}
                            />
                        </Grid>
                    </Grid>
                </Container>

                {displayCTABanner()}

                {/*  STRONG POINTS  */}
                <Container maxWidth="lg" sx={{ my: 20 }}>
                    <Grid container spacing={3} justifyContent={{ xs: "center", lg: "space-between" }}>
                        <Grid item md={6}>
                            <Typography component={"h2"}>
                                {t("winelabel_strong")}
                            </Typography>
                            <Typography
                                variant={"h1"}
                                component={"h3"}
                            >
                                {t("winelabel_strong_title")}
                            </Typography>
                            <Typography>
                                {t("winelabel_strong_text")}
                            </Typography>

                            <br/>
                            <br/>

                            <Grid container justifyContent={"center"} spacing={2}>
                                <Grid item xs={"auto"} sm={2}>
                                    <Typography variant={"h4"} component={"p"} fontWeight={700}>
                                        01
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={9} sx={{ textAlign: { xs: "center", sm: "left" } }}>
                                    <Typography variant={"h5"} component={"p"} fontWeight={900}>
                                        {t("winelabel_strong_list_01_title")}
                                    </Typography>
                                    <Typography variant={"body1"} component={"p"}>
                                        {t("winelabel_strong_list_01_text")}
                                    </Typography>
                                </Grid>

                                <Grid item xs={"auto"} sm={2}>
                                    <Typography variant={"h4"} component={"p"} fontWeight={700}>
                                        02
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={9} sx={{ textAlign: { xs: "center", sm: "left" } }}>
                                    <Typography variant={"h5"} component={"p"} fontWeight={900}>
                                        {t("winelabel_strong_list_02_title")}
                                    </Typography>
                                    <Typography variant={"body1"} component={"p"}>
                                        {t("winelabel_strong_list_02_text")}
                                    </Typography>
                                </Grid>

                                <Grid item xs={"auto"} sm={2}>
                                    <Typography variant={"h4"} component={"p"} fontWeight={700}>
                                        03
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={9} sx={{ textAlign: { xs: "center", sm: "left" } }}>
                                    <Typography variant={"h5"} component={"p"} fontWeight={900}>
                                        {t("winelabel_strong_list_03_title")}
                                    </Typography>
                                    <Typography variant={"body1"} component={"p"}>
                                        <Trans t={t} i18nKey={"winelabel_strong_list_03_text"}>
                                            e<b>e</b>
                                        </Trans>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={6} sx={{ display: { xs: "none", md: "initial" } }}>
                            <img
                                style={styles.images}
                                src={Mockups}
                                width={688} height={507}
                                alt={"wine label"}
                            />
                        </Grid>
                    </Grid>
                </Container>

                {displayCTABanner()}

            </Box>
        </>
    )
}
