import React, {useCallback, useMemo} from 'react';
import {useDropzone} from 'react-dropzone';
import {useTranslation} from "react-i18next";



export default function PaperDropZone(props: any) {
    const {t} = useTranslation(['common']);
    const [accept, setAccept] = React.useState<string>('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    const [msg, setMsg] = React.useState<string>(t("upload_template"));

    const baseStyle = {
        flex: 1,
        display: 'flex',
        // flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: '#eeeeee',
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        color: '#bdbdbd',
        outline: 'none',
        transition: 'border .24s ease-in-out',
        cursor: props.disabled ? "default" : "pointer"
    };

    const activeStyle = {
        borderColor: '#2196f3'
    };

    const acceptStyle = {
        borderColor: '#00e676'
    };

    const rejectStyle = {
        borderColor: '#ff1744'
    };

    const onDrop = useCallback((acceptedFiles, fileRejections) => {
        if (acceptedFiles.length > 0 && props.onDrop) {
            props.onDrop(acceptedFiles);
        } else if (fileRejections.length > 0 && props.onDropReject) {
            props.onDropReject(fileRejections);
        }
    }, [props]);

    React.useEffect(() => {
        if (props?.accept !== undefined) {
            setAccept(props.accept);
        }
        if (props?.msg !== undefined) {
            setMsg(props.msg);
        }
    }, [props]);

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
    } = useDropzone({onDrop, accept: accept});

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [
        isDragActive,
        isDragReject,
        isDragAccept
    ]);

    return (
        <div className='container'>
            <div {...getRootProps({style})}>
                <input {...getInputProps()} disabled={props.disabled} />
                <p style={{textAlign: 'center'}}>{msg}</p>
            </div>
        </div>
    );
}
