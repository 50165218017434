import React from "react"
// MUI
import {Chip, Grid, IconButton, ListItem, ListItemText, Skeleton, Tooltip, Typography} from "@mui/material";
import {
    AdminPanelSettings,
    CancelScheduleSend,
    ChevronLeft,
    ChevronRight,
    Delete,
    Done,
    DoneAll,
    Person
} from "@mui/icons-material";
// Primereact
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
// Styles
import {styles} from "@/Views/Account/components/OrganisationsAndUsers/styles";

export default function OrgUsersDataTable(props: any) {

    const {
        orgMembers,
        loadingUsers,
        user,
        params, setParams,
        handleOpenRoleDialog,
        handleOpenDeleteUserDialog,
        handleCancelInvitation,
        dt, t
    } = props

    const paginatorTemplate = () => {

        const pageDown = () => {
            setParams({...params, page: params.page <= 1 ? 1 : params.page-1})
        }

        const pageUp = () => {
            setParams({...params, page: params.page + 1})
        }

        return (
            <>
                <Grid container justifyContent={"center"} sx={{ my: 1 }} spacing={ 3 }>
                    <Grid item>
                        <IconButton onClick={() => pageDown()} disabled={params.page === 1}>
                            <ChevronLeft />
                        </IconButton>
                    </Grid>
                    <Grid item>
                        <Typography variant={"body1"} component={"p"} fontWeight={600} sx={{ mt: 1 }}>
                            {params.page}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <IconButton onClick={() => pageUp()}>
                            <ChevronRight />
                        </IconButton>
                    </Grid>
                </Grid>
            </>
        )
    }

    const emailBodyTemplate = (rowData: any) => {
        return (
            <ListItem dense disablePadding>
                <ListItemText
                    primary={rowData.email_user}
                    primaryTypographyProps={{
                        variant: "subtitle2"
                    }}
                    secondary={t(rowData.user_role.toLowerCase())}
                    secondaryTypographyProps={{
                        sx: styles.userRole
                    }}
                />
            </ListItem>
        )
    }

    const statusBodyTemplate = (rowData: any) => {
        if (rowData.accepted && (rowData.email_user === user.email)) {
            return <Chip size="small" icon={<Person/>} label={t("common:Your_account")} color="primary" />;
        }

        if (rowData.accepted) {
            return <Chip size="small" icon={<DoneAll/>} label={t("Confirmed")} color="secondary" />;
        }
        // one week invitation before expiration
        return <Chip size="small" icon={<Done/>} label={rowData.expired ? t("Invitation_expired") : t("Invitation_sent")} />;
    }

    const actionsBodyTemplate = (rowData: any) => {
        return (
            <>
                {
                    rowData.accepted &&
                    (
                        <>
                            <IconButton
                                aria-label={"change role"}
                                onClick={() => handleOpenRoleDialog(rowData)}
                                disabled={rowData.invite_uuid === '' || rowData.email_user === user.email}
                            >
                                <Tooltip placement={"top"} arrow title={`${t("Change_role")}`}>
                                    <AdminPanelSettings />
                                </Tooltip>
                            </IconButton>

                            <IconButton
                                aria-label="delete"
                                value={rowData.invite_uuid}
                                onClick={() => handleOpenDeleteUserDialog(rowData)}
                                disabled={rowData.invite_uuid === '' || rowData.email_user === user.email}
                            >
                                <Tooltip placement={"top"} arrow title={`${t('Remove_user')}`}>
                                    <Delete />
                                </Tooltip>
                            </IconButton>
                        </>
                    )
                }

                {
                    !rowData.accepted &&
                    <IconButton
                        aria-label="cancel-invitation"
                        value={rowData.invitation_id}
                        onClick={() => handleCancelInvitation(rowData.invitation_id)}
                        disabled={rowData.Accepted}>
                        <Tooltip placement={"top"} arrow title={`${t('Cancel_invite')}`}>
                            <CancelScheduleSend/>
                        </Tooltip>
                    </IconButton>
                }
            </>
        );
    }


    if (loadingUsers) return <Skeleton variant={"rectangular"} height={100} />

    return (
        <>
            <ListItem dense disablePadding>
                {orgMembers === null ?
                    <Typography>
                        {t("Orga_members_no_members")}
                    </Typography>
                    :
                    <DataTable
                        ref={dt}
                        rowHover
                        dataKey="email_user"
                        value={orgMembers}
                        className="p-datatable-sm"
                        style={styles.dataTable}
                        emptyMessage={t("No_records_found")}
                    >
                        <Column
                            field="email_user"
                            header={t("Email")}
                            body={emailBodyTemplate}
                        />
                        <Column
                            field="accepted"
                            header={t("Status")}
                            body={statusBodyTemplate}
                        />
                        <Column
                            header={t("Actions")}
                            body={actionsBodyTemplate}
                        />
                    </DataTable>
                }
            </ListItem>
            {paginatorTemplate()}
        </>
    )
}