import React from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton, Box} from "@mui/material";
// TRANSLATIOn
import {useTranslation} from 'react-i18next';
import {Close} from "@mui/icons-material";

// DOCUMENTATION
// open: boolean: state managing the dialog
// onClose: function: to trigger when Close buttons are hit
// title: string: title of the modal
// text: string: text of the modal
// color: string: Type of color. Choose between primary, secondary and error (default button colors).
// variant: string: Chose between contained, text and outlined
// primaryAction: function: when the primary action is hit (will display button)
// primaryActionText: string: text to display in the button.
// primaryIcon: node: icon to display as start adornment for Primary Action if enabled.
// secondaryAction: function: when the secondary action is hit (will display button)
// secondaryActionText: string: text to display in the button.
// secondaryIcon: node: icon to display as start adornment for Secondary Action if enabled.
// loading: boolean: loading state


export default function GenericActionDialog(props: any) {

    const {t} = useTranslation(['settings', 'common'])
    const {onClose, open, title, text, primaryAction, secondaryAction, primaryActionText, secondaryActionText, loading} = props;

    const color = props?.color ? props.color : "primary";
    const variant = props?.variant ? props.variant : "contained";
    const primaryIcon = props?.primaryIcon ? props.primaryIcon : null;
    const secondaryIcon = props?.secondaryIcon ? props.secondaryIcon : null;

    const padding = { p: 3 };

    return (
        <>
            <Dialog
                onClose={onClose}
                open={open}
                maxWidth={props.size ? props.size : "md"}
                fullWidth={!!props.size}
            >
                <DialogTitle sx={padding}>
                    <Box display="flex" alignItems="center">
                        <Box flexGrow={1}>
                            {title}
                        </Box>
                        <IconButton onClick={onClose}>
                            <Close />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent sx={padding}>
                    {text}
                </DialogContent>
                <DialogActions sx={padding}>
                    <Button color={"inherit"} onClick={onClose} disabled={loading}>
                        {t("common:Back")}
                    </Button>
                    { (primaryAction && primaryActionText) &&
                        <Button color={color} startIcon={primaryIcon} onClick={primaryAction} variant={variant} disableElevation disabled={loading}>
                            {primaryActionText}
                        </Button>
                    }
                    { (secondaryAction && secondaryActionText) &&
                        <Button color={color} startIcon={secondaryIcon} onClick={secondaryAction} variant={variant} disableElevation disabled={loading}>
                            {secondaryActionText}
                        </Button>
                    }
                </DialogActions>
            </Dialog>
        </>
    )
}