import React from "react";
import {Rnd} from "react-rnd";
import {OpenInFull} from "@mui/icons-material";

const DraggableLogo = (props: any) => {

    const {
        onDragStart,
        catchPosition,
        handleResize,
        previewLogo,
        logo
    } = props;

    return (
        <React.Fragment>
            <Rnd
                bounds="parent"
                onDragStart={onDragStart}
                onDragStop={catchPosition}
                position={{ x: logo.x, y: logo.y }}
                lockAspectRatio
                onResizeStop={handleResize}
                size={{width: logo.width, height: 'auto'}}
                enableResizing={{
                    top: false,
                    right: false,
                    bottom:false,
                    left:false,
                    topRight:false,
                    bottomRight:true, // here
                    bottomLeft:false,
                    topLeft:false
                }}
                resizeHandleComponent={{ bottomRight: <OpenInFull sx={{ transform: "scaleX(-1)" }} /> }}
                minWidth={'25px'}
            >
                <img
                    draggable={false}
                    alt="preview-logoSrc"
                    src={previewLogo}
                    style={{
                        width: "100%",
                        height: "auto",
                        border: "1px solid black"
                    }}
                />
            </Rnd>
        </React.Fragment>
    );
}

export default DraggableLogo
