import React, {useEffect, useState} from 'react';
// MUI
import {FormControl, Grid, TextField, Typography, Container, Divider, Box} from '@mui/material';
// Unitag UI
import {Button} from '@components';
// TRANSLATION
import {useTranslation} from 'react-i18next';
// AUTH
import {Auth} from 'aws-amplify';
// API
import {APIGet, APIPut} from '@api';

const {REACT_APP_API_URL} = process.env;

export default function SettingsProfileTab(props: any) {

    const {t} = useTranslation(['settings', 'common']);
    const {handleError, handleSuccess} = props

    const styles = {
        paper: {padding: '16px'},
        textField: {
            marginLeft: '8px',
            marginRight: '8px',
            my: 0,
            width: '300px',
        },
        form: {
            my: 1
        },
        titleBlock: { py: 1 }
    };

    const [name, setName] = useState<string>('');
    const [surname, setSurname] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [financialEmail, setFinancialEmail] = useState<string>('');
    const [financialEmailBase, setFinancialEmailBase] = useState<string>('');
    const [company, setCompany] = useState<string>('');
    const [position, setPosition] = useState<string>('');
    // Payment error detection
    const [paymentMethodError, setPaymentMethodError] = useState<boolean>(false);

    useEffect(() => {
        Auth.currentAuthenticatedUser().then(user => {

            if (user.attributes.name !== undefined) setName(user.attributes.name);
            if (user.attributes.family_name !== undefined) setSurname(user.attributes.family_name);
            if (user.attributes.email !== undefined) setEmail(user.attributes.email);
            if (user.attributes['custom:company'] !== undefined) setCompany(user.attributes['custom:company']);
            if (user.attributes['custom:company_position'] !== undefined) setPosition(user.attributes['custom:company_position']);

            // not catched for it is quite buggy
            APIGet(REACT_APP_API_URL + "/user/billing")
            .then((data: any) => {
                setFinancialEmail(data.parsedBody.email);
                setFinancialEmailBase(data.parsedBody.email);
            })
        }).catch(() => handleError(t("error_get_identification")));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onSubmit = () => {
        setPaymentMethodError(false);

        Auth.currentAuthenticatedUser().then(user => {
            let params: any = {};

            if (user.attributes.name !== name && name !== "") params['name'] = name
            if (user.attributes.family_name !== surname && surname !== "") params['family_name'] = surname
            if (user.attributes.email !== email && email !== "") params['email'] = email;
            if (user.attributes['custom:company'] !== company && company !== "") params['custom:company'] = company
            if (user.attributes['custom:company_position'] !== position && position !== "") params['custom:company_position'] = position

            Auth.updateUserAttributes(user, params).then((res) => {

                setName(name);
                setSurname(surname);
                setCompany(company);
                setPosition(position);

                if (financialEmailBase !== financialEmail && financialEmail !== '') {
                    APIPut(REACT_APP_API_URL + "/user/billing", {billing_email: financialEmail})
                        .then((dataAPI: any) => {
                            if (dataAPI.parsedBody === "No payment method found") setPaymentMethodError(true);
                            else handleSuccess(t("Profile_update_success"))
                        })
                        .catch((err: any) => {
                            if (err === "No payment method found") setPaymentMethodError(true);
                            else handleError(t('Profile_update_error'))
                        })
                } else {
                    if (res === "SUCCESS") handleSuccess(t("Profile_update_success"))
                    else handleError(t('Profile_update_error'))
                }

            })
            .catch(err => {
                // Hacky check, the lambda function doesn't let us "not send an email", so we throw an error and handle it here
                // What in the diggity dog
                if (err.code=== 'UserLambdaValidationException' && err.message === 'CustomMessage failed with error success.') {
                    handleSuccess(t("Profile_update_success"))
                    return;
                }
                handleError(t('Profile_update_error'))
            });
        });
    }

    return (
        <main>
            <Container maxWidth='xl'>
                <Grid container justifyContent='flex-start'>

                    <Grid item xs={12} sx={styles.titleBlock}>
                        <Typography variant='h5' gutterBottom>{t('Your_profile')}</Typography>
                        <Divider/>
                    </Grid>

                    <Grid item xs={12} sm={8} md={6}>
                        <FormControl fullWidth sx={styles.form} variant='outlined'>
                            <TextField
                                label={t('Name')}
                                value={name}
                                sx={styles.textField}
                                helperText={t('Name_surname_label')}
                                variant='outlined'
                                size={"small"}
                                type='text'
                                onChange={(e) => setName(e.target.value)}
                            />
                        </FormControl>

                        <FormControl fullWidth sx={styles.form} variant='outlined'>
                            <TextField
                                label={t('Surname')}
                                value={surname}
                                sx={styles.textField}
                                helperText={t('Name_surname_label')}
                                variant='outlined'
                                size={"small"}
                                type='text'
                                onChange={(e) => setSurname(e.target.value)}
                            />
                        </FormControl>

                        <FormControl fullWidth sx={styles.form} variant='outlined'>
                            <TextField
                                label={t('Company')}
                                value={company}
                                sx={styles.textField}
                                helperText={t('Company_label')}
                                variant='outlined'
                                size={"small"}
                                type='text'
                                onChange={(e) => setCompany(e.target.value)}
                            />
                        </FormControl>

                        <FormControl fullWidth sx={styles.form} variant='outlined'>
                            <TextField
                                label={t('Position')}
                                value={position}
                                sx={styles.textField}
                                helperText={t('Position_label')}
                                variant='outlined'
                                size={"small"}
                                type='text'
                                onChange={(e) => setPosition(e.target.value)}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={8} md={6}>
                        <FormControl fullWidth sx={styles.form} variant='outlined'>
                            <TextField
                                label={t('User_email')}
                                helperText={t('User_email_help')}
                                sx={styles.textField}
                                value={email}
                                variant='outlined'
                                size={"small"}
                                type='email'
                                disabled
                            />
                        </FormControl>

                        <FormControl fullWidth variant='outlined'>
                            <TextField
                                label={t('Financials_email')}
                                value={financialEmail}
                                sx={styles.textField}
                                helperText={t('Financials_email')}
                                margin='dense'
                                variant='outlined'
                                type='email'
                                size={"small"}
                                onChange={(e) => setFinancialEmail(e.target.value)}
                            />

                            {   paymentMethodError &&
                                <Box component="span" sx={{ p: 2, border: '1px dashed red' }}>
                                    <Typography color="error">{t('Missing_Payment_Method')}</Typography>
                                </Box>
                            }
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <Button primary onClick={onSubmit}>
                            {t('Profile_update')}
                        </Button>
                    </Grid>

                </Grid>
            </Container>
        </main>
    );
}
