import React from "react"
// MUI
import {Divider, Grid, Typography} from "@mui/material";
import {QrCode2Outlined} from "@mui/icons-material";
// Components
import ServiceBillingProgress from "@/Views/Account/components/BillingAndPlans/perService/ServiceBillingList/ServiceBillingProgress";
// Interfaces
import {SubscriptionItem} from "@interfaces";

export default function ServiceBillingList(props: any) {

    const {creditsQRCodes, currency, subscriptionInterval, subscriptionType, services, styles, t} = props

    return (
        <>
            <Grid item xs={12}>
                <Divider textAlign={'left'}>
                    <Typography variant={"h6"} component={"p"}>
                        {t('Credits_information')}
                    </Typography>
                </Divider>
                <Typography variant="body2" style={styles.subtitle}>{t("Credits_intro")}</Typography>
            </Grid>

            <ServiceBillingProgress
                service={{
                    type: 'qrcodes',
                    price: 0,
                    price_per_unit: 0,
                    credit: {...creditsQRCodes},
                    icon: <QrCode2Outlined style={styles.iconList}/>
                }}
                currency={currency}
                interval={subscriptionInterval}
                unlimited={subscriptionType === "platinum"}
            />

            {!!services && services.map((service: SubscriptionItem, index: any) => {
                return (
                    <div key={index} style={styles.serviceWrapper}>
                        <ServiceBillingProgress
                            service={service}
                            currency={currency}
                            interval={subscriptionInterval}
                        />
                    </div>
                )
            })}
        </>
    )
}