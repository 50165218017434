import React, {useEffect, useState} from "react"
import {Alert, Collapse, Divider, Grid, Link, Paper, Slider, TextField, Typography} from "@mui/material";
import {roundToCent} from "@functions";
import {KeyboardArrowDown, KeyboardArrowUp} from "@mui/icons-material";


export default function AlacarteModule(props: any) {

    const {module, currency, yearly, t} = props
    const styles = {
        modulePaperWrapper: {
            backgroundColor: "transparent",
            p: 2,
            mb: 5
        },
        sliderPaper: {
            display: "flex",
            flexDirection: "row",
        },
        sliderWrapper: {
            p: 4,
            minWidth: "459px"
        },
        verticalDivider: {
            mx: 2
        },
        sliderInputWrapper: {
            width: "100%",
            py: 4,
            pr: 4,
            pl: 2
        },
        billingPaper: {
            backgroundColor: "rgba(255,255,255,0.75)"
        },

        maxCreditsAlert: {
            mb: 3
        },
        netTotalWrapper: {
            p: 2,
            textAlign: { xs: "center", md: "right" }
        },
        sliderGridItem: {
            p: 3
        },
        gridDiscountItem: {
            borderRight: "1px solid",
            borderColor: { xs: "transparent", md: "divider" },
            display: (!!module.discounts && module.discounts.length > 0) ? "block" : "none"
        }
    }

    // features collapse
    const [openFeatures, setOpenFeatures] = useState<boolean>(false)
    // prices
    const [rawTotal, setRawTotal] = useState<number>(0)
    const [netTotal, setNetTotal] = useState<number>(0)
    // discounts
    const [discountValue, setDiscountValue] = useState<number>(0)
    const [discountTicks, setDiscountTicks] = useState<any[] | undefined>([])

    // calculate discount markers on init
    useEffect(() => {
        if (module.discounts) returnDiscountTicks()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [module.discounts])

    useEffect(() => {
        // cleaner
        if (module.value && module.value > module.maxCredits) module.setValue(module.maxCredits)
        else if (!module.value) module.setValue(0)

        calculateRawTotal()
        calculateDiscountValue()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [module.value])

    // calculate net total when price data are changed
    useEffect(() => {
        calculateNetTotal()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rawTotal, yearly])

    // Calculation
    const calculateRawTotal = () => { setRawTotal(roundToCent(module.value * module.basePrice)) }

    const calculateNetTotal = () => {
        let value: number

        if (discountValue !== 0) {
            value = roundToCent(module.value * (module.basePrice * discountValue))
            module.setPrice(value)
            if (yearly) setNetTotal(roundToCent(value * 12 * (!module.disableYearlyDiscount ? 0.9 : 1)))
            else setNetTotal(value)
        }
        else {
            value = roundToCent(module.value * module.basePrice)
            module.setPrice(value)
            if (yearly) setNetTotal(roundToCent(value * 12 * (!module.disableYearlyDiscount ? 0.9 : 1)))
            else setNetTotal(value)
        }
    }

    // discount
    const calculateDiscountValue = () => {
        if (!module.discounts || module.discounts.length < 1) setDiscountValue(0)
        else module.discounts.forEach((discount: any) => {
            if (module.discounts[0].tier > module.value) setDiscountValue(0)
            if (discount.tier <= module.value) setDiscountValue(discount.value)
        })
    }

    const returnDiscountValue = () => {
        if (discountValue && discountValue !== 0) return roundToCent(100 - (discountValue * 100))
        return 0
    }

    const returnDiscountTicks = () => {
        let ticks: any = []
        if (!!module.discounts && module.discounts.length > 0) {
            module.discounts.forEach((discount: any) => {
                if (discount.tier <= 0 ||  discount.tier >= module.maxCredits) return
                ticks.push({ label: `${discount.tier}`, value: discount.tier })
            })
            setDiscountTicks(ticks)
        } else return
    }

    // Render parts
    const renderOwnedItems = () => {
        if (module.owned < 1) return <></>
        return (
            <Grid item xs={12}>
                <Collapse in={module.owned >= 1}>
                    <Typography variant={"body1"} fontWeight={700} color={"secondary"} align={"left"}>
                        {t("you_have_")} {module.owned} {t("items")}
                    </Typography>
                </Collapse>
            </Grid>
        )
    }

    const renderFeaturesList = () => {

        if (!module.features) return <></>

        const handleOpenFeatures = (e: any) => {
            e.preventDefault()
            setOpenFeatures(!openFeatures)
        }

        return (
            <Grid item xs={12}>
                <Link
                    onClick={(e) => handleOpenFeatures(e)}
                    color={"secondary"}
                    sx={{ cursor: "pointer" }}
                >
                    {t("features_included")}&nbsp;
                    {openFeatures ?
                        <><KeyboardArrowUp fontSize={"inherit"} /></> :
                        <><KeyboardArrowDown fontSize={"inherit"} /></>
                    }
                </Link>

                <Collapse in={openFeatures}>
                    <ul style={{ marginTop: 16 }}>
                        {module.features.map((feat: any, index: number) => (
                            <li style={{ margin: '8px 0 8px 8px'}} key={index}>
                                <Typography variant={"body2"}>
                                    <i>{t(feat)}</i>
                                </Typography>
                            </li>
                        ))}
                    </ul>
                </Collapse>
            </Grid>
        )
    }

    const renderSliderPaper = () => {
        return (
            <Grid item xs={12}>

                <Typography variant={"body2"} fontStyle={"italic"} fontSize={12}>
                    {t("slider_helper")}
                </Typography>

                <Paper sx={styles.sliderPaper}>
                    <Grid container justifyContent={"center"}>
                        <Grid item xs={12} md={7} sx={styles.sliderGridItem}>
                            <Slider
                                disabled={module.owned >= module.maxCredits}
                                value={module.value}
                                onChange={(e, val) => module.setValue(val as number)}
                                min={0}
                                max={module.maxCredits}
                                marks={discountTicks}
                                valueLabelDisplay={"auto"}
                                valueLabelFormat={() => {
                                    return `${(discountValue !== 0 ? (module.basePrice * discountValue).toFixed(2) : module.basePrice)} ${currency}`
                                }}
                            />
                        </Grid>
                        <Grid item xs={1} sx={{ display: { xs: "none", md: "inline-flex" } }}>
                            <Divider orientation={"vertical"} sx={styles.verticalDivider} />
                        </Grid>
                        <Grid xs={8} md={4} item sx={styles.sliderGridItem}>
                            <TextField
                                disabled={module.owned >= module.maxCredits}
                                label={module.total}
                                value={module.value}
                                onChange={(e:any) => module.setValue(e.target.value)}
                                size={"small"}
                                fullWidth
                            />
                        </Grid>
                    </Grid>

                </Paper>
            </Grid>
        )
    }

    const renderPricingPaper = () => {
        return (
            <Grid item xs={12}>

                <Collapse in={Boolean(module.value && (module.value >= module.maxCredits || module.owned >= module.maxCredits))}>
                    <Alert severity={"warning"} sx={styles.maxCreditsAlert}>
                        {t("max_credits_reached")}
                    </Alert>
                </Collapse>

                <Paper sx={styles.billingPaper} elevation={0}>

                    <Grid container justifyContent={"space-between"}>

                        {/*/!* Raw price *!/*/}
                        {/*<Grid item xs={11} md={4} p={2}>*/}
                        {/*    <Typography variant={"subtitle1"} component={"p"}>*/}
                        {/*        {t("total_raw")}*/}
                        {/*    </Typography>*/}
                        {/*    <Typography variant={"h5"} component={"p"} color={"secondary"}>*/}
                        {/*        {rawTotal} {currency}*/}
                        {/*    </Typography>*/}
                        {/*</Grid>*/}

                        {/* Discount Value */}
                        <Grid item xs={11} md={6} textAlign={{ xs: "center", md: "left" }} p={2} sx={styles.gridDiscountItem}>
                            <Typography variant={"subtitle1"} component={"p"}>
                                {t("discount_value")}
                            </Typography>
                            <Typography variant={"h5"} component={"p"} color={"secondary"}>
                                {returnDiscountValue()}%
                            </Typography>
                        </Grid>

                        {/* Net Total */}
                        <Grid item xs={11} md={(!!module.discounts && module.discounts.length > 0) ? 6 : 12} sx={styles.netTotalWrapper}>
                            <Typography variant={"subtitle1"} component={"p"}>
                                &nbsp;{yearly ? t("total_net_yearly") : t("total_net_monthly")}
                            </Typography>
                            <Typography variant={"h5"} component={"p"} color={"secondary"}>
                                {netTotal} {currency}
                            </Typography>
                        </Grid>
                    </Grid>

                </Paper>
            </Grid>
        )
    }

    return (
        <>
            <Paper variant={"outlined"} sx={styles.modulePaperWrapper}>
                <Grid container justifyContent={"center"} rowSpacing={3}>
                    {/* Intro */}
                    <Grid item xs={12}>
                        <Typography variant={"h5"} component={"p"} gutterBottom>
                            {module.icon} {module.name}
                        </Typography>
                        <Typography variant={"body2"}>
                            {module.introduction}
                        </Typography>
                    </Grid>

                    {renderOwnedItems()}
                    {renderFeaturesList()}
                    {renderSliderPaper()}
                    {renderPricingPaper()}
                </Grid>
            </Paper>
        </>
    )
}