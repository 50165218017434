import React from "react"
import {Alert, Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import {Button} from "@components";

export default function ConfigDomainDialog(props: any) {

    const {open, onClose, domainConfig, handleSuccess, handleError, t} = props

    const copyText = (text: string) => {
        navigator.clipboard.writeText(text).then(() => {
            handleSuccess(t("success_copying_text"))
        }).catch(() => handleError(t("error_copying_text")))
    }

    return (
        <>
            <Dialog maxWidth="sm" fullWidth open={open}>
                <DialogTitle>
                    <Box display="flex" alignItems="center">
                        <Box flexGrow={1}>{t("Domain_conf_dial")}</Box>
                        <IconButton onClick={onClose}><CloseIcon/></IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Typography variant="body1" style={{paddingBottom: '8px', fontWeight: 300}}>
                        {t("Domain_conf_dial_text_1")}
                    </Typography>
                    <br/>
                    <Alert
                        icon={false}
                        severity="info"
                        action={
                            <IconButton
                                aria-label="copy"
                                component="span"
                                onClick={() => { copyText(`${domainConfig?.domain_name} ${domainConfig?.dns_entry_type} ${domainConfig?.dns_entry}.`)}}
                            >
                                <FileCopyIcon/>
                            </IconButton>
                        }
                    >
                        {domainConfig?.domain_name} {domainConfig?.dns_entry_type} {domainConfig?.dns_entry}.
                    </Alert>
                    <br/>
                    <Typography variant="body1" style={{paddingBottom: '8px', fontWeight: 300}}>
                        {t("Domain_conf_dial_text_2")}
                    </Typography>
                </DialogContent>
                <DialogActions style={{padding: '24px'}}>
                    <Button text onClick={onClose}>{t("Close")}</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}