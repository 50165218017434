import React, {useState, useEffect} from 'react';
import {useLocation} from "react-router-dom";
// MUI
import {
    Badge,
    IconButton,
    Tooltip,
} from "@mui/material";
import {Notifications} from "@mui/icons-material";
// TRANSLATION
import {Trans, useTranslation} from 'react-i18next';
// styles
import NotificationsMenu from "./notificationsMenu/notificationsMenu";
import { APIGet, APIPut } from '@api';
import {useSnackbar} from "@hooks";

const {REACT_APP_NOTIFIER_URL} = process.env;


export default function NotificationCenter() {

    const {t} = useTranslation(["notifications" ,"common"]);
    const {handleError} = useSnackbar()

    const [notifications, setNotifications] = useState<any[]>([]);
    const [latestNotifications, setLatestNotifications] = useState<any[]>([]);
    const [total, setTotal] = useState<number>(0);

    const [menuAnchor, setMenuAnchor] = React.useState<null | HTMLElement>(null);

    let path = useLocation().pathname

    // Lifecycles

    // Effects
    useEffect(() => {
        fetchNotifications();
    }, []);

    useEffect(() => {
        let latest = notifications.reverse().slice(0,5);
        setLatestNotifications(latest);
        returnNotificationTotal();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notifications]);

    // Refresh notifications on path change
    useEffect(() => {
        fetchNotifications();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [path]);

    // Menu
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setMenuAnchor(event.currentTarget);

        if (!!notifications) {
            for (let i = 0; i < notifications.length; i++) {
                acknowledgeNotifications(notifications[i].uuid)
            }
        }
    };



    const acknowledgeNotifications = (uuid: string) => {
        APIPut<any>(`${REACT_APP_NOTIFIER_URL}/notifications/${uuid}/ack`, null)
        .then()
        .catch(() => {
            handleError(t("error_acknowledging_notifications"))
        });
    }

    const fetchNotifications = () => {
        APIGet<any>(`${REACT_APP_NOTIFIER_URL}/notifications`)
        .then((data: any) => {
            if (data.ok) {
                const information = data.parsedBody;
                setNotifications(information.notifications);
            } else {
                setNotifications([]);
            }
        })
        .catch(() => {
            // This error is not managed due to the stillness of notifications these days
            // reactivate when needed
            // handleError(t("error_get_notifications"))
            setNotifications([]);
        });
    }

    // Return values
    const returnNotificationTotal = () => {
        if (!!notifications) {
            let s: number = 0;
            notifications.forEach((element: any) => {
                // noinspection JSUnresolvedReference
                if (element.recipients[0].acknowledged_at === null) {
                    s = s+1;
                    setTotal(s);
                }
            })
        }
    }

    const returnNotificationAmount = () => {
        if (total < 100 && total !== 0) { return total }
        if (total > 100) { return "99+" }
        return 0;
    }

    const returnTooltipTitle = () => {
        if (total === 0) return t("Notifications_nothing_new");
        return (
            <Trans t={t} i18nKey={"Notifications_tooltip"} total={total}>
                You have <span>{{total}}</span> notifications
            </Trans>
        )
    }

    return (
        <>
            <Tooltip title={returnTooltipTitle()} arrow>
                <IconButton
                    aria-label="notifications"
                    aria-controls="menu-notifications"
                    aria-haspopup="true"
                    color="inherit"
                    onClick={handleClick}
                >
                    <Badge color={"secondary"} badgeContent={returnNotificationAmount()}>
                        <Notifications />
                    </Badge>
                </IconButton>
            </Tooltip>

            <NotificationsMenu
                open={Boolean(menuAnchor)}
                anchorEl={menuAnchor}
                handleClose={() => setMenuAnchor(null)}
                notifications={latestNotifications}
                fetchNotifications={() => fetchNotifications()}
                acknowledgeNotifications={(uuid: string) => acknowledgeNotifications(uuid)}
            />

        </>
    )
}
