import React, {useEffect, useState} from 'react';
// Types
import {CreditsResponse} from "@interfaces";
// API
import {APIGet} from "@api";
// Auth
import {Auth} from "aws-amplify";
// Translation & locale management
import i18next from "i18next";
import {Box, CircularProgress, Container, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {standaloneSubscription} from "@/constants/plans_en";
import {useUser} from "@context";
import {useSnackbar} from "@hooks";

const {REACT_APP_API_URL, REACT_APP_VCARDS_PRO_DOMAIN} = process.env;

export default function VCardProApp() {
    const {t} = useTranslation(['common']);
    const {handleError} = useSnackbar()
    const history = useHistory()
    const {user, getUser, organisationAsMember, getOrganisationAsMember} = useUser()

    const [currentURL, setCurrentURL] = useState('');
    const [iframeHeight, setIframeHeight] = useState<any>(800);

    const [vcardCredit, setVcardCredit] = useState<boolean | undefined>(false);

    let _lng = i18next.language

    useEffect(() => {
        getUser()
        window.addEventListener("message", vcardsMessageHandler, false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // TODO: centralize
    useEffect(() => {
        APIGet<CreditsResponse[]>(REACT_APP_API_URL + "/billing/credits")
            .then((data) => {
                if (data.ok) {
                    if (data.parsedBody !== undefined) {
                        data.parsedBody.forEach((val: CreditsResponse) => {
                            if (val.service_name === 'vcards') {
                                if (val.credits_left === 0 && val.credits_used === 0 && val.credits_bought === 0) {
                                    setVcardCredit(false)
                                    return
                                }
                                setVcardCredit(true)
                            }
                        });
                    }
                } else {
                    throw data.status;
                }
            }).catch(() => handleError(t("error_get_credits")));
        getOrganisationAsMember()
        setIframeUrl()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    useEffect(() => {
        setIframeUrl()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18next.language])

    const vcardsMessageHandler = (event: any) => {
        // we only want message from vcard pro
        if (event.origin !== REACT_APP_VCARDS_PRO_DOMAIN) return

        if (typeof event.data === 'string') {
            if (event.data === "refreshToken") {
                Auth.currentSession().then((sess) => {
                    event.source.postMessage(sess.getIdToken().getJwtToken(), REACT_APP_VCARDS_PRO_DOMAIN)
                }, () => {
                    handleError(t("error_get_identification"))
                }).catch(() => handleError(t("error_get_identification")))
            } else if (event.data.includes("resize")) {
                let size = JSON.parse(event.data)
                // remove intro message
                size.splice(0,1)
                setIframeHeight(size[0])
            } else if (event.data.includes("scrollTop")) {
                window.scrollTo(0, 0)
            }

        }
    }

    const setIframeUrl = () => {
        if (!!user) {
            if (user?.account_type === "standard" || user?.account_type === "gold" || user?.account_type === "platinum" || !!organisationAsMember) {
                Auth.currentSession().then((sess) => {
                    setIframeHeight(window.outerHeight);
                    setCurrentURL(REACT_APP_VCARDS_PRO_DOMAIN + "?lng=" + _lng + "&token=" + sess.getIdToken().getJwtToken());
                }, () => {
                    handleError(t("error_get_identification"))
                }).catch(() => handleError(t("error_get_identification")))
            }
        }
    }

    const renderLoading = () => {
        return (
            <Container maxWidth={"lg"}>
                <Box sx={{margin: "auto", mt: 15, textAlign: "center"}}>
                    <CircularProgress/>
                    <br/>
                    <br/>
                    <Typography variant={"body2"} fontWeight={600}>
                        {t("common:loading")}
                    </Typography>
                </Box>
            </Container>
        )
    }

    if (!(!!user) || vcardCredit === undefined) {
        return renderLoading()
    } else if ((!!user && user.account_type === "free" && (!user.org || user.org === "")) || (!vcardCredit && (!user.org || user.org === ""))) {
        history.push({ pathname: "/alacarte", state: { fromPlan: standaloneSubscription, topUp: true }  })
    } else if (!organisationAsMember) {
        // TODO: gracefully manage this
        return (<>error: no organisation</>)
    } else if (vcardCredit) {
        return (
            <iframe
                style={{width: '100%', border: "none"}}
                referrerPolicy="strict-origin-when-cross-origin"
                src={currentURL}
                title="Unitag VCards Pro"
                height={iframeHeight}
                allow="clipboard-write"
            />
        )
    }

    return renderLoading()
}
