import React, {useEffect, useState} from "react"
import {Link} from "react-router-dom";
// MUI
import {
    Divider,
    Drawer as MUIDrawer,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography,
} from "@mui/material";
// Icons (MUI & Custom)
import {Dashboard, Star, GroupWork, AddCircle, AccountCircle} from "@mui/icons-material"
import DynamicQRIcon from '../../../assets/img/icons/dynamic_qr-blue.png';
import VCardsIcon from '../../../assets/img/icons/vcards-blue.png';
import BatchesIcon from '../../../assets/img/icons/batches-blue.png';
import PackagingIcon from '../../../assets/img/icons/packaging-blue.png';
import AnalyticsIcon from '../../../assets/img/icons/analytics-blue.png';
import CampaignsIcon from '../../../assets/img/icons/campaigns.png';
import WineLabel from '../../../assets/img/icons/wine-bottle.png';
import {ReactComponent as AllAppsIcon} from "../../../assets/svg/apps/apps.svg";
// MUI mixins and custom interfaces
import {styled, Theme, CSSObject} from '@mui/material/styles';
import {APIGet} from "@api";
import {CreditsResponse} from "@interfaces";
import {
    freeSubscription,
    goldSubscription,
    platinumSubscription,
    standaloneSubscription,
    standardSubscription
} from "@/constants/plans_en";

const drawerWidth = 300

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    borderRadius: 0,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    borderRadius: 0,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const Drawer = styled(MUIDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),

        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

const {REACT_APP_API_URL} = process.env;
export default function NavbarDrawer(props: any) {
    const {
        globalUser, complexUser,
        open, setOpen,
        onClose,
        styles, t
    } = props

    const drawerDividersStyles = {
        my: !open ? "20.5px" : "10px",
    }
    const [wineLabel, setWineLabel] = useState<boolean>(false);

    useEffect(() => {
        APIGet<CreditsResponse[]>(REACT_APP_API_URL + "/billing/credits")
            .then((data) => {
                if (data.ok) {
                    if (data.parsedBody !== undefined) {
                        data.parsedBody.forEach((val: CreditsResponse) => {
                            if (val.service_name === 'winelabel' && (val.credits_bought > 0 || val.credits_left > 0)) {
                                setWineLabel(true);
                                return;
                            }
                        });
                    }
                }
            });
    }, []);

    const returnLocationUpgradePlan = (accountType: any) => {
        if (!!accountType) {
            switch(accountType) {
                case freeSubscription: return { fromPlan: standaloneSubscription, topUp: false  };
                case standaloneSubscription: return { fromPlan: standaloneSubscription, topUp: true  };
                case standardSubscription: return { fromPlan: standardSubscription, topUp: true  };
                case goldSubscription: return { fromPlan: goldSubscription, topUp: true  };
                case platinumSubscription: return { fromPlan: platinumSubscription, topUp: true  };
                default: return { fromPlan: standaloneSubscription, topUp: false  };
            }
        }
    }

    return (
        <>
            <Drawer
                variant={"permanent"}
                open={open}
                onClose={() => setOpen(!open)}
                onMouseEnter={() => setOpen(true)}
                onMouseLeave={() => setOpen(false)}
            >
                <DrawerHeader></DrawerHeader>
                <List>
                    <ListItemButton dense component={Link} to="/" onClick={onClose}>
                        <ListItemIcon><Dashboard style={{fill: '#626262'}}/></ListItemIcon>
                        <ListItemText primary={t("Home")}/>
                    </ListItemButton>

                    <ListItemButton dense component={Link} to="/settings?profile" onClick={onClose}>
                        <ListItemIcon><AccountCircle style={{fill: '#626262'}}/></ListItemIcon>
                        <ListItemText primary={t("Account")}/>
                    </ListItemButton>

                    <ListItemButton dense component={Link} to="/settings?organisations" onClick={onClose}>
                        <ListItemIcon><GroupWork style={{fill: '#626262'}}/></ListItemIcon>
                        <ListItemText primary={t("Organisations")}/>
                    </ListItemButton>

                    <Divider sx={drawerDividersStyles} textAlign={"left"}>
                        {open && <Typography variant={"subtitle2"}>{t("Applications")}</Typography>}
                    </Divider>

                    <ListItemButton dense component={Link} to="/applications" onClick={onClose}>
                        <ListItemIcon><AllAppsIcon style={{fill: '#626262'}}/></ListItemIcon>
                        <ListItemText primary={t("All_apps")}/>
                    </ListItemButton>

                    <Divider sx={styles.drawerStaticDivider}/>

                    <ListItemButton dense sx={styles.drawerAppButtons} component={Link} to="/applications/analytics"
                                    onClick={onClose}>
                        <ListItemIcon>
                            <img width={"24px"} height={"auto"} src={AnalyticsIcon} alt={"Analytics"}/>
                        </ListItemIcon>
                        <ListItemText primary="Analytics"/>
                    </ListItemButton>

                    <ListItemButton dense sx={styles.drawerAppButtons} component={Link} to="/applications/atlas"
                                    onClick={onClose}>
                        <ListItemIcon>
                            <img width={"24px"} height={"auto"} src={BatchesIcon} alt={"Atlas"}/>
                        </ListItemIcon>
                        <ListItemText primary="Atlas"/>
                    </ListItemButton>

                    <ListItemButton dense sx={styles.drawerAppButtons} component={Link} to="/applications/campaigns"
                                    onClick={onClose}>
                        <ListItemIcon>
                            <img width={"24px"} height={"auto"} src={CampaignsIcon} alt={"Campaigns"}/>
                        </ListItemIcon>
                        <ListItemText primary="Campaigns"/>
                    </ListItemButton>

                    <ListItemButton dense sx={styles.drawerAppButtons} component={Link} to="/applications/packaging"
                                    onClick={onClose}>
                        <ListItemIcon>
                            <img width={"24px"} height={"auto"} src={PackagingIcon} alt={"Packaging"}/>
                        </ListItemIcon>
                        <ListItemText primary="Packaging"/>
                    </ListItemButton>

                    <ListItemButton dense sx={styles.drawerAppButtons} component={Link} to="/applications/qrcodes/list"
                                    onClick={onClose}>
                        <ListItemIcon>
                            <img width={"24px"} height={"auto"} src={DynamicQRIcon} alt={"QR Codes"}/>
                        </ListItemIcon>
                        <ListItemText primary="QR Codes"/>
                    </ListItemButton>

                    <ListItemButton dense sx={styles.drawerAppButtons} component={Link} to="/applications/vcardpro"
                                    onClick={onClose}>
                        <ListItemIcon>
                            <img width={"24px"} height={"auto"} src={VCardsIcon} alt={"VCards Pro"}/>
                        </ListItemIcon>
                        <ListItemText primary="vCard Pro"/>
                    </ListItemButton>

                    {
                        wineLabel ? <ListItemButton dense sx={styles.drawerAppButtons} component={Link}
                                                    to="/applications/winelabel/control_panel" onClick={onClose}>
                                <ListItemIcon>
                                    <img width={"30px"} height={"auto"} src={WineLabel} alt={"Wine labelling"}/>
                                </ListItemIcon>
                                <ListItemText primary={t('App_winelabel')}/>
                            </ListItemButton>
                            : null
                    }

                    {/* Drawer section 2 */}
                    {
                        (!!complexUser && complexUser.organisation_role !== "MEMBER") &&
                        <>
                            <Divider sx={drawerDividersStyles} textAlign={"left"}>
                                {open && <Typography variant={"subtitle2"}>{t("Billing_Plans")}</Typography>}
                            </Divider>

                            <ListItemButton dense component={Link} to="/plans" onClick={onClose}>
                                <ListItemIcon><Star/></ListItemIcon>
                                <ListItemText primary={t("Upgrade")}/>
                            </ListItemButton>

                            <ListItemButton dense component={Link} to={{
                                pathname: "/alacarte",
                                state: returnLocationUpgradePlan(globalUser?.account_type)
                            }} onClick={onClose}>
                                <ListItemIcon><AddCircle/></ListItemIcon>
                                <ListItemText primary={t("Credits_add")}/>
                            </ListItemButton>
                        </>
                    }
                </List>
            </Drawer>
        </>
    )
}
