import React from "react";
// MUI
import {ButtonBase, Grid, Paper, Typography} from "@mui/material";
import {Business, Person} from "@mui/icons-material";

export default function AccountTypeSelector(props: any) {
    const {credentials, setCredentials, t} = props
    const styles = {
        paper: {
            padding: '16px',
        },
        buttonBase: {
            height: '100%',
            alignItems: 'center',
            width: '100%',
            mx: 2
        },
        icon: {
            width: '40px',
            height: '40px',
            fill: '#b4c74a',
            transition: '0.15s'
        },
    }

    return (
        <Grid item>

            <Grid
                container
                columnSpacing={3}
                justifyContent="center"
            >
                {
                    ["Individual", "Company"].map((type: any) => (
                        <Grid item key={type}>
                            <ButtonBase sx={styles.buttonBase} onClick={() => {
                                setCredentials({ ...credentials, attributes:
                                        {...credentials.attributes, "custom:usage": type}
                                })
                            }}>
                                <Paper variant="outlined" sx={styles.paper}>
                                    {type === "Individual" ? <Person sx={styles.icon}/> : <Business sx={styles.icon} />}
                                    <Typography variant="h6" component="h4" gutterBottom>{t(type)}</Typography>
                                </Paper>
                            </ButtonBase>
                        </Grid>
                    ))
                }
            </Grid>
        </Grid>
    );
}
