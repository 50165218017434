import React from "react"
import {Grid, ListItemText, Paper} from "@mui/material";
import {Button} from "@components";
import {Link} from "react-router-dom";

export default function SettingsUpgradeMessage(props: any) {

    const {t, accountType, message, unauthorized} = props

    return (
        <Paper variant={"outlined"} sx={{ p: 2, mt: 3 }}>
            <Grid container justifyContent={"space-between"} spacing={2}>
                <Grid item xs={12} md={8}>
                    <ListItemText
                        primary={unauthorized ? t("unauthorized_app") : t("Plan_display") + " " + t(accountType)}
                        secondary={t(message)}
                    />
                </Grid>
                <Grid
                    item
                    xs={8}
                    md={4}
                    sx={{
                        display: !unauthorized ? "flex" : "none",
                        flexDirection: "column",
                        alignContent: "center",
                        justifyContent: "center"
                    }}
                >
                    <Button primary component={Link} to="/plans">
                        {t("Upgrade")}
                    </Button>
                </Grid>
            </Grid>
        </Paper>
    )
}
