import React from "react"
// MUI
import {Alert, Grid, Typography} from "@mui/material";
import {Button} from "@components";


export default function HomeStaticAlerts(props: any) {

    const {isSandbox, billingAlert, i18n, styles, t} = props

    const renderEnvAlert = () => {
        if (!isSandbox) return <></>
        return <Alert severity="error" sx={styles.staticAlerts}>{t("Sandbox_alert")}</Alert>
    }

    const renderBigMessage = () => {

        if (!billingAlert) return <></>

        const returnBigMessageLink = () => {
            switch (i18n.language) {
                case "en-EN": return "https://medium.com/@team_21430/unitag-pricing-change-and-improved-experience-in-2024-184428b62922"
                case "fr-FR": return "https://medium.com/@team_21430/unitag-%C3%A9volution-tarifaire-et-am%C3%A9lioration-de-votre-exp%C3%A9rience-en-2024-97b2936f8402"
                case "es-ES": return "https://medium.com/@team_21430/unitag-cambios-de-precio-y-mejoras-de-tu-experiencia-en-2024-28b813364557"
                default: return "https://medium.com/@team_21430/unitag-pricing-change-and-improved-experience-in-2024-184428b62922"
            }
        }

        return (
            <>
                <Alert severity={"warning"} sx={{ width: "100%", mt: 2 }}>
                    <Grid container justifyContent={"space-between"} spacing={1}>
                        <Grid item xs={12} md={9}>
                            <Typography variant={"h5"}>
                                {t("big_message")}
                            </Typography>
                        </Grid>
                        <Grid item xs={10} md={3}>
                            <Button secondary href={returnBigMessageLink()} target={"_blank"}>
                                {t("read_more_bmessage")}
                            </Button>
                        </Grid>
                    </Grid>
                </Alert>
            </>
        )
    }

    return (
        <>
            {renderEnvAlert()}
            {renderBigMessage()}
        </>
    )
}