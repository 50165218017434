import React from 'react';
// MUI
import {
    Box,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    TextField,
    Typography
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
// MUI ICONS
import CloseIcon from '@mui/icons-material/Close';
// API
import {APIPost} from "@api";
// Unitag UI
import {Button} from "@components";
// TRANSLATION
import {useTranslation} from "react-i18next";
// Styles
import {styles} from "./styles";

const {REACT_APP_API_URL} = process.env;

export default function SubOrganisationDialog(props: any) {

    const { onCreate, onClose, open, handleError } = props;
    const {t} = useTranslation(['settings', 'common']);

    const [loading, setLoading] = React.useState<boolean>(false);
    // Sub Organisation state
    const [subOrgName, setSubOrgName] = React.useState<string>('');
    const [useCredits, setUseCredits] = React.useState<boolean>(true);

    const createSubOrganisation = () => {
        setLoading(true);

        APIPost<any>(REACT_APP_API_URL + "/org", {
            name: subOrgName,
            can_user_consume_credits: useCredits,
            can_user_create_org: false,
            parent_organisation_id: props.org.organisation_id,
        }).then((res: any) => {
            if (res.status === 200) {
                setLoading(false)
                setSubOrgName("")
                onCreate();
            } else {
                setLoading(false);
                handleError(t("Org_sub_creation_error"))
            }
        }).catch(() => {
            setLoading(false);
            handleError(t("Org_sub_creation_error"))
        })
    }

    const handleCreditsChange = (event: SelectChangeEvent) => {
        setUseCredits(event.target.value as string === "true")
    }

    // Close and reset
    const handleClose = () => {
        if (loading) return null;
        setSubOrgName("")
        onClose()
    }

    return (
        <Dialog open={open} onClose={handleClose} maxWidth={"sm"} fullWidth>
            <DialogTitle sx={styles.dialogPadding}>
                <Box display="flex" alignItems="center">
                    <Box flexGrow={1}>{t("Orga_sub_org")}</Box>
                    <IconButton onClick={handleClose}><CloseIcon/></IconButton>
                </Box>
            </DialogTitle>

            <DialogContent sx={styles.dialogPadding}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="body1">
                            {t("Orga_sub_dialog_text_1")}
                        </Typography>
                        <br/>
                        <Typography variant="body1">
                            {t("Orga_sub_dialog_text_2")}
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Paper elevation={0} variant="outlined" sx={styles.dialogPadding}>
                            <TextField
                                size="small"
                                variant="outlined"
                                label={t("Orga_sub_name")}
                                onChange={(e) => setSubOrgName(e.target.value)}
                                fullWidth
                            />

                            <FormControl size="small" variant="outlined" fullWidth sx={styles.formSpacing}>
                                <InputLabel id="credits-org-label">{t("Can_users_use_credits")}</InputLabel>
                                <Select
                                    labelId="credits-org-label"
                                    fullWidth
                                    autoFocus
                                    label={t("Can_users_use_credits")}
                                    value={useCredits.toString()}
                                    onChange={handleCreditsChange}
                                >
                                    <MenuItem value="true">
                                        <Typography>{t("True")}</Typography>
                                    </MenuItem>
                                    <MenuItem value="false">
                                        <Typography>{t("False")}</Typography>
                                    </MenuItem>
                                </Select>
                            </FormControl>

                        </Paper>
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions sx={styles.dialogPadding}>
                <Button text onClick={handleClose}>
                    {t("Cancel")}
                </Button>
                <Button primary onClick={createSubOrganisation} disabled={subOrgName === "" || loading}>
                    {t("Create_sub_org")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
