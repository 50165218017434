// BASE
import React from 'react';
import {Link} from "react-router-dom";
// Material UI
import {Container, Grid, Typography, Link as MaterialLink, Box} from '@mui/material';
// TRANSLATION
import {useTranslation} from "react-i18next";
import {getCurrentYear} from "@functions";
import {useUser} from "@context";




export default function Footer() {

    const {t} = useTranslation(['common']);
    const {user} = useUser()

    const styles = {
        footer: {
            borderTop: '1px solid rgba(0, 0, 0, 0.15)',
            padding: '25px 0',
            bgColor: "white",
            marginLeft: !user ? 0 : "64px",
            maxWidth: "100vw"
        },

        footerGrid: {
            margin: "16px 0px",
        },


        footerLink: {
            cursor: 'pointer',
            fontSize: "0.9rem",
            color: "#202020",
            "&:hover": {
                color: 'secondary.main',
            }
        },

        subfooter: {
            backgroundColor: '#292929',
            color: 'white',
            paddingTop: '10px',
            paddingBottom: '10px',
            textAlign: 'center',
        },
    };


    return (
        <React.Fragment>
            <Box sx={styles.footer}>
                <Container maxWidth="lg" sx={{ pl: "0!important", pr: "0!important" }}>
                    <Grid container direction="row" justifyContent="center" alignItems="center">
                        <Grid item xs={12} sm={6} sx={styles.footerGrid} style={{textAlign: 'start'}}>
                            <MaterialLink underline={"none"} href={t('Blog_url')} target="_blank"
                                          sx={styles.footerLink}>Blog</MaterialLink>
                            &nbsp; &nbsp;&nbsp;
                            <MaterialLink underline={"none"} sx={styles.footerLink} href="https://unitag.io/documentation" target="_blank"
                                          rel="noopener noreferrer">{t("Documentation")}</MaterialLink>
                            &nbsp; &nbsp;&nbsp;
                            <MaterialLink underline={"none"} href="mailto:sales@email.unitag.io" sx={styles.footerLink}>Contact
                                Sales</MaterialLink>
                        </Grid>

                        <Grid item xs={12} sm={6} sx={styles.footerGrid} style={{textAlign: 'end'}}>
                            <MaterialLink underline={"none"} sx={styles.footerLink} component={Link} to="/terms-and-conditions">
                                {t("Terms_of_service")}
                            </MaterialLink>
                            &nbsp; &nbsp;&nbsp;
                            <MaterialLink underline={"none"} sx={styles.footerLink} component={Link} to="/privacy">
                            {t("Privacy_policy")}
                            </MaterialLink>
                        </Grid>
                    </Grid>
                </Container>

            </Box>

            <Container maxWidth={false} sx={styles.subfooter}>
                <Typography variant="body2">Unitag {getCurrentYear()}</Typography>
            </Container>

        </React.Fragment>
    );
}
