import React from 'react';
// MUI
import {Paper, Typography, Box} from "@mui/material";
// styles
import {styles} from "../styles";
// Context
import {useUser} from "@context";

export default function MemberPanel(props: any) {

    const {t} = props
    const {getOrganisation, organisation} = useUser()
    const [organisationName, setOrganisationName] = React.useState<string | undefined>("");

    React.useEffect(() => {
        getOrganisation()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        if (!!organisation) setOrganisationName(organisation.member_org?.organisation_name)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box sx={styles.blockBalance}>
            <Paper variant={"outlined"} sx={styles.paper}>
                <Typography>
                    {t("Member_of")}<b>{organisationName}</b>
                </Typography>
            </Paper>
        </Box>
    )
}
