
function hexToRgb(hex: string | undefined, alpha?: number) {
    if (hex !== undefined) {
        let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

        if (result) {
            let r = parseInt(result[1], 16);
            let g = parseInt(result[2], 16);
            let b = parseInt(result[3], 16);
            let a = alpha ? alpha : 1
            return {
                r, g, b, a,
                rgba: `rgba(${r}, ${g}, ${b}, ${a})`
            };
        }
    }
    return {
        r: 0,
        g: 0,
        b: 0,
        a: 0
    };
}

const checkIfEmptyArray = (Array?: any[]) => {
    if (!Array) return true
    return Array?.length === 0;
}

const getCurrentYear = () => {
    const date = new Date()
    return date.getFullYear()
}

function formatJSIso8601ToHumanString(dateRaw: string, gmt?: boolean): string {
    const date: Date = new Date(dateRaw);

    let days: number = date.getDate(),
        months: number = date.getMonth() + 1;

    let strDays: string = String(days);
    if (days < 10) {
        strDays = "0" + days;
    }

    let strMonths: string = String(months);
    if (months < 10) {
        strMonths = "0" + months;
    }

    let hours: number = date.getHours();
    let strHours: string = String(hours);
    if (hours < 10) {
        strHours = "0" + hours
    }

    let minutes: number = date.getMinutes()
    let strMinutes: string = String(minutes);
    if (minutes < 10) {
        strMinutes = "0" + minutes
    }

    if(gmt) {
        return `${strDays}/${strMonths}/${date.getFullYear()}, 00:00`;
    }

    return `${strDays}/${strMonths}/${date.getFullYear()} ${strHours}:${strMinutes}`;
}

const formatJSDateToSlash = (date: Date) => {
    let month: number = date.getMonth() + 1;
    let day: number = date.getDate();
    let monthStr: string = month.toString();
    let dayStr: string = day.toString();

    if (month < 10) {
        monthStr = '0' + month.toString();
    }

    if (day < 10) {
        dayStr = '0' + day.toString();
    }

    return dayStr + '/' + monthStr + '/' + date.getFullYear();
}

function deepCopy(data: any): any {
    return JSON.parse(JSON.stringify(data));
}

function uppercaseFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

// date formatting with single classic date ; output DD/MM/YYYY HH:MM
const returnFormattedDate = (date: any) => {
    let days: number = date.getDate();
    let months: number = date.getMonth() + 1;

    let strDays: string = String(days);
    if (days < 10) {
        strDays = "0" + days.toString();
    }

    let strMonths: string = String(months);
    if (months < 10) {
        strMonths = "0" + months.toString();
    }

    let hours: number = date.getHours();
    let strHours: string = String(hours);
    if (hours < 10) {
        strHours = "0" + hours.toString();
    }

    let minutes: number = date.getMinutes()
    let strMinutes: string = String(minutes);
    if (minutes < 10) {
        strMinutes = "0" + minutes.toString();
    }

    return strDays + "/" + strMonths + "/" + date.getFullYear() + " " + strHours + ":" + strMinutes
}

function sanitizeUrl(url: any) {
    return url.replace(new RegExp("[^-A-Za-z0-9+&@#/%?=~_|!:,.;()]"), " ")
}

export {hexToRgb, checkIfEmptyArray, getCurrentYear, formatJSIso8601ToHumanString, formatJSDateToSlash, deepCopy, uppercaseFirstLetter, returnFormattedDate, sanitizeUrl}