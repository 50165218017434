import React from "react";
// MUI
import {
    Accordion as MUIAccordion,
    AccordionDetails,
    AccordionSummary,
    ListItem,
    ListItemIcon,
    ListItemText
} from "@mui/material";
import {ExpandMore} from "@mui/icons-material";

interface AccordionProps {
    expanded: boolean,
    onChange: any,
    icon?: any,
    title: string,
    children: any,
    disabled?: boolean,
    disabledMessage?: string
}

export default function GeneratorAccordion(props: AccordionProps) {
    return (
        <MUIAccordion
            variant={"outlined"}
            expanded={props.expanded}
            onChange={props.onChange}
            disabled={props.disabled}
            sx={{
                borderColor: "#d5d5d5",
                width: "100%",
                mb: 1,
                "&:before": {
                    display: "none!important"
                }
            }}
        >
            <AccordionSummary expandIcon={<ExpandMore />} sx={{ backgroundColor: "rgba(239,239,239,0.53)" }}>
                <ListItem dense>
                    {
                        props.icon &&
                        <ListItemIcon>
                            {props.icon}
                        </ListItemIcon>
                    }
                    <ListItemText primary={props.disabled && props.disabledMessage ? props.disabledMessage : props.title} primaryTypographyProps={{ fontWeight: 400, fontSize: 16 }} />
                </ListItem>
            </AccordionSummary>
            <AccordionDetails>
                {props.children}
            </AccordionDetails>
        </MUIAccordion>
    )
}