import React, {useState, useEffect} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
// MUI
import {
    Box,
    CircularProgress, Collapse,
    Container, Divider,
    Grid, Link, MenuItem,
    Paper, Stack,
    Typography,
} from '@mui/material';
import {CheckCircleOutline} from "@mui/icons-material";
// Assets
import Background from "@assets/img/background_login.webp";
// AWS
import {Auth} from "aws-amplify";
// TRANSLATION
import i18next from 'i18next';
import {useTranslation} from 'react-i18next';
// API & Types
import {APIPost} from "@api";
import {PostLoginResponse} from "@interfaces";
// Unitag UI
import {Button} from '@components';
// Components
import ValidationCodeDisplay from "./components/validationCodeDisplay";
import AccountTypeSelector from "./components/accountTypeSelector";
import IndividualAccountForm from "./components/individualAccountForm";
import CompanyAccountForm from "./components/companyAccountForm";
import BasicForm from "./components/basicForm";
import BrandsCarousel from "@components/BrandsCarousel/BrandsCarousel";
// Hooks
import {useSnackbar} from "@hooks";
import {useUser} from "@context";
// Regexes
import {regexEmail, sanitizeUrl} from "@functions";
// misc
import {
    goldSubscription,
    platinumSubscription,
    standaloneSubscription,
    standardSubscription
} from "@/constants/plans_en";
// Signup flows images
import SignupStandard from "@assets/img/signup/signup-standard.png"
import SignupGold from "@assets/img/signup/signup-gold.png"
import SignupPlatinum from "@assets/img/signup/signup-platinum.png"
import SignupStandalone from "@assets/img/signup/signup-standalone.png"

const {REACT_APP_API_URL} = process.env;

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const UnsecuredPage = () => {
    return (
        <div>
            <h1 style={{color: 'red'}}>
                If you see this page, the Unitag page you're trying to access is under Clickjacking security attack.
            </h1>
            <h2>
                Please inform the support team with the reference of the application from where you clicked this link.
            </h2>
            <h2>
                Click <Link href={sanitizeUrl(window.self.location.href)} title='Unitag console login' target='blank'>here</Link> to
                access the Unitag console safely.
            </h2>
        </div>
    )
}

export default function SignupComponent() {
    const {t} = useTranslation(['common']);
    const query = useQuery();
    const history = useHistory();
    const {handleError, handleSuccess} = useSnackbar()
    const {setCredentials: setCreds} = useUser()


    // State management
    const [loading, setLoading] = useState<boolean>(false);
    const [language, setLanguage] = useState<string>('fr-FR');
    // Signup process
    const [credentials, setCredentials] = useState<any>({
        username: '',
        password: '',
        attributes: {
            email: '',
            name: '',
            family_name: '',
            "custom:usage": '',
            "custom:company": '',
            "custom:company_position": '',
            "custom:company_size": '',
            "custom:company_industry": '',
            "custom:preferred_language": '',
        }
    })
    // Code
    const [isPendingValidation, setIsPendingValidation] = useState<boolean>(false);
    const [verifCode, setVerifCode] = useState<any>('');
    // Company setting
    const [isSettingCompany, setIsSettingCompany] = useState<boolean>(false);
    // Data fill
    const [emailPrefill, setEmailPrefill] = useState<string | null>("");
    // Captcha
    const [verifiedRecaptcha, setVerifiedRecaptcha] = useState<boolean>(true);
    // Finalisation
    const [canFinalize, setCanFinalize] = useState<boolean>(false);
    // Redirection following URL params
    const [product, setProduct] = useState<null | string>('');
    const [vcardsNumber, setVcardsNumber] = useState<number>(0);
    // Password confirmation
    const [isPasswordValid, setIsPasswordValid] = useState<boolean>(false);
    const [passwordRules, setPasswordRules] = useState({
        length: false,
        uppercase: false,
        lowercase: false,
        number: false,
        characters: false,
    });

    // Check if user is on a plan funnel or general funnel
    const isPlanFunnel = !!product && (product === "standard" || product === "gold" || product === "platinum" || product === "standalone");

    // styles
    const styles = {
        container: {
            minHeight: "100vh",
            backgroundImage: `url(${Background})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            px: "0!important"
        },
        logoGridItem: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center"
        },
        mainPaper: {
            position: "relative",
            minHeight: "100vh",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
        },
        logo: {
            height: isPlanFunnel ? 75 : 110,
            width: "auto",
            margin: 32,
            filter:"drop-shadow(0 0 0.75rem #afc928)"
        },
        title: {
            marginBottom: '32px'
        },
        subactions: {
            my: 2
        },
        translationBox: {
            padding: '24px',
            textAlign: 'center',
        },
        circularProgress: {
            m: 1
        },
        genericBrandsCarouselWrapper: {
            postion: 'absolute',
            width: "inherit",
            // backgroundColor: "red",
            top: 0,
        }
    }

    useEffect(() => {
        // This async call does not need to be catched for it manages redirection and will always throw an error if no credentials
        APIPost<PostLoginResponse>(REACT_APP_API_URL + "/login", {}).then((data) => {
            if (data.parsedBody) history.replace("/")
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setIsPasswordValid(false);
        if (
            passwordRules.lowercase &&
            passwordRules.uppercase &&
            passwordRules.length &&
            passwordRules.number &&
            passwordRules.characters
        ) {
            setIsPasswordValid(true);
        }
    }, [passwordRules]);

    useEffect(() => {
        const eid = query.get("eid");
        if (eid !== undefined && eid !== null) {
            setEmailPrefill(eid);
            // Also prefill credentials
            setCredentials({...credentials, username: eid})
        }

        const iid = query.get("iid");
        const oid = query.get("oid");

        const followedProduct = query.get("product");
        const _vcardsNumber= query.get("vcards-number")

        if (iid !== undefined && iid !== null) window.localStorage.setItem("iid", iid);
        if (oid !== undefined && oid !== null) window.localStorage.setItem("oid", oid);

        setProduct(followedProduct);
        if (!!_vcardsNumber) setVcardsNumber(Number(_vcardsNumber));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query]);

    useEffect(() => {
       checkPassword(credentials.password)
    }, [credentials.password])

    useEffect(() => {
        checkFinal()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [credentials.attributes])

    // Completion and regexes check
    const checkPassword = (password: string) => {
        if (password === "") {
            setPasswordRules({
                length: false,
                uppercase: false,
                lowercase: false,
                number: false,
                characters: false,
            })
        } else {
            let pwd = password;
            let format = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
            setPasswordRules({
                length: pwd.length >= 8,
                uppercase: pwd.toLowerCase() !== pwd,
                lowercase: pwd.toUpperCase() !== pwd,
                number: /\d/.test(pwd),
                characters: format.test(pwd)
            })
        }
    }

    const checkFinal = () => {
        setCanFinalize(false);
        if (credentials.attributes["custom:usage"] === "Company") {
            if (credentials.attributes['custom:company'] && credentials.attributes['custom:company_industry']
                && credentials.attributes['custom:company_position'] && credentials.attributes['custom:company_size']) {
                setCanFinalize(true);
            }
        } else if (credentials.attributes['custom:usage'] === "Individual") {
            if (credentials.attributes.family_name !== '' && credentials.attributes.name !== '') setCanFinalize(true);
        }

    }

    // Process logic
    // First step - registering basic info
    const signup = () => {
        // Start Circular Progress on Button
        setLoading(true);

        let usedLanguage = language
        if (usedLanguage === null || usedLanguage === undefined || usedLanguage === '') {
            usedLanguage = window.localStorage.i18nextLng
            if (usedLanguage === null || usedLanguage === undefined || usedLanguage === '') usedLanguage = "fr-FR"
        }

        setCredentials({...credentials, attributes: {...credentials.attributes, email: credentials.username}})

        Auth.signUp({
            username: credentials.username,
            password: credentials.password,
            attributes: {
                email: credentials.username,
                "custom:preferred_language": usedLanguage
            }
        }).then(() => {
            handleSuccess(`${t("Account_creation_success")}`)
            // We switch back context to Login
            setIsPendingValidation(true);
        }, reason => {
            handleError(reason.message)
            setLoading(false);
        }).catch(() => handleError(t("error_get_identification")))
    }
    // The registering detailed info
    const finish = () => {
        const redirectByPath = () => {
            switch (product) {
                case "vcards":
                    history.push("/applications/vcard/control_panel");
                    break;
                case "standalone":
                case "buy-vcards":
                    history.push({ pathname: "/alacarte", state: { fromPlan: standaloneSubscription, topUp: true, quantity: vcardsNumber } });
                    break;
                case "plans":
                    history.push("/plans");
                    break;
                case "winelabel":
                    history.push("/winelabel-buy-credits");
                    break;
                case "standard":
                case "standard-plan":
                    history.push({ pathname: '/alacarte', state: {fromPlan: standardSubscription} });
                    break;
                case "gold":
                case "gold-plan":
                    history.push({ pathname: '/alacarte', state: {fromPlan: goldSubscription} });
                    break;
                case "platinum":
                case "platinum-plan":
                    history.push({ pathname: '/alacarte', state: {fromPlan: platinumSubscription} });
                    break;
                case '':
                default:
                    history.push("/");
                    break;
            }
        }

        setLoading(true);
        let userInfo: any = {};

        Auth.signIn(credentials.username, credentials.password)
            .then((user) => {
                userInfo = user;
                Auth.updateUserAttributes(user, credentials.attributes)
                    .then(() => {
                        // Force login upon codeValidation
                        APIPost<PostLoginResponse>(REACT_APP_API_URL + "/login", {}).then(() => {
                            setLoading(false);
                            setCreds({
                                refresh_token: '',
                                id_token: userInfo.signInUserSession.idToken.jwtToken,
                            })
                            // We switch back context to Login
                            setIsPendingValidation(false);
                            setIsSettingCompany(false);
                            redirectByPath();
                        })
                    }, reason => {
                        setLoading(false);
                        handleError(reason.message)
                    })
            }, reason => {
                setLoading(false);
                handleError(reason.message)
            }).catch(() => handleError(t("error_get_identification")))
    }

    // Code verification
    const verifyCode = () => {
        Auth.confirmSignUp(credentials.username, verifCode)
            .then(() => {
                setCredentials({...credentials, attributes: {...credentials.attributes, email: credentials.username}})
                handleSuccess(t("success_verifying_code"))
                setIsPendingValidation(false);
                setIsSettingCompany(true);
            }).catch(() => {
            handleError(t("Verification_code_error"))
        }).finally(() => {
            setLoading(false)
        })
    }

    const handleVerificationCodeChange = (code: string) => {
        setVerifCode(code);
    }

    // render
    const renderButton = () => {

        const canProceed = !(
            !credentials.username ||
            !regexEmail(credentials.username) ||
            (!!credentials.password && !isPasswordValid) ||
            (!!credentials.password && (credentials.password !== credentials.passwordConf)))

        const returnButtonContent = () => {
            if (!credentials.username) return t("No_email_provided")
            if (!regexEmail(credentials.username)) return t("invalid_email")
            if (!isPasswordValid) return t("Password_not_matching_requirements")
            if (!!credentials.password && (credentials.password !== credentials.passwordConf)) return t("Password_confirmation_not_matching")
            return t("Signup")
        }

        return (
            <Grid item>
                <Button
                    primary fullWidth
                    disabled={!verifiedRecaptcha || loading || !canProceed}
                    onClick={signup}
                >
                    {loading ? <CircularProgress size={21}/> : returnButtonContent()}
                </Button>
            </Grid>
        )
    }

    const renderLayout = () => {

        const renderContentPreview = () => {

            const renderListElement = (listEl: any, color: string) => {
                return (
                    <>
                        <Stack direction="row" spacing={2}>
                            <CheckCircleOutline sx={{color: color }} />
                            <Typography variant={"body2"} sx={{ marginTop: "2px!important" }}>
                                {listEl}
                            </Typography>
                        </Stack>
                    </>
                )
            }

            const renderPlanPreview = (plan: any) => {
                return (
                    <>
                        <Box sx={{
                            backgroundImage: `url(${plan.image})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center 30%",
                            height: 250,
                            width: "100%!important",
                            borderRadius: "12px 12px 0 0"
                        }}></Box>
                        <Box p={4}>
                            <Typography align={"center"} fontSize={22} fontWeight={600} sx={{ color: plan.color }}>
                                {plan.title}
                            </Typography>
                            <Typography variant={"body1"} sx={{ my: 2 }}>
                                {plan.description}
                            </Typography>

                            {
                                plan.features.map((feat: any, i: number) => (
                                    <Box key={i}>{renderListElement(feat, plan.color)}</Box>
                                ))
                            }
                        </Box>
                    </>
                )
            }

            const renderContentType = () => {

                const plans = {
                    standard: {
                        title: t("standard_title"),
                        description: t("standard_description"),
                        image: SignupStandard,
                        features: [
                            t("standard_feature_1"),
                            t("standard_feature_2"),
                            t("standard_feature_3"),
                        ],
                        color: "#afc928"
                    },
                    gold: {
                        title: t("gold_title"),
                        description: t("gold_description"),
                        image: SignupGold,
                        features: [
                            t("gold_feature_1"),
                            t("gold_feature_2"),
                            t("gold_feature_3"),
                        ],
                        color: "#e39d00"
                    },
                    platinum: {
                        title: t("platinum_title"),
                        description: t("platinum_description"),
                        image: SignupPlatinum,
                        features: [
                            t("platinum_feature_1"),
                            t("platinum_feature_2"),
                            t("platinum_feature_3"),
                        ],
                        color: "#0077cc"
                    },
                    standalone: {
                        title: t("standalone_title"),
                        description: t("standalone_description"),
                        image: SignupStandalone,
                        features: [
                            t("standalone_feature_1"),
                            t("standalone_feature_2"),
                            t("standalone_feature_3"),
                        ],
                        color: "#00cc8f"
                    },
                }

                switch (product) {
                    case "standalone":
                    case "buy-vcards": return renderPlanPreview(plans.standalone)
                    case "standard-plan":
                    case "standard": return renderPlanPreview(plans.standard)
                    case "gold-plan":
                    case "gold": return renderPlanPreview(plans.gold)
                    case "platinum-plan":
                    case "platinum": return renderPlanPreview(plans.platinum)
                    default: return <></>
                }
            }

            const returnTier = () => {
                switch (product) {
                    case "standalone":
                    case "buy-vcards": return "vcards"
                    case "standard-plan":
                    case "standard": return "standard"
                    case "gold-plan":
                    case "gold": return "gold"
                    case "platinum-plan":
                    case "platinum":
                    default: return "platinum"
                }
            }

            return (
                <>
                    <Collapse in={isPlanFunnel} sx={{ display: "flex", width: "100%", maxWidth: "450px", minWidth: "250px" }}>
                        <Paper
                            variant={"outlined"}
                            sx={{
                                width: "100%",
                                minHeight: "300px",
                                borderRadius: 3,
                                border: "none"
                            }}
                        >
                            {renderContentType()}

                            <BrandsCarousel tier={returnTier()} />
                        </Paper>
                    </Collapse>
                </>
            )
        }

        const renderTranslationSection = () => {

            const toggleLang = (e: any) => {
                let lng = e.target.value
                setLanguage(lng)
                i18next.changeLanguage(lng, (err, t) => {
                    if (err) handleError(`${err}`);
                    t('key');
                }).then();
            }

            return (
                <Box sx={styles.translationBox}>
                    <Button text small value={"fr-FR"} onClick={toggleLang}>Français</Button>&nbsp;&nbsp;
                    <Button text small value={"en-EN"} onClick={toggleLang}>English</Button>&nbsp;&nbsp;
                    <Button text small value={"es-ES"} onClick={toggleLang}>Español</Button>&nbsp;&nbsp;
                    <Button text small value={"de-DE"} onClick={toggleLang}>Deutsch</Button>
                </Box>
            )
        }

        // render global wrapper
        const renderSignupStep = () => {

            const signupStepSwitch = () => {
                if (isPendingValidation) {
                    return <ValidationCodeDisplay
                        {...{handleVerificationCodeChange, verifyCode, t}} email={credentials.attributes.email}
                    />;
                } else if (isSettingCompany && credentials.attributes["custom:usage"] === '') {
                    return <AccountTypeSelector
                        {...{credentials, setCredentials, t}}
                    />;
                } else if (isSettingCompany && credentials.attributes["custom:usage"] === "Individual") {
                    return <IndividualAccountForm
                        {...{credentials, setCredentials, canFinalize, finish, loading, t}}
                    />;
                } else if (isSettingCompany && credentials.attributes["custom:usage"] === "Company") {
                    return <CompanyAccountForm
                        {...{canFinalize, credentials, setCredentials, loading, finish, t}}
                    />;
                } else {
                    return <BasicForm
                        {...{
                            emailPrefill,
                            credentials, setCredentials,
                            isPasswordValid, passwordRules,
                            renderButton,
                            setVerifiedRecaptcha,
                            product,
                            t
                        }}
                    />;
                }
            }

            return (
                <Grid container justifyContent={"center"} spacing={3} mt={4}>
                    <Grid item xs={10} sm={8} md={9} lg={7}>
                        {
                            !isPlanFunnel &&
                            <Typography variant="body1" sx={{ color: "#b1b1b1"}} align="center">
                                {t("like_our_partners")}
                            </Typography>
                        }
                        <Typography variant="h4" sx={styles.title} align="center">
                            {t("Signup_to_unitag")}
                        </Typography>
                        <Divider />
                    </Grid>
                    <Grid item xs={10} sm={8} md={9} lg={7}>
                        {signupStepSwitch()}
                    </Grid>
                    <Grid item xs={10} sm={8} md={9} lg={7}>
                        {renderTranslationSection()}
                    </Grid>
                </Grid>
            )
        }

        if (window.self !== window.top) return <UnsecuredPage />

        return (
            <Container maxWidth={false} sx={styles.container}>
                <Grid container justifyContent={"center"} spacing={3}>
                    <Grid item md={6} sx={styles.logoGridItem}>
                        <img
                            draggable={false}
                            src={"/assets/logo-unitag.svg"}
                            alt={"login logo"}
                            height={isPlanFunnel ? 100 : 200} width={isPlanFunnel ? 225 : 450}
                            style={styles.logo}
                        />
                        {renderContentPreview()}
                    </Grid>
                    <Grid item xs={12} sm={11} md={6}>

                        <Paper elevation={0}>
                            {
                                !isPlanFunnel &&
                                <Box sx={styles.genericBrandsCarouselWrapper}>
                                    <BrandsCarousel tier={"platinum"} />
                                </Box>
                            }
                            <Paper elevation={0} sx={styles.mainPaper}>
                                {renderSignupStep()}
                            </Paper>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        )
    }

    return <main>{renderLayout()}</main>
}
