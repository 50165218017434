import React, {useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom';
// MUI
import {
    Grid,
    Paper,
    Typography,
    Box,
    Divider,
    Skeleton,
    Tooltip, TextField, Autocomplete
} from "@mui/material";
import {Add, AddToQueue, Delete, HelpOutlineOutlined} from "@mui/icons-material";
// API
import {GetOrganisationResponse} from "@interfaces";
import {APIDelete} from "@api";
// TRANSLATION
import {useTranslation} from "react-i18next";
// Unitag UI
import {Button} from "@components";
// Dialogs
import SubOrganisationDialog from "../dialogs/OrgCreateSubDialog";
import DeleteSubOrganisationDialog from "../dialogs/OrgDeleteSubDialog";
import InviteUserDialog from "../dialogs/OrgInviteUserDialog";
import ShareCreditsDialog from "../dialogs/OrgShareCreditsDialog";
// Styles
import {styles} from "../styles";
// Context
import {useUser} from "@context";

const {REACT_APP_API_URL} = process.env;

export default function OrganisationsOverviewTab(props: any) {

    const {allowWineLabel, handleSuccess, handleError} = props

    const {t} = useTranslation(['settings', 'common']);
    const history = useHistory();

    const {
        user,
        organisation,
        organisationInvitations,
        credits, getCredits,
        getOrganisation,
        getOrganisationInvitations,
        getOrganisationAsMember,
        authorisations, getAuthorisations
    } = useUser()

    const [loading, setLoading] = React.useState<boolean>(true);

    // Main data
    const [currentOrg, setCurrentOrg] = React.useState<GetOrganisationResponse | null>(null);
    const [initOrg, setInitOrg] = React.useState<GetOrganisationResponse | null>(null);

    // Sub organisations data
    const [isSubOrganisation, setIsSubOrganisation] = React.useState<boolean>(false);
    const [subOrganisations, setSubOrganisations] = React.useState<GetOrganisationResponse[] | null>(null);
    const [subOrganisationsCount, setSubOrganisationsCount] = React.useState<number | null>(null);
    const [selectedSubOrganisation, setSelectedSubOrganisation] = React.useState<any>("");

    // Sub organisations creation
    const [openSubOrgDialog, setOpenSubOrgDialog] = React.useState<boolean>(false);
    // Sub organisations deletion
    const [openSubOrgDeletionDialog, setOpenSubOrgDeletionDialog] = React.useState<boolean>(false);

    // User Invitation
    const [openInvitationDialog, setOpenInvitationDialog] = React.useState<boolean>(false);
    const [organisationToInviteIn, setOrganisationToInviteIn] = React.useState<GetOrganisationResponse | null>(null);

    // Credits sharing
    const [sharingDialog, setSharingDialog] = useState<any>({ open: false, subOrganisation: null, product: null, credits: null });

    // Effects
    useEffect(() => {
        getAuthorisations()
        getOrganisation()
        getCredits()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!!organisation) {
            if (!!organisation.member_org) {
                setCurrentOrg(organisation.member_org);
                getOrganisationAsMember(organisation.organisation_id)
                setLoading(false)
                return;
            }
            setCurrentOrg(organisation)
            setInitOrg(organisation)
            setLoading(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organisation])

    // Hydrate sub organisations and set global data
    useEffect(() => {
        hydrateSubOrganisations();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initOrg]);

    useEffect(() => {
        if (!!subOrganisations && subOrganisations.length > 0) {
            setSelectedSubOrganisation(subOrganisations[0]);
            return;
        }
        return;
    }, [subOrganisations]);

    useEffect(() => {
        if (!!selectedSubOrganisation) getOrganisationInvitations(selectedSubOrganisation.organisation_id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSubOrganisation])

    const hydrateSubOrganisations = () => {
        if (initOrg !== null && initOrg !== undefined)  {
            // Check if is root organisation or not
            if (!initOrg.is_root_org) {
                setIsSubOrganisation(true);
                setLoading(false);
                return;
            }
            // If not a sub-organisation, fetch for sub-organisations
            // If no sub-org have been created, simply turn off loading
            if (!!initOrg.sub_organisations) {
                // Order sub-organisations by name
                let org = initOrg.sub_organisations.sort((a, b) =>
                    a.organisation_name.localeCompare(b.organisation_name)
                );
                setSubOrganisationsCount(org.length);
                setSubOrganisations(org);
                setLoading(false);
                return;
            } else {
                setLoading(false);
                return;
            }
        }
        return;
    }

    // Custom repeating components
    const OrganisationsOverviewBox = (props: any) => {

        let {element} = props;

        if (element.hideCondition) return null;

        return (
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <Paper variant={"outlined"} sx={{ borderRadius: 3 }}>
                    <Box sx={styles.overviewData}>
                        <Box sx={styles.overviewTooltipBox}>
                            <Typography variant={"body2"} align={"center"}>
                                {element.name}
                            </Typography>
                            {
                                element.name === t("Orga_seats_left") && (
                                    <>
                                        &nbsp;
                                        <Tooltip title={`${t("Orga_seats_left_tooltip")}`} arrow placement={"top"}>
                                            <HelpOutlineOutlined fontSize={"inherit"} />
                                        </Tooltip>
                                    </>
                                )
                            }
                        </Box>
                        <Typography variant={"body1"} align={"center"} fontWeight={700}>
                            {element.data}
                        </Typography>
                    </Box>
                    <Button
                        sx={styles.overviewAction}
                        fullWidth
                        small
                        dense
                        primary
                        onClick={element.action}
                        startIcon={<Add />}
                        aria-label={element.actionTitle}
                        disabled={element?.disableCondition}
                    >
                        {element.actionTitle}
                    </Button>
                </Paper>
            </Grid>
        )
    }

    // Deleting Sub Organisations
    const deleteSubOrganisation = () => {
        setLoading(true);
        APIDelete<any>(`${REACT_APP_API_URL}/org/${selectedSubOrganisation.organisation_id}`, {}).then((data: any) => {
            if (data.status >= 400) {
                setLoading(false);
                handleError(t("Snackbar_suborg_deleted_error"))
            } else {
                setLoading(false);
                setOpenSubOrgDeletionDialog(false);
                setSubOrganisations(null);
                setSubOrganisationsCount(0);
                setSelectedSubOrganisation("");

                handleSuccess(t("Snackbar_suborg_deleted_success"))
            }
        }).catch(() => handleError(t("Snackbar_suborg_deleted_error"))
        ).finally(() => getOrganisation());
    }

    // Creeating Sub Organisations
    const resolveSubOrganisationCreation = () => {
        setOpenSubOrgDialog(false);
        handleSuccess(t("Snackbar_suborg_creation_success"))
        getOrganisation();
    }

    // Dialog Controls
    const handleOpenInvitationDialog = (selectedOrg: GetOrganisationResponse | null) => {
        setOrganisationToInviteIn(selectedOrg);
        setOpenInvitationDialog(true);
    }

    const handleCloseInvitationDialog = () => {
        setOrganisationToInviteIn(null);
        setOpenInvitationDialog(false);
    }

    const resolveInvitationSuccess = () => {
        handleSuccess(t("Snackbar_invitation_sent"))
        setOrganisationToInviteIn(null);
        setOpenInvitationDialog(false);
    }

    const handleOpenSharingDialog = () => {
        setSharingDialog({ ...sharingDialog, open: true, subOrganisation: selectedSubOrganisation.organisation_id });
    }

    const handleCloseSharingDialog = () => {
        setSharingDialog({ open: false, subOrganisation: null, product: null, credits: null });
    }

    // Display functions
    const returnRole = () => {
        switch(initOrg?.role) {
            case "OWNER": return t("Owner_of");
            case "ADMIN": return t("Admin_of");
            case "MEMBER": return t("Member_of");
            default: return t("Member_of");
        }
    }

    const returnOrganisationOverview = () => {

        const overview = [
            {
                name: t("Users"),
                data: loading ? "-" : currentOrg?.seats_used,
                action: () => handleOpenInvitationDialog(currentOrg),
                actionTitle: t("common:Add"),
                disableCondition: (loading || currentOrg?.seats_left === 0 || !currentOrg)
            },
            {
                name: t("Orga_seats_left"),
                data: loading ? "-" : currentOrg?.seats_left,
                action: () => { history.push({ pathname: "/alacarte", state: { fromPlan: user.account_type, topUp: true } }) },
                actionTitle: t("common:Buy"),
                disableCondition: (loading || isSubOrganisation || initOrg?.role === "ADMIN" || (!!user && user.account_type === "standalone"))
            },
            {
                name: t("Orga_sub_org"),
                data: loading ? "-" : !!subOrganisationsCount ? subOrganisationsCount : t("common:None"),
                action: () => setOpenSubOrgDialog(true),
                actionTitle: t("common:Create"),
                disableCondition: (loading || !currentOrg || isSubOrganisation),
                hideCondition: isSubOrganisation || (!authorisations.subOrganisations && !allowWineLabel)
            }
        ]

        return (
            <>
                <Grid container spacing={2} justifyContent={"flex-start"} mb={2}>
                    {overview.map((element: any, index: number) => {
                        return <OrganisationsOverviewBox key={index} element={element} />
                    })}
                </Grid>
            </>
        )
    }

    const returnSubOrganisationSelection = () => {

        return (
            <>
                <Divider textAlign={"left"}>
                    <Typography variant={"h6"} component={"p"}>
                        {t("Orga_sub_org")}
                    </Typography>
                </Divider>

                <Grid container spacing={2} justifyContent={"space-between"} my={2}>
                    <Grid item xs={10} sm={4}>
                        <Autocomplete
                            size={"small"}
                            disableClearable
                            autoHighlight
                            noOptionsText={t("common:No_elements_found")}
                            value={selectedSubOrganisation}
                            onChange={(e, newValue) => setSelectedSubOrganisation(newValue)}
                            disablePortal
                            getOptionLabel={(option: any) => option.organisation_name ? option.organisation_name : ""}
                            options={!!subOrganisations ? subOrganisations : []}
                            renderInput={(params) => <TextField {...params} label={t("Orga_sub_org")} />}
                        />
                    </Grid>

                    <Grid item xs={8} sm={5} md={'auto'}>
                        <Button
                            primary
                            small
                            startIcon={<AddToQueue />}
                            dense
                            sx={styles.formBalance}
                            onClick={() => handleOpenSharingDialog()}
                            aria-label={"Share"}
                        >
                            {t("share_credits")}
                        </Button>


                        <Button
                            primary
                            small
                            startIcon={<Add />}
                            dense
                            sx={styles.formBalance}
                            onClick={() => setOpenSubOrgDialog(true)}
                            aria-label={"Create"}
                        >
                            {t("common:Create")}
                        </Button>

                        <Button
                            delete
                            small
                            startIcon={<Delete />}
                            dense
                            sx={styles.formBalance}
                            onClick={() => setOpenSubOrgDeletionDialog(true)}
                            disabled={selectedSubOrganisation === ""}
                            aria-label={"Delete"}
                        >
                            {t("common:Delete")}
                        </Button>
                    </Grid>
                </Grid>
            </>
        )
    }

    const returnSubOrganisationOverview = () => {

        if (subOrganisationsCount === 0 || subOrganisationsCount === null) {
            return (
                <Paper variant={"outlined"} sx={styles.greyedPaper}>
                    <Typography>
                        {t("No_sub_organisations_created_yet")}
                    </Typography>
                </Paper>
            )
        }

        const overview = [
            {
                name: t("common:Users"),
                data: !!organisationInvitations ? organisationInvitations.length : 0,
                action: () => handleOpenInvitationDialog(selectedSubOrganisation),
                actionTitle: t("common:Add")
            }
        ]

        return (
            <>
                <Grid container spacing={2} justifyContent={"flex-start"} mb={2}>
                    {overview.map((element: any, index: number) => {
                        return <OrganisationsOverviewBox key={index} element={element} />
                    })}
                </Grid>
            </>
        )
    }

    const returnSubOrganisationPanel = () => {
        if (loading) return <Skeleton variant={"rectangular"} height={200} />
        // specific use
        if (!authorisations.subOrganisations && !allowWineLabel) return <></>

        if (!loading && !isSubOrganisation) {
            return (
                <>
                    {returnSubOrganisationSelection()}
                    {returnSubOrganisationOverview()}
                </>
            )
        }
    }

    return (
        <>
            <Grid container justifyContent={"space-between"} mb={2}>
                <Grid item xs={12} sm={6}>
                    <Typography variant="body2">
                        {loading ? <Skeleton variant={"text"} width={100} /> : returnRole()}
                    </Typography>
                    <Typography variant="h6" component="p" color="secondary">
                        {currentOrg?.organisation_name ? currentOrg.organisation_name : <Skeleton variant={"text"} />}
                    </Typography>
                </Grid>
            </Grid>

            {returnOrganisationOverview()}

            {returnSubOrganisationPanel()}

            <SubOrganisationDialog
                open={openSubOrgDialog}
                onCreate={resolveSubOrganisationCreation}
                onClose={() => setOpenSubOrgDialog(false)}
                org={currentOrg}
                {...{handleError}}
            />

            <DeleteSubOrganisationDialog
                loading={loading}
                open={openSubOrgDeletionDialog}
                onClose={() => setOpenSubOrgDeletionDialog(false)}
                onDelete={deleteSubOrganisation}
                subOrganisation={selectedSubOrganisation}
            />

            <InviteUserDialog
                onInvite={handleCloseInvitationDialog}
                selectedOrg={organisationToInviteIn}
                seatsLeft={initOrg?.seats_left}
                open={openInvitationDialog}
                onClose={handleCloseInvitationDialog}
                onSuccess={resolveInvitationSuccess}
                {...{user, handleSuccess, handleError}}
            />

            <ShareCreditsDialog
                open={sharingDialog.open}
                onClose={() => handleCloseSharingDialog()}
                subOrganisation={sharingDialog.subOrganisation}
                {...{handleError, handleSuccess, sharingDialog, credits, t}}
            />
        </>
    )
}
