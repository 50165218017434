import React from "react"
import {Link as RouterLink} from "react-router-dom"
// MUI
import {
    Box,
    FormControl,
    Grid, InputAdornment,
    Link,
    TextField,
    Typography,
    Tooltip, Divider
} from "@mui/material";
import {AccountCircle, Cancel, CheckCircle, Lock} from "@mui/icons-material";
// RECAPTCHA
import Recaptcha from "react-recaptcha";
// Regexes
import {regexEmail} from "@functions";

const {REACT_APP_CAPTCHA_PUBLIC_KEY,} = process.env;

export default function BasicForm(props: any) {

    const {
        emailPrefill,
        credentials, setCredentials,
        isPasswordValid,
        renderButton,
        passwordRules,
        setVerifiedRecaptcha,
        product,
        t
    } = props

    const styles = {
        spacing: {
            my: 1
        }
    }

    const returnPasswordRules = () => {
        const rules = [
            { key: "Pwd_1_lowercase", prop: passwordRules.lowercase },
            { key: "Pwd_8_characters", prop: passwordRules.length },
            { key: "Pwd_1_uppercase", prop: passwordRules.uppercase },
            { key: "Pwd_1_number", prop: passwordRules.number },
            { key: "Pwd_1_special", prop: passwordRules.characters },
        ];

        return (
            <>
                <Typography fontSize={14}>
                    {t("Pwd_rules")}
                </Typography>
                {rules.map((rule: any, index: number) => {
                    return (
                        <Typography fontSize={14} key={index} color={rule.prop ? "primary" : "inherit"}>
                            {t(rule.key)}
                        </Typography>
                    )
                })}
            </>
        )
    }

    const emailOnChange = (e: any) => {
        setCredentials({...credentials, username: e.target.value})
        if (e.target.value.includes('bouygues-construction') || e.target.value.includes("losinger-marazzi.ch")) {
            window.location.replace('https://bouygues.console.unitag.io')
            return
        } else if (e.target.value.includes('@sisley')) {
            window.location.replace('https://sisley.console.unitag.io')
            return
        } else if (e.target.value.includes('@adeo')) {
            window.location.replace('https://adeo.console.unitag.io')
            return
        } else if (e.target.value.includes('@equans')) {
            window.location.replace('https://equans.console.unitag.io')
            return
        } else if (e.target.value.includes('@bonduelle')) {
            window.location.replace('https://bonduelle.console.unitag.io')
            return
        } else if (
            e.target.value.includes('@maison-colibri.com') ||
            e.target.value.includes('@nuwen.com') ||
            e.target.value.includes('@patisseriesgourmandes.com') ||
            e.target.value.includes('@phosphea.com') ||
            e.target.value.includes('@roullier.com') ||
            e.target.value.includes('@timacagro.com')
        ) {
            window.location.replace('https://roullier.console.unitag.io')
            return
        } else if (e.target.value.includes('@groupeseb')) {
            window.location.replace('https://seb.console.unitag.io')
            return
        }
    }

    return (
        <Box>
            <FormControl sx={styles.spacing} fullWidth variant="outlined">
                <TextField
                    label={t("Mail")}
                    id="email"
                    defaultValue={emailPrefill}
                    placeholder={t("Your_mail")}
                    onChange={emailOnChange}
                    variant="outlined"
                    error={Boolean(!regexEmail(credentials.username))}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <AccountCircle/>
                            </InputAdornment>
                        ),
                    }}
                />
            </FormControl>
            <FormControl sx={styles.spacing} fullWidth variant="outlined">
                <Tooltip title={returnPasswordRules()} placement={"right"} arrow>
                    <TextField
                        label={t("Password")}
                        type="password"
                        id="password"
                        onChange={(e) => setCredentials({...credentials, password: e.target.value})}
                        placeholder={t("Your_password")}
                        variant="outlined"
                        error={Boolean(credentials.password && !isPasswordValid)}
                        InputProps={{
                            startAdornment: (
                                isPasswordValid ?
                                    <InputAdornment position="start">
                                        <CheckCircle style={{color: "green"}}/>
                                    </InputAdornment>

                                : !isPasswordValid && credentials.password !== "" ?
                                    <InputAdornment position="start">
                                        <Cancel style={{color: "red"}}/>
                                    </InputAdornment>
                                :
                                    <InputAdornment position="start">
                                        <Lock />
                                    </InputAdornment>
                            ),
                        }}
                    />
                </Tooltip>
            </FormControl>

            <FormControl sx={styles.spacing} fullWidth variant="outlined">
                <TextField
                    label={t("Confirm_password")}
                    type="password"
                    id="passwordConfirmation"
                    onChange={(e) => setCredentials({...credentials, passwordConf: e.target.value})}
                    placeholder={t("Confirm_password")}
                    variant="outlined"
                    InputProps={{
                        startAdornment: (
                            !!credentials.passwordConf && (credentials.password === credentials.passwordConf) ?
                                <InputAdornment position="start">
                                    <CheckCircle style={{color: "green"}}/>
                                </InputAdornment>
                            : !!credentials.passwordConf && (credentials.password !== credentials.passwordConf) ?
                                <InputAdornment position="start">
                                    <Cancel style={{color: "red"}}/>
                                </InputAdornment>
                            :
                                <InputAdornment position="start">
                                    <Lock />
                                </InputAdornment>
                        )
                    }}
                />
            </FormControl>

            <Grid container justifyContent={{ xs: "center", md: "space-between" }} rowSpacing={2}>
                <Grid item xs={12} sm={6} textAlign={{ xs: "center", sm: "left" }}>
                    <Link component={RouterLink} to={`/login${product ? "?product=" + product : ''}`} color="secondary">
                        {t("Already_have_an_account")}
                    </Link>
                </Grid>
                <Grid item xs={12} sm={6} textAlign={{ xs: "center", sm: "right" }}>
                    <Link component={RouterLink} to="/forgot_password" color="secondary">
                        {t("Forgot_password")}
                    </Link>
                </Grid>

                <Grid item xs={12}>
                    <Recaptcha
                        sitekey={REACT_APP_CAPTCHA_PUBLIC_KEY!}
                        render="explicit"
                        verifyCallback={() => setVerifiedRecaptcha(true)}
                    />
                </Grid>

                <Grid item xs={12}><Divider /></Grid>

                <Grid item xs={12}>{renderButton()}</Grid>
            </Grid>
        </Box>
    );
}
