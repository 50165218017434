import React from "react";
import {Link} from "react-router-dom";
// MUI
import {Box, Divider, IconButton, Menu, Tooltip, Typography} from "@mui/material";
import {PlaylistAdd, Refresh} from "@mui/icons-material";
// Components
import Notification from "@components/notification/notification";
// Unitag UI
import {Button} from "@components";
// Styles
import {styles} from "./styles";
// Translation
import {useTranslation} from "react-i18next";

export default function NotificationsMenu(props: any) {

    const {t} = useTranslation(["notifications" ,"common"]);
    const {anchorEl, open, handleClose, notifications, fetchNotifications, acknowledgeNotifications} = props;

    const returnNotifications = () => {

        if (!!notifications && notifications.length > 0) {
            return (
                notifications.map((notification: any, index: number) => {
                    const {published_at, level, message, title, section, uuid, recipients} = notification;
                    return (
                        <Notification
                            key={index}
                            {...{
                                title,
                                uuid,
                                published_at,
                                section,
                                message,
                                recipients,
                                level,
                                handleClose,
                                acknowledgeNotifications
                            }}
                        />
                    );
                })
            )
        }

        return (
            <Box sx={styles.emptyNotifications}>
                <Typography fontWeight={700} variant={"body1"} color={"text.secondary"} align={"center"}>
                    {t("Notifications_nothing_new")}
                </Typography>
            </Box>
        );
    }

    return (
        <>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                MenuListProps={{sx: styles.menuWrapper}}
                slotProps={{ paper: { sx: styles.menuPaper } }}
            >
                <Box sx={styles.menuHeader}>
                    <Typography variant={"body1"} sx={styles.menuHeaderTitle}>
                        {t("Notifications")}
                    </Typography>
                    <Tooltip title={`${t("Refresh")}`} arrow placement={"left"}>
                        <IconButton size={"small"} aria-label={"refresh notifications"} onClick={fetchNotifications}>
                            <Refresh fontSize={"inherit"} />
                        </IconButton>
                    </Tooltip>
                </Box>

                <Divider />
                {returnNotifications()}

                <Box textAlign={"center"} sx={{ margin: 'auto' }}>
                    <Button
                        text small
                        secondary
                        startIcon={<PlaylistAdd />}
                        component={Link}
                        to={"/notifications-center"}
                        onClick={handleClose}
                    >
                        {t("common:More")}
                    </Button>
                </Box>
            </Menu>
        </>
    )
}