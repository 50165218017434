import React, {useState} from "react";
import {
    Box, Button, Collapse,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Paper,
    Typography
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {useTranslation} from "react-i18next";
import {KeyboardArrowDown, KeyboardArrowUp} from "@mui/icons-material";


const useStyles = {
    standard: {color: '#b4c74a'},
    standardBackground: {color: 'white', backgroundColor: '#b4c74a'},

    sectionWrapper: {marginBottom: '0'},
    paperWrapper: {borderRadius: 2, zIndex: 5},
    paper: {
        borderTopRightRadius: '8px',
        borderTopLeftRadius: '8px',
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        padding: '8px',
        textAlign: 'center',
        marginBottom: '8px',
        zIndex: 6
    },

    featuresBox: {padding: '16px'},
    featuresListBox: {},
    featuresSupportBox: {},
    featuresListItem: {}
};

export function StandardPlan() {


    const classes = useStyles;
    const {t} = useTranslation(['plans', 'common', 'billings']);
    const [openFeatures, setOpenFeatures] = useState<boolean>(false)

    const features = {
        feats: [
            t("standard_feature_1"),
            t("standard_feature_2"),
            t("standard_feature_3"),
            t("standard_feature_4"),
            t("standard_feature_5"),
        ],
        support: [
            t("standard_support_feature_1"),
            t("standard_support_feature_2")
        ]
    }

    return (
        <Grid container alignItems="center" justifyContent="center">
            <Grid item xs={12} md={10} lg={8} sx={classes.sectionWrapper}>
                <Paper variant="outlined" sx={classes.paperWrapper}>
                    <Paper elevation={0} sx={{...classes.standardBackground, ...classes.paper}}>
                        <Typography variant="h4">{t("Standard")}</Typography>
                    </Paper>

                    <Box sx={classes.featuresBox}>
                        <Box style={{height: '60px'}}>
                            <Typography variant="body2" align="center">{t("Standard_headline")}</Typography>
                        </Box>
                        <Button
                            color={"secondary"}
                            fullWidth
                            onClick={() => setOpenFeatures(!openFeatures)}
                            startIcon={openFeatures ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                        >
                            {openFeatures ? t("hide_details") : t("show_details")}
                        </Button>
                        <Collapse in={openFeatures}>
                            <Divider style={{marginTop: '16px', marginBottom: 16}}/>
                            <Typography style={{marginLeft: '40px'}} variant="subtitle2">{"Free features, plus:"}</Typography>

                            <Box sx={classes.featuresListBox}>
                                <List style={{marginLeft: '50px'}}>
                                    {
                                        features.feats.map((feat: any, index: number) => (
                                            <ListItem sx={classes.featuresListItem} key={index}>
                                                <ListItemIcon><CheckCircleIcon sx={classes.standard}/></ListItemIcon>
                                                <ListItemText secondary={feat}/>
                                            </ListItem>
                                        ))
                                    }
                                </List>
                            </Box>
                            <Typography style={{marginLeft: '40px'}} variant="subtitle2">Support:</Typography>
                            <Box sx={classes.featuresSupportBox}>
                                <List style={{marginLeft: '50px'}}>
                                    {
                                        features.support.map((feat: any, index: number) => (
                                            <ListItem sx={classes.featuresListItem} key={index}>
                                                <ListItemIcon><CheckCircleIcon sx={classes.standard}/></ListItemIcon>
                                                <ListItemText secondary={feat}/>
                                            </ListItem>
                                        ))
                                    }
                                </List>
                            </Box>
                        </Collapse>
                    </Box>
                </Paper>
            </Grid>
        </Grid>
    );
}
