import React from 'react'
// MUI
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    Typography
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
// Unitag UI
import {Button} from "@components";
// TRANSLATION
import {useTranslation} from "react-i18next";
// Styles
import {styles} from "./styles";

export default function DeleteSubOrganisationDialog(props: any) {

    const {t} = useTranslation(['settings', 'common'])
    const {onClose, open, subOrganisation, loading, onDelete} = props;

    return (
        <>
            <Dialog
                fullWidth
                maxWidth={'sm'}
                open={open}
                onClose={onClose}
            >
                <DialogTitle sx={styles.dialogPadding}>
                    <Box display="flex" alignItems="center">
                        <Box style={{textAlign: 'left'}} flexGrow={1}>
                            {t("Confirm_sub_org_deletion")}
                        </Box>
                        <IconButton onClick={onClose}>
                            <CloseIcon/>
                        </IconButton>
                    </Box>
                </DialogTitle>

                <DialogContent sx={styles.dialogPadding}>
                    <Typography>
                        {t('Delete_sub_org_confirm')}<strong>{subOrganisation.organisation_name}</strong>
                    </Typography>
                    <Divider sx={styles.divider} />
                    <Typography>
                        {t('Delete_sub_org_notes')}
                    </Typography>
                </DialogContent>

                <DialogActions sx={styles.dialogPadding}>
                    <Button
                        text
                        onClick={onClose}
                        disabled={loading}
                    >{
                        t("common:Cancel")}
                    </Button>
                    <Button
                        delete
                        onClick={onDelete}
                        disabled={loading}
                    >
                        {t("common:Delete")}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
