import React from "react"
// MUI
import {
    Box,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Paper
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
// Stripe
import {CardElement} from "@stripe/react-stripe-js";
// Components
import {Button} from "@components";

export default function AddPaymentMethodDialog(props: any) {

    const {loading, open, onClose, onAdd, stripe, t} = props

    return (
        <>
            <Dialog
                fullWidth
                maxWidth={'sm'}
                open={open}
                keepMounted
                onClose={onClose}
                scroll="body"
            >
                <DialogTitle style={{padding: '24px'}}>
                    <Box display="flex" alignItems="center">
                        <Box style={{textAlign: 'left'}} flexGrow={1}>{t("Add_payment_method")}</Box>
                        <IconButton onClick={onClose}><CloseIcon/></IconButton>
                    </Box>
                </DialogTitle>

                <DialogContent style={{padding: '24px'}}>
                    <Grid container justifyContent={'center'} alignItems="center">
                        <Grid item xs={12} md={10} lg={8}>
                            <Paper variant="outlined" style={{padding: '24px'}}>
                                <CardElement id={"card-element"} />
                            </Paper>
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions style={{padding: '24px'}}>
                    <Button text onClick={onClose}>{t("Cancel")}</Button>
                    <Button primary disabled={!stripe || loading} onClick={onAdd}>
                        {loading ?  <CircularProgress sx={{ height: "24px!important", width: "24px!important" }} /> : t("Add_payment_method")}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}