const platinumSubscription = "platinum"

const goldSubscription = 'gold'

const standardSubscription = 'standard'

const freeSubscription = 'free'

const extraGoldUsersSubscription = 'extra_users_gold'

const extraPlatinumUsersSubscription = 'extra_users_platinum'

const extraVCardsSubscription = 'extra_vcards'

const extraVcardsProductSubscription = "extra_vcards_product"

const extraDomainsSubscription = "domains"

const standaloneSubscription = "standalone"

export {
    platinumSubscription,
    goldSubscription,
    extraVCardsSubscription,
    extraGoldUsersSubscription,
    extraPlatinumUsersSubscription,
    freeSubscription,
    standardSubscription,
    standaloneSubscription,
    extraVcardsProductSubscription,
    extraDomainsSubscription
}
