import React, {useState} from "react";
import {
    Accordion, AccordionDetails,
    AccordionSummary,
    Box, ButtonGroup, Dialog, DialogActions,
    DialogContent,
    DialogTitle, Divider,
    Grid,
    Typography,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Collapse, IconButton, CircularProgress
} from '@mui/material';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CreditCardIcon from "@mui/icons-material/CreditCard";
import {useTranslation} from "react-i18next";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
// Unitag UI
import {Button} from '@components';
import CloseIcon from "@mui/icons-material/Close";
import {roundToCent} from "@functions";

export function PaymentDialog(props: any) {

    const {t} = useTranslation(['plans', 'billings', 'common']);

    const {
        open, onClose, onSubscribe,
        alreadyClient,
        collaboratorsCredits, vcardsCredits, qrcodesCredits, domainsCredits,
        chosenPlan,
        user, currency,

        pricePerMonth, pricePerYear,

        handleRecurrenceChange, recurrence,
        loading
    } = props

    const styles = {
        listTopBorder: {
            border: 0,
            borderBottom: '2px solid',
            paddingLeft: '10px',
            margin: '8px 40% 16px 0',
        },
        basketItem: {
            cursor: "pointer",
            transition: "0.25s ease-in-out",
            borderRadius: "8px",
            "&:hover": {
                backgroundColor: "#efefef"
            }
        },
        basketItemIcon: {
            minWidth: '40px'
        },
        basketItemListElement: {
            pl: 2
        },
        dialogActions: {
            pr: 3,
            borderTop: "1px solid grey",
            backgroundColor: "#efefef"
        },
        yearlyDiscount: {color: 'secondary.main'}
    }

    const [expandBasketFeature, setExpandBasketFeature] = useState<string | null>(null)

    const renderIntroAndInfo = () => {
        return (
            <>
                <Grid item md={12}>
                    <Typography variant="body2" textAlign={"justify"}>
                        {t("Select_payment_headline_1")}&nbsp;
                        {t("Select_payment_headline_2")}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Accordion style={{boxShadow: 'none', border: '1px solid #c3c3c3'}}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                            <Typography variant="subtitle1">{t("Select_payment_details")}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body2">{t("Select_payment_details_text")}</Typography>
                        </AccordionDetails>
                    </Accordion>
                </Grid>

                <Grid item xs={12}>
                    <Accordion style={{boxShadow: 'none', border: '1px solid #c3c3c3'}}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                            <Typography variant="subtitle1">{t("taxes_and_vat")}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body2">{t("Taxe_apply_after")}</Typography>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </>
        )
    }

    const renderBasket = () => {

        const handleExpandBasket = (section: any) => {
            if (expandBasketFeature !== section) setExpandBasketFeature(section)
            else setExpandBasketFeature(null)
        }

        const renderPlanUpgrade = () => {

            if (!!user && user.account_type === chosenPlan) return <></>

            return (
                <ListItem>
                    <ListItemIcon style={styles.basketItemIcon}>
                        <CheckCircleIcon />
                    </ListItemIcon>
                    {
                        (!!user && user.account_type !== "free") ?
                            <ListItemText disableTypography secondary={`${t("billings:upgrade")} ${user.account_type} → ${chosenPlan}`} />
                            : <ListItemText disableTypography secondary={t("7_day_trial")} />
                    }
                </ListItem>
            )
        }

        const renderPlanFeatures = () => {

            if (!chosenPlan || (alreadyClient && !!user && chosenPlan === user.account_type)) return <></>

            const plans = [
                {
                    type: "standard",
                    label: "billings:standard_features",
                    features: [
                        "standard_feature_1",
                        "standard_feature_2",
                        "standard_feature_3",
                        "standard_feature_4",
                        "standard_feature_5",
                    ]
                },
                {
                    type: "gold",
                    label: "billings:gold_features",
                    features: [
                        "gold_feature_1",
                        "gold_feature_2",
                        "gold_feature_3",
                        "gold_feature_4",
                        "gold_feature_5",
                        "gold_feature_6",
                    ]
                },
                {
                    type: "platinum",
                    label: "billings:platinum_features",
                    features: [
                        "platinum_feature_1",
                        "platinum_feature_2",
                        "platinum_feature_3",
                        "platinum_feature_4",
                        "platinum_feature_5",
                        "platinum_feature_6",
                    ]
                }
            ]

            let selectedPlan = plans.filter((plan: any) => plan.type === chosenPlan)

            return (
                <>
                    {!!selectedPlan && selectedPlan.map((plan: any, index: any) => (
                        <div key={index}>
                            <ListItem onClick={() => handleExpandBasket("plan_features")} sx={styles.basketItem}>
                                <ListItemIcon sx={styles.basketItemIcon}>
                                    <CheckCircleIcon />
                                </ListItemIcon>
                                <ListItemText disableTypography secondary={t(plan.label)} />
                                {expandBasketFeature === "plan_features" ? <ExpandLess/> : <ExpandMore/>}
                            </ListItem>
                            <Collapse in={expandBasketFeature === "plan_features"}>
                                <List disablePadding dense sx={styles.basketItemListElement}>
                                    {
                                        plan.features.map((feature: any, index: any) => (
                                            <ListItem key={index}>
                                                <ListItemText secondary={t(feature)}/>
                                            </ListItem>
                                        ))
                                    }
                                </List>
                            </Collapse>
                        </div>
                    ))}
                </>
            )
        }

        const renderCreditsChosen = () => {

            const modules = [
                {
                    label: "billings:vcards_business",
                    number: vcardsCredits,
                    features: [
                        'billings:Sync_contact_vcards',
                        'billings:Scan_vcards',
                        'billings:Share_vcards',
                        'billings:Analytics_vcards'
                    ]
                },
                {
                    label: "billings:extra_collaborators",
                    number: collaboratorsCredits,
                    features: [
                        'Extra_users',
                        'Permission_users',
                        'Working_users'
                    ]
                },
                {
                    label: "billings:domains",
                    number: domainsCredits,
                    features: [
                        "billings:domains_introduction"
                    ]
                }
            ]

            let selectedModules = modules.filter((module: any) => !!module.number && module.number > 0)

            return (
                <>
                    {!!selectedModules && selectedModules.map((module: any, index: number) => (
                        <div key={index}>
                            <ListItem onClick={() => handleExpandBasket(module.label)} sx={styles.basketItem}>
                                <ListItemIcon sx={styles.basketItemIcon}>
                                    <CheckCircleIcon />
                                </ListItemIcon>
                                <ListItemText disableTypography secondary={t(module.label) + ": " + module.number} />
                                {expandBasketFeature === module.label ? <ExpandLess/> : <ExpandMore/>}
                            </ListItem>
                            <Collapse in={expandBasketFeature === module.label}>
                                <List disablePadding dense sx={styles.basketItemListElement}>
                                    {
                                        module.features.map((feature: any, index: any) => (
                                            <ListItem key={index}>
                                                <ListItemText secondary={t(feature)}/>
                                            </ListItem>
                                        ))
                                    }
                                </List>
                            </Collapse>
                        </div>
                    ))}
                </>
            )
        }

        const renderQRCodesNumber = () => {

            if (!qrcodesCredits || (!!user && user.account_type === chosenPlan)) return <></>

            return (
                <ListItem>
                    <ListItemIcon style={styles.basketItemIcon}>
                        <CheckCircleIcon />
                    </ListItemIcon>
                    <ListItemText disableTypography secondary={t(`billings:${qrcodesCredits}`) + " QR Codes"} />
                </ListItem>
            )
        }

        return (
            <Grid item xs={12} md={6} lg={6}>
                <Typography variant="h6"> {t("Plan_display")} {chosenPlan}</Typography>
                <Grid sx={styles.listTopBorder} />

                <Typography variant="body2">{t("Incl_features")}</Typography>
                <List>
                    {renderPlanUpgrade()}
                    {renderPlanFeatures()}
                    {renderCreditsChosen()}
                    {renderQRCodesNumber()}
                </List>
            </Grid>
        )
    }

    const renderPaymentSummary = () => {

        const renderPaymentFrequency = () => {

            // if (alreadyClient || (!!user && user.account_type !== "free")) return <></>

            return (
                <>
                    <ButtonGroup
                        size="small"
                        variant="contained"
                        disableElevation
                        style={{margin: "8px 0px 8px 0px"}}
                    >
                        <Button
                            primary={recurrence === "monthly"}
                            value="monthly"
                            onClick={handleRecurrenceChange}
                        >
                            {t("Pay_monthly")}
                        </Button>
                        <Button
                            primary={recurrence === "yearly"}
                            value="yearly"
                            onClick={handleRecurrenceChange}
                        >
                            {t("Pay_yearly")}
                        </Button>
                    </ButtonGroup>
                </>
            )
        }

        return (
            <Grid item xs={12} md={6}>
                <Typography variant="h6">{t("Summary")}</Typography>
                <Grid sx={styles.listTopBorder} />

                {renderPaymentFrequency()}

                <Grid container justifyContent="space-between" style={{marginTop: '16px'}} columnSpacing={1}>
                    <Grid item xs={9}>
                        {
                            alreadyClient ?
                                <Typography variant="body2">
                                    {t("Pr_par_Added")} {t(`${recurrence}`)} {t("Pr_par_topup")}
                                </Typography>
                                : <Typography variant="body2">
                                    {t("Pr_par_NEW")} {t(`${recurrence}`)} {t("Pr_par_TOTAL")}
                                </Typography>
                        }

                        {recurrence === "yearly" &&
                            <Typography variant="body2" sx={styles.yearlyDiscount}>
                                {t("Pricing_10_reduction")}
                            </Typography>
                        }
                    </Grid>

                    <Grid item xs={3} style={{textAlign: 'end'}}>
                        {recurrence === "monthly" ?
                            <Typography><b>{roundToCent(pricePerMonth)}</b></Typography>
                            : <Typography><b>{roundToCent(pricePerYear)}</b></Typography>
                        }
                    </Grid>
                </Grid>

                <Divider style={{marginTop: '16px', marginBottom: '16px'}}/>

                <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item><Typography variant="body1">{t("Pricing_due_today")}</Typography></Grid>
                    <Grid item><Typography fontWeight={700}>{t("Free")} - 0 {currency}</Typography></Grid>
                </Grid>
            </Grid>
        )
    }

    return (
        <Dialog scroll={"paper"} {...{open, onClose}}>
            <DialogTitle>
                <Box display="flex" alignItems="space-between">
                    <Box flexGrow={1}>{t("Select_payment_method")}</Box>
                    <IconButton onClick={onClose}><CloseIcon/></IconButton>
                </Box>
            </DialogTitle>

            <DialogContent className={"fancyScroll"}>
                <Grid container rowSpacing={3} columnSpacing={2}>
                    {renderIntroAndInfo()}
                    {renderBasket()}
                    {renderPaymentSummary()}
                </Grid>
            </DialogContent>

            <DialogActions sx={styles.dialogActions}>
                <Button onClick={onClose} disabled={loading} text>{t("Cancel")}</Button>
                &nbsp;&nbsp;
                <Button
                    primary
                    onClick={onSubscribe}
                    startIcon={!alreadyClient ? null : <CreditCardIcon/>}
                    disabled={loading}
                >
                    {loading ? <CircularProgress color={"secondary"} size={25} /> : !alreadyClient ? t("billings:start_free_trial") : t("Credit_card")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
