import React, {useEffect} from "react"
import {Link} from "react-router-dom";
// MUI
import {ButtonBase, CircularProgress, Container, Divider, Grid, Paper, Typography} from "@mui/material";
// Translation
import {useTranslation} from "react-i18next";
import i18next from 'i18next';
// Icons
import VCardsIcon from '@assets/img/icons/vcards-blue.png';
import BatchesIcon from '@assets/img/icons/batches-blue.png';
import PackagingIcon from '@assets/img/icons/packaging-blue.png';
// Compo
import {Button} from "@components";
import {AppHeader} from "@components";
// styles
import {styles} from "./styles"
// context
import {useUser} from "@context";


export default function ELearning() {

    const {t} = useTranslation(["common"])
    const {complexUser, getComplexUser, organisationAsMember} = useUser()

    const [loading, setLoading] = React.useState<boolean>(true);
    const [account, setAccount] = React.useState<string | undefined>("");

    React.useEffect(() => {
        if (!complexUser) getComplexUser()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!!complexUser) {
            setAccount(complexUser.account_type)
            setLoading(false)
        }
    }, [complexUser]);

    const renderAppsList = () => {
        // We need to use a condition to settle link language
        const apps = [
            {
                name: "packaging",
                icon: PackagingIcon,
                link:
                    i18next.language === "fr-FR" ? "https://rise.articulate.com/share/o-Fuplu-bTV4HKdJUEocQIB17rSzp45i"
                    : "https://rise.articulate.com/share/pa9p3S-KhFl07dYsuio2830Hxgh5xLOv",
            },
            { name: "vcards", icon: VCardsIcon, link: "", disabled: true },
            { name: "domains", icon: BatchesIcon, link: "", disabled: true },
            { name: "atlas", icon: BatchesIcon, link: "", disabled: true },
            { name: "organisations", icon: BatchesIcon, link: "", disabled: true },
        ]

        return (
            <>
                <Grid item xs={7} px={5} sx={styles.gridItem}>
                    <Typography variant={"body1"} fontSize={19}>
                        {t("elearning_intro")}
                    </Typography>

                    <Grid container spacing={3} my={3} justifyContent={"center"}>
                        {
                            apps.map((app: any, index: number) => {
                                const {name, icon, link} = app;
                                return (
                                    <Grid item xs={9} sm={4} md={3} sx={styles.gridItem} key={index}>
                                        <ButtonBase component={"a"} sx={app.disabled ? styles.disabledButton : styles.buttonBase} rel={"noopener noreferrer"} href={link} disabled={app.disabled}>
                                            <Paper variant="outlined" sx={styles.buttonPaper}>
                                                <img src={icon} width={40} height={40} alt={`${name} logo`} />
                                                <Typography variant={"h6"} component="h4" sx={styles.buttonTitle}>{t(name)}</Typography>
                                            </Paper>
                                        </ButtonBase>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                </Grid>

            </>
        )
    }

    const renderPreview = () => {
        return (
            <Grid item xs={7} sx={styles.preview}>
                <Typography align={"center"} fontSize={26} fontWeight={700}>
                    {t("elearning_preview_message")}
                </Typography>
                <Button primary large sx={styles.button} component={Link} to={"/plans"}>
                    {t("Upgrade")}
                </Button>
            </Grid>
        )
    }

    const renderContent = () => {

        if (loading) {
            return (
                <Grid item my={10}>
                    <CircularProgress size={60} />
                </Grid>
            )
        }

        if (account === "platinum" || (!!organisationAsMember && organisationAsMember.org_account_type === "platinum")) {
            return renderAppsList()
        } else {
            return renderPreview()
        }
    }

    return (
        <>
            <Container maxWidth={false}>
                <Container maxWidth={"lg"} sx={styles.container}>

                    <AppHeader
                        paths={[{ name: "Unitag_console", path: "/" }]}
                        positionName={t("elearning")}
                        title={t("unitag_elearning")}
                        subTitle={t("elearning_subtitle")}
                    />

                    <Divider sx={styles.divider} />

                    <Grid container spacing={4} justifyContent="center" direction="row" columns={9}>
                        {renderContent()}
                    </Grid>
                </Container>
            </Container>
        </>
    )
}