export const styles = {
    emptyNotifications: {
        width: "100%",
        height: "100px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignContent: "center"
    },
    menuWrapper: {
        p: 0
    },
    menuPaper: {
        width: "350px",
        maxHeight: "550px",
        p: 0
    },
    menuHeader: {
        width: "100%",
        p: "8px 16px",
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignContent: "center"
    },
    menuHeaderTitle: {
        pt: "2px"
    }
}