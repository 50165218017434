import React, {useEffect} from 'react';
import {Link} from "react-router-dom";
import {Container, Typography, Divider, Grid, Paper, ButtonBase} from '@mui/material';
// SVG ICONS
import DynamicQRIcon from '@assets/img/icons/dynamic_qr-blue.png';
import VCardsIcon from '@assets/img/icons/vcards-blue.png';
import BatchesIcon from '@assets/img/icons/batches-blue.png';
import PackagingIcon from '@assets/img/icons/packaging-blue.png';
import AnalyticsIcon from '@assets/img/icons/analytics-blue.png';
import CampaignsIcon from '@assets/img/icons/campaigns.png';
// TRANSLATION
import {useTranslation} from "react-i18next";
// COMPONENTS
import {AppHeader} from "@components";
import {useUser} from "@context";
import WinelabelIcon from "@assets/img/icons/wine-bottle.png";


// For mapping purposes
// name: Add lowercase name (will be used as a literal translation from commonLNG.json)
// icon: Add direct import as done upwards
// link (optional): Add a custom link ONLY IF route differs from "/applications/{name}"
// The secondary text is directly translated from commonLNG.json following the key "App_list_{name}_intro"

// { name: "vcards", icon: VCardsIcon, link: "/applications/vcard/control_panel" },

export default function AppList() {

    let apps = [
        { name: "analytics", icon: AnalyticsIcon },
        { name: "atlas", icon: BatchesIcon },
        { name: "campaigns", icon: CampaignsIcon },
        { name: "packaging", icon: PackagingIcon },
        { name: "qrcodes", icon: DynamicQRIcon, link: "/applications/qrcodes/list" },
        { name: "vcardpro", icon: VCardsIcon, link: "/applications/vcardpro" }
    ]

    const {t} = useTranslation(['common']);
    const {credits} = useUser()

    useEffect(() => {
        if (credits) {
            let winelabel = credits.find((cred: any) => cred.service_name === "winelabel")
            if (!!winelabel && (winelabel.credits_left > 0 || winelabel.credits_used > 0 || winelabel.credits_bought > 0)) {
                apps.push({ name: "Wine Label", icon: WinelabelIcon, link: "/applications/winelabel/control_panel"})
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [credits])

    const styles = {
        paper: {
            p: 3,
            width: "100%",
            height: "100%",
            alignItems: "center",
            transition: "0.2s ease-in-out",
            borderRadius: 5,
            "&:hover": {
                borderColor: 'secondary.main',
                backgroundColor: '#f8f8f8'
            },
            "&:hover svg": {
                fill: 'secondary.main'
            }
        },
        appTitle: { fontWeight: 700, my: 1 },
        gridItem: { textAlign: 'center' },
        buttonBase: { height: '100%', width: '100%' },
    };

    return (
        <Container maxWidth="xl" sx={{ py: 4, px: 2, minHeight: '65vh' }}>

            <Grid container justifyContent={"center"}>
                <Grid item xs={10}>
                    <AppHeader
                        paths={[{ name: "Unitag_console", path: "/" }]}
                        positionName={t("Applications")}
                        title={"Applications"}
                        subTitle={t("Applications_list_header")}
                    />

                    <Divider style={{margin: "0px 0px 32px 0px"}}/>

                    <Grid container spacing={4} justifyContent="center" direction="row">
                        {
                            apps.map((app: any, index: number) => {
                                const {name, icon, link} = app;
                                return (
                                    <Grid item xs={10} sm={4} md={3} sx={styles.gridItem} key={index}>
                                        <ButtonBase component={Link} sx={styles.buttonBase} to={link ? link : `/applications/${name}`}>
                                            <Paper variant="outlined" sx={styles.paper}>
                                                <img src={icon} width={40} height={40} alt={`${name} logo`} />
                                                <Typography variant={"h6"} component="h4" sx={styles.appTitle}>{t(name)}</Typography>
                                                {app.name !== "winelabel" && <Typography variant={"body2"} component="p">{t(`App_list_${name}_intro`)}</Typography>}
                                            </Paper>
                                        </ButtonBase>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
}
