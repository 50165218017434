// Fetch builder helper
// Source: https://www.carlrippon.com/fetch-with-async-await-and-typescript/

import {Auth} from 'aws-amplify';

interface HttpResponse<T> extends Response {
    parsedBody?: T;
    rawBlob?: Blob;
}

async function httpAPI<T>(request: RequestInfo): Promise<HttpResponse<T>> {

    const response: HttpResponse<T> = await fetch(request, {
        credentials: 'include'
    });

    try {
        response.parsedBody = await response.json();
    } catch (e) {
        throw new Error(response.statusText);
    }
    return response;
}

async function httpBlob<T>(request: RequestInfo): Promise<HttpResponse<T>> {

    const response: HttpResponse<T> = await fetch(request);

    try {
        response.rawBlob = await response.blob()
    } catch (e) {
        throw new Error(response.statusText);
    }
    return response;
}

export async function APIGetBlob<T>(
    path: string,
    args = {
        method: 'get',
    }
): Promise<HttpResponse<T>> {

    const sess = await Auth.currentSession();

    const req: RequestInit = {
        method: args.method,
        headers: [
            ['Authorization', sess.getIdToken().getJwtToken()],
        ]
    }

    return await httpBlob<T>(new Request(path, req));
}

export async function APIGet<T>(
    path: string,
    args = {
        method: 'get',
    }
): Promise<HttpResponse<T>> {

    const sess = await Auth.currentSession();

    const req: RequestInit = {
        method: args.method,
        headers: [
            ['Authorization', sess.getIdToken().getJwtToken()],
        ]
    }

    return await httpAPI<T>(new Request(path, req));
}

export async function APIPostBlob<T>(
    path: string,
    body: any,
    args = {
        method: 'post',
        body: JSON.stringify(body),
    }
): Promise<HttpResponse<T>> {

    const sess = await Auth.currentSession();

    const req: RequestInit = {
        method: args.method,
        body: args.body,
        headers: [
            ['Authorization', sess.getIdToken().getJwtToken()],
        ]
    }

    return await httpBlob<T>(new Request(path, req));
}

export async function APIPostFileBlob<T>(
    path: string,
    file: any,
    args = {
        method: 'post',
    }
): Promise<HttpResponse<T>> {

    const sess = await Auth.currentSession();

    const data = new FormData();
    data.append('File', file);

    const req: RequestInit = {
        method: args.method,
        body: data,
        headers: [
            ['Authorization', sess.getIdToken().getJwtToken()],
        ]
    }

    return await httpBlob<T>(new Request(path, req));
}


export async function APIPostFile<T>(
    path: string,
    file: any,
    args = {
        method: 'post',
    }
): Promise<HttpResponse<T>> {

    const sess = await Auth.currentSession();

    const data = new FormData();
    data.append('File', file);

    const req: RequestInit = {
        method: args.method,
        body: data,
        headers: [
            ['Authorization', sess.getIdToken().getJwtToken()],
        ]
    }

    return await httpAPI<T>(new Request(path, req));
}

export async function APIPost<T>(
    path: string,
    body: any,
    args = {
        method: 'post',
        body: JSON.stringify(body),
    }
): Promise<HttpResponse<T>> {

    const sess = await Auth.currentSession();

    const req: RequestInit = {
        method: args.method,
        body: args.body,
        headers: [
            ['Authorization', sess.getIdToken().getJwtToken()],
        ]
    }
    return await httpAPI<T>(new Request(path, req));
}

export async function APIPut<T>(
    path: string,
    body: any,
    args: RequestInit = {
        method: 'put',
        body: JSON.stringify(body),
    }
): Promise<HttpResponse<T>> {

    const sess = await Auth.currentSession();

    const req: RequestInit = {
        method: args.method,
        body: args.body,
        headers: [
            ['Authorization', sess.getIdToken().getJwtToken()],
        ]
    }

    return await httpAPI<T>(new Request(path, req));
}

export async function APIDelete<T>(
    path: string,
    body: any,
    args: RequestInit = {
        method: 'delete',
        body: JSON.stringify(body),
    }
): Promise<HttpResponse<T>> {

    const sess = await Auth.currentSession();

    const req: RequestInit = {
        method: args.method,
        body: args.body,
        headers: [
            ['Authorization', sess.getIdToken().getJwtToken()],
        ]
    }

    return await httpAPI<T>(new Request(path, req));
}
