import i18n from 'i18next'
// import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import {initReactI18next} from 'react-i18next'

// TRANSLATION FILES (FR)
import analyticsFR from "./locales/fr/analyticsFR.json"
import atlasFR from "./locales/fr/atlasFR.json"
import campaignsFR from "./locales/fr/campaignsFR.json"
import commonFR from "./locales/fr/commonFR.json"
import homeDashboardFR from "./locales/fr/homeDashboardFR.json"
import landingFR from "./locales/fr/landingFR.json"
import navigationFR from "./locales/fr/navigationFR.json"
import packagingFR from "./locales/fr/packagingFR.json"
import plansFR from "./locales/fr/plansFR.json"
import qrcodesFR from "./locales/fr/qrcodesFR.json"
import settingsFR from "./locales/fr/settingsFR.json"
import billingFR from "./locales/fr/billingFR.json"
import termsFR from "./locales/fr/termsFR.json"
import privacyFR from "./locales/fr/privacyFR.json"
import mapFR from "./locales/fr/mapFR.json"
import notificationsFR from "./locales/fr/notificationsFR.json"
import buyModulesFR from "./locales/fr/buyModulesFR.json"
import winelabelFR from "./locales/fr/winelabel.json"
import generatorFR from "./locales/fr/generatorFR.json"
import toursFR from "./locales/fr/tours.json"

// TRANSLATION FILES (EN)
import analyticsEN from "./locales/en/analyticsEN.json"
import atlasEN from "./locales/en/atlasEN.json"
import campaignsEN from "./locales/en/campaignsEN.json"
import commonEN from "./locales/en/commonEN.json"
import homeDashboardEN from "./locales/en/homeDashboardEN.json"
import landingEN from "./locales/en/landingEN.json"
import navigationEN from "./locales/en/navigationEN.json"
import packagingEN from "./locales/en/packagingEN.json"
import plansEN from "./locales/en/plansEN.json"
import qrcodesEN from "./locales/en/qrcodesEN.json"
import settingsEN from "./locales/en/settingsEN.json"
import billingEN from "./locales/en/billingEN.json"
import termsEN from "./locales/en/termsEN.json"
import privacyEN from "./locales/en/privacyEN.json"
import mapEN from "./locales/en/mapEN.json"
import notificationsEN from "./locales/en/notificationsEN.json"
import buyModulesEN from "./locales/en/buyModulesEN.json"
import winelabelEN from "./locales/en/winelabel.json"
import generatorEN from "./locales/en/generatorEN.json"
import toursEN from "./locales/en/tours.json"

// TRANSLATION FILES (ES)
import analyticsES from "./locales/es/analyticsES.json"
import atlasES from "./locales/es/atlasES.json"
import campaignsES from "./locales/es/campaignsES.json"
import commonES from "./locales/es/commonES.json"
import homeDashboardES from "./locales/es/homeDashboardES.json"
import landingES from "./locales/es/landingES.json"
import navigationES from "./locales/es/navigationES.json"
import packagingES from "./locales/es/packagingES.json"
import plansES from "./locales/es/plansES.json"
import qrcodesES from "./locales/es/qrcodesES.json"
import settingsES from "./locales/es/settingsES.json"
import billingES from "./locales/es/billingES.json"
import termsES from "./locales/es/termsES.json"
import privacyES from "./locales/es/privacyES.json"
import mapES from "./locales/es/mapES.json"
import notificationsES from "./locales/es/notificationsES.json"
import buyModulesES from "./locales/es/buyModulesES.json"
import winelabelES from "./locales/es/winelabel.json"
import generatorES from "./locales/es/generatorES.json"
import toursES from "./locales/es/tours.json"

// TRANSLATION FILES (ES)
import analyticsDE from "./locales/de/analyticsDE.json"
import atlasDE from "./locales/de/atlasDE.json"
import campaignsDE from "./locales/de/campaignsDE.json"
import commonDE from "./locales/de/commonDE.json"
import homeDashboardDE from "./locales/de/homeDashboardDE.json"
import landingDE from "./locales/de/landingDE.json"
import navigationDE from "./locales/de/navigationDE.json"
import packagingDE from "./locales/de/packagingDE.json"
import plansDE from "./locales/de/plansDE.json"
import qrcodesDE from "./locales/de/qrcodesDE.json"
import settingsDE from "./locales/de/settingsDE.json"
import billingDE from "./locales/de/billingDE.json"
import termsDE from "./locales/de/termsDE.json"
import privacyDE from "./locales/de/privacyDE.json"
import mapDE from "./locales/de/mapDE.json"
import notificationsDE from "./locales/de/notificationsDE.json"
import buyModulesDE from "./locales/de/buyModulesDE.json"
import winelabelDE from "./locales/de/winelabel.json"
import generatorDE from "./locales/de/generatorDE.json"
import toursDE from "./locales/de/tours.json"


// Namespaces linking with imported JSON files
const resources = {
    en: {
        analytics: analyticsEN,
        atlas: atlasEN,
        billings: billingEN,
        buyModules: buyModulesEN,
        campaigns: campaignsEN,
        common: commonEN,
        home: homeDashboardEN,
        landing: landingEN,
        navigation: navigationEN,
        packaging: packagingEN,
        plans: plansEN,
        qrcodes: qrcodesEN,
        settings: settingsEN,
        notifications: notificationsEN,
        winelabel: winelabelEN,

        terms: termsEN,
        privacy: privacyEN,
        map: mapEN,
        tours: toursEN,

        generator: generatorEN
    },
    fr: {
        analytics: analyticsFR,
        atlas: atlasFR,
        billings: billingFR,
        buyModules: buyModulesFR,
        campaigns: campaignsFR,
        common: commonFR,
        home: homeDashboardFR,
        landing: landingFR,
        navigation: navigationFR,
        packaging: packagingFR,
        plans: plansFR,
        qrcodes: qrcodesFR,
        settings: settingsFR,
        notifications: notificationsFR,
        winelabel: winelabelFR,

        terms: termsFR,
        privacy: privacyFR,
        map: mapFR,
        tours: toursFR,

        generator: generatorFR
    },
    es: {
        analytics: analyticsES,
        atlas: atlasES,
        billings: billingES,
        buyModules: buyModulesES,
        campaigns: campaignsES,
        common: commonES,
        home: homeDashboardES,
        landing: landingES,
        navigation: navigationES,
        packaging: packagingES,
        plans: plansES,
        qrcodes: qrcodesES,
        settings: settingsES,
        notifications: notificationsES,
        winelabel: winelabelES,

        terms: termsES,
        privacy: privacyES,
        map: mapES,
        tours: toursES,

        generator: generatorES
    },

    de: {
        analytics: analyticsDE,
        atlas: atlasDE,
        billings: billingDE,
        buyModules: buyModulesDE,
        campaigns: campaignsDE,
        common: commonDE,
        home: homeDashboardDE,
        landing: landingDE,
        navigation: navigationDE,
        packaging: packagingDE,
        plans: plansDE,
        qrcodes: qrcodesDE,
        settings: settingsDE,
        notifications: notificationsDE,
        winelabel: winelabelDE,

        terms: termsDE,
        privacy: privacyDE,
        map: mapDE,
        tours: toursDE,

        generator: generatorDE
    }
}


i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: {
            'en-EN': ['en'],
            'fr-FR': ['en'],
            'es-ES': ['en'],
            'de-DE': ['en']
        },
        debug: false,
        interpolation: {
            // may not be useful with React as it escapes by default
            escapeValue: false,
        },
        resources,
        defaultNS: 'common',
        fallbackNS: 'common',
    }).then();
