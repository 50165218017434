import React, { useState, useEffect } from "react";


export default function DateDisplay(props: any) {

    const { originTime } = props;

    const [date, setDate] = useState<string>("");
    // const [time, setTime] = useState<string>("");

    useEffect(() => {
        const info = originTime.split("T");
        const date_m = info[0];
        // const time_m = info[1].split(":");
        setDate(new Date(date_m).toLocaleDateString())
        // setTime(time_m[0]+":"+time_m[1])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <span style={{ fontSize: 14 }}>{date}</span>
}