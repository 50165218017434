import React from 'react';
import { Container, Typography, Button, Divider } from '@mui/material';
// TRANSLATION
import {useTranslation} from "react-i18next";

export default function NotFound() {
    const { t } = useTranslation(['common']);

    return (
        <Container maxWidth="md" style={{marginTop: '55px', paddingBottom: '55px', minHeight: '65vh'}}>
            <Typography variant="h3">{t("Error_404")}</Typography>
            <Typography variant="h5">{t("Page_not_found")}</Typography>

            <Divider style={{marginTop: '50px', marginBottom: '50px' }}/>

            <Typography variant="body1">
                {t("404_text1")}&nbsp;
                    {t("404_text2")}
                {t("404_text3")}
            </Typography>
            <Button variant="contained" color="primary" component="a" href="https://console.unitag.io" style={{ marginTop: '55px' }}>{t("Back")}</Button>
        </Container>
    );
}
