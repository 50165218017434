import React from 'react'
import {Button, Menu} from "@mui/material"
import {ChromePicker, ColorResult} from "react-color"

const GeneratorColorPicker = (props: any) =>  {

    const { label, initialColor, disabled, onChangeComplete } = props;

    const [color, setColor] = React.useState<any>(initialColor);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const menuOpen = Boolean(anchorEl);

    const styles = {
        colorPickerMenu: {
            backgroundColor: "transparent",
            boxShadow: "none",
        }
    }

    // Used to make PrimaryColor match in both Accordion and avoiding false color displays
    React.useEffect(() => {
        setColor(initialColor);
        return;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.initialColor])

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        onChangeComplete(color);
    };

    return (
        <>
            <Button
                fullWidth
                variant="contained"
                size="medium"
                disableElevation
                onClick={handleClick}
                disabled={disabled}
                sx={{
                    backgroundColor: disabled ? "#c8c8c8" : color,
                    color: color === "#ffffff" ? "#000000" : "#ffffff",
                    border: "1px solid",
                    borderColor: color === "#ffffff" ? "#000000" : "transparent",
                    transition: "0.15s ease-in-out",
                    "&:hover": {
                        backgroundColor: disabled ? "#c8c8c8" : color,
                        filter: "brightness(0.95)"
                    }
                }}
            >
                {color ? color : label}
            </Button>

            <Menu
                anchorEl={anchorEl}
                open={menuOpen}
                onClose={handleClose}
                slotProps={{ paper: { sx: styles.colorPickerMenu } }}
            >
                <ChromePicker
                    disableAlpha
                    color={color}
                    onChange={(color: ColorResult) => setColor(color.hex)}
                />
            </Menu>
        </>
    )
}

export default GeneratorColorPicker
