import React, {useState, useEffect} from 'react';
import {Link} from "react-router-dom";
// MUI
import {
    Box,
    Breadcrumbs,
    Button,
    Container,
    Divider,
    Grid,
    Link as MaterialLink,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Paper,
    Skeleton,
    Typography
} from '@mui/material';
// MUI ICONS
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// API
import {APIGet} from "@api";
// Interfaces
import {GetPlansResponse} from "@interfaces";
// TRANSLATION
import {useTranslation} from "react-i18next";
// Tracking and analytics
import mixpanel from "mixpanel-browser";
// Context
import {useUser} from "@context";
// Components
import PlansDetailedFeatures from "@/Views/Plans/PlansDetailedFeatures";
import {
    freeSubscription,
    goldSubscription,
    platinumSubscription,
    standaloneSubscription,
    standardSubscription
} from "@/constants/plans_en";
import {useSnackbar} from "@hooks";

mixpanel.init('e54d191765b082621fa9ebf9da1867e2', {debug: false});

const {REACT_APP_API_URL} = process.env;

function PlanPaper(props: any) {

    const { accountType, loading, styles, t } = props
    const {
        type,
        label, intro, featuresIncludedText,
        features,
        price, action, disabled,
        color
    } = props.plan

    const planStyle = {
        background:  { color: 'white', backgroundColor: color },
        checkmark: { color: color }
    }

    const isThereABetterOption = () => {
        if (accountType === standaloneSubscription || (accountType === standardSubscription && (type === goldSubscription || type === platinumSubscription))) return true
        return accountType === goldSubscription && type === platinumSubscription;
    }

    return (
        <>
            <Grid item xl={3} lg={3} md={5} sm={8} xs={10} sx={styles.sectionWrapper}>
                <Paper variant="outlined" sx={styles.paperWrapper}>

                    <Paper elevation={0} sx={{...planStyle.background, ...styles.paper}}>
                        <Typography variant="h4">{label}</Typography>
                    </Paper>

                    <Box sx={styles.featuresBox}>

                        <Box style={{height: 60}}>
                            <Typography variant="body2" align="center">{intro}</Typography>
                        </Box>

                        <Divider sx={styles.introDivider}/>

                        <Typography variant="subtitle2">{featuresIncludedText}</Typography>

                        <Box>
                            <Box sx={styles.featuresListBox} style={{ minHeight: 450 }}>
                                <List>

                                    {
                                        features.base && features.base.map((feature: string, index: number) => (
                                            <ListItem sx={styles.featuresListItem} key={index}>
                                                <ListItemIcon><CheckCircleIcon sx={planStyle.checkmark}/></ListItemIcon>
                                                <ListItemText secondary={feature}/>
                                            </ListItem>
                                        ))
                                    }
                                </List>
                            </Box>

                            <Typography variant="subtitle2">{t("analytics")}</Typography>
                            <Box sx={styles.featuresAnalyticsBox}>
                                <List>
                                    {
                                        features.analytics && features.analytics.map((feature: string, index: number) => (
                                            <ListItem key={index}>
                                                <ListItemIcon><CheckCircleIcon sx={planStyle.checkmark}/></ListItemIcon>
                                                <ListItemText secondary={feature}/>
                                            </ListItem>
                                        ))
                                    }
                                </List>
                            </Box>

                            <Typography variant="subtitle2">{t("storage")}</Typography>
                            <Box sx={styles.featuresAnalyticsBox}>
                                <List>
                                    {
                                        features.storage && features.storage.map((feature: string, index: number) => (
                                            <ListItem key={index}>
                                                <ListItemIcon><CheckCircleIcon sx={planStyle.checkmark}/></ListItemIcon>
                                                <ListItemText secondary={feature}/>
                                            </ListItem>
                                        ))
                                    }
                                </List>
                            </Box>

                            <Typography variant="subtitle2">{t("support")}</Typography>
                            <Box sx={styles.featuresSupportBox}>
                                <List>
                                    {
                                        features.support && features.support.map((feature: string, index: number) => (
                                            <ListItem key={index}>
                                                <ListItemIcon><CheckCircleIcon sx={planStyle.checkmark}/></ListItemIcon>
                                                <ListItemText secondary={feature}/>
                                            </ListItem>
                                        ))
                                    }
                                </List>
                            </Box>
                        </Box>

                        <Divider/>

                        <Box justifyContent="center" textAlign="center" style={{marginTop: '24px', minHeight: '165px'}}>

                            <Typography variant="body1" style={{color: '#c3c3c3'}}>{t("As_low_as")}</Typography>

                            <Typography variant="h5" component="p">
                                {price}{t("Per_month")}
                            </Typography>

                            <Typography variant={"body1"} style={{fontSize: '13px'}}>
                                {t("without_taxes")}
                            </Typography>

                            <br/>

                            {
                                accountType === type ?
                                    <Button
                                        value="standard"
                                        variant="contained"
                                        size="large"
                                        color="primary"
                                        disableElevation
                                        disabled
                                    >
                                        {t("current_plan")}
                                    </Button>
                                : accountType !== label && accountType !== "free" && !isThereABetterOption() ?
                                    <Button
                                        value="standard"
                                        variant="contained"
                                        size="large"
                                        color="primary"
                                        disableElevation
                                        disabled
                                    >
                                        -
                                    </Button>
                                :
                                    <Grid>
                                        <Link to={loading ? "#" : action}>
                                            <Button
                                                value="standard"
                                                variant="contained"
                                                size="large"
                                                color="primary"
                                                disableElevation
                                                disabled={disabled}
                                                id={"try-standard"}
                                            >
                                                {
                                                    loading ?
                                                        <Skeleton variant="circular" width={'30px'} height={'30px'}/>
                                                        : t("Try_free")
                                                }
                                            </Button>
                                        </Link>
                                    </Grid>
                            }
                        </Box>
                    </Box>

                </Paper>
            </Grid>
        </>
    )
}

export default function Plans() {

    const {credits, getCredits, user, getUser} = useUser()
    const {handleError} = useSnackbar()
    const {t} = useTranslation(['plans', 'common']);

    const [accountType, setAccountType] = useState<"free" | "standalone" | "standard" | "gold" | "platinum">("free")

    const [currency, setCurrency] = useState<string>("$");
    const [standardPrice, setStandardPrice] = useState<string>('');
    const [goldPrice, setGoldPrice] = useState<string>('');
    const [platinumPrice, setPlatinumPrice] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    const styles = {
        background: {
            paddingBottom: '40px',
            paddingTop: "32px"
        },
        paperWrapper: {borderRadius: '10px', zIndex: 5},
        paper: {
            borderTopRightRadius: '8px',
            borderTopLeftRadius: '8px',
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            padding: '8px',
            textAlign: 'center',
            marginBottom: '8px',
            zIndex: 6,
        },
        introDivider: { my: 2 },
        featuresBox: { padding: '16px', marginBottom: '24px', minHeight: '965px' },
        featuresListBox: { minHeight: '350px' },
        featuresSupportBox: { minHeight: '105px' },
        featuresAnalyticsBox: { minHeight: '75px' },
        featuresListItem: { minHeight: '60px' },
    };

    // plan check function
    const isPlanDisabled = (plan: any) => {
        if (!credits) return true
        else {
            let qrCodesCredits = credits.filter((service: any) => service.service_name === "qrcodes")
            let qrCodesCreditsUsed = qrCodesCredits[0].credits_used

            switch (plan) {
                case "standard":
                    return qrCodesCreditsUsed > 100;
                case "gold":
                    return qrCodesCreditsUsed > 500;
                default: return false
            }
        }
    }

    // plans content
    // TODO: create component for plan rendering
    let plans = [
        {
            type: "standard",
            label: t("Standard"),
            intro: t("Standard_headline"),
            featuresIncludedText: "incl.",
            price: standardPrice,
            action: { pathname: '/alacarte', state: { fromPlan: 'standard' } },
            disabled: isPlanDisabled("standard"),
            color: '#b4c74a',
            features:  {
                base: [
                    t("standard_feature_1"),
                    t("standard_feature_2"),
                    t("standard_feature_3"),
                    t("standard_feature_4"),
                    t("standard_feature_5"),
                ],
                analytics: [t("standard_analytics_feature")],
                storage: [t("standard_stockage_feature")],
                support: [
                    t("standard_support_feature_1"),
                    t("standard_support_feature_2")
                ]
            }
        },
        {
            type: "gold",
            label: t("Gold"),
            intro: t("Gold_headline"),
            featuresIncludedText: t("Incl_features_gold"),
            price: goldPrice,
            action: {pathname: '/alacarte', state: {fromPlan: 'gold'}},
            disabled: isPlanDisabled("gold"),
            color: '#e39d00',
            features: {
                base: [
                    t("gold_feature_1"),
                    t("gold_feature_2"),
                    t("gold_feature_3"),
                    t("gold_feature_4"),
                    t("gold_feature_5"),
                    t("gold_feature_6")
                ],
                analytics: [t("gold_analytics_feature")],
                storage: [t("gold_stockage_feature")],
                support: [
                    t("gold_support_feature_1"),
                    t("gold_support_feature_2")
                ]
            },
        },
        {
            type: "platinum",
            label: t("Platinum"),
            intro: t("Platinum_headline"),
            featuresIncludedText: t("Incl_features_platinum"),
            price: platinumPrice,
            action: {pathname: '/alacarte', state: {fromPlan: 'platinum'}},
            disabled: isPlanDisabled("platinum"),
            color: '#6192bd',
            features: {
                base: [
                    t("platinum_feature_1"),
                    t("platinum_feature_2"),
                    t("platinum_feature_3"),
                    t("platinum_feature_4"),
                    t("platinum_feature_5"),
                    t("platinum_feature_6")
                ],
                analytics: [t("platinum_analytics_feature")],
                storage: [t("platinum_stockage_feature")],
                support: [
                    t("platinum_support_feature_1"),
                    t("platinum_support_feature_2")
                ]
            },
        },
    ]

    // Check plans & currencies
    useEffect(() => {
        setLoading(true);

        // get credits to check for actually bought QRCodes
        getCredits()
        if (!user) getUser()

        APIGet<GetPlansResponse>(REACT_APP_API_URL + "/billing/plans").then((data) => {
            if (!!data.parsedBody && data.ok) {

                let mCurrency: string;
                let mIsCurrencySuffix: boolean = false;

                switch (data.parsedBody.plans[0].currency) {
                    case "eur": mCurrency = "€"; mIsCurrencySuffix = true; break;
                    case "gbp": mCurrency = "£"; break;
                    default: mCurrency = "$";
                }

                setCurrency(mCurrency);

                for (const p of data.parsedBody.plans) {

                    let price = '';

                    if (mIsCurrencySuffix) price = p.monthly + mCurrency
                    else price = mCurrency + p.monthly;


                    switch (p.name) {
                        case "standard": setStandardPrice(price); break;
                        case "gold": setGoldPrice(price); break;
                        case "platinum": setPlatinumPrice(price); break;
                    }
                }
            } else {
                handleError(t("error_get_plans"))
            }
        }).catch(() => handleError(t("error_get_plans"))
        ).finally(() => setLoading(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Check account type
    useEffect(() => {
        if (!!user) {
            switch (user.account_type) {
                case "free": break;
                case "standard": setAccountType("standard"); break;
                case "gold": setAccountType("gold"); break;
                case "platinum": setAccountType("platinum")
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])

    const renderHeader = () => {
        return (
            <>
                <Grid container justifyContent="center">
                    {/* BREADCRUMBS */}
                    <Grid item xs={12}>
                        <Breadcrumbs aria-label="breadcrumb">
                            <MaterialLink className={"link"} color="inherit" component={Link} to="/">
                                {t("Unitag_console")}
                            </MaterialLink>
                            <Typography color="textPrimary">
                                {t("Plans")}
                            </Typography>
                        </Breadcrumbs>
                    </Grid>

                    {/* TITLE */}
                    <Grid item xs={12} sm={8} md={6}>
                        <Box style={{margin: '24px 0px 24px 0px', textAlign: 'center'}}>
                            <Typography variant="h2" component="h1">{t("Unitag_pricing")}</Typography>
                            <br/>
                            <Typography variant="body2">{t("Pricing_headline")}</Typography>
                            <br/>
                        </Box>
                    </Grid>
                </Grid>
                <Divider />
            </>
        )
    }

    const renderPlansList = () => {

        const renderStandaloneOption = () => {
            if (accountType !== freeSubscription && accountType !== standaloneSubscription) return <></>

            return (
                <>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={9}>
                        <Paper variant={"outlined"} sx={{ p: 2 }}>
                            <Grid container justifyContent={"center"} spacing={2}>
                                <Grid item xs={12} md={9}>
                                    <Typography variant={"body1"} sx={{ mt: 1 }}>
                                        {t("i_want_standalone")}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={3} textAlign={"right"}>
                                    <Link to={{ pathname: '/alacarte', state: { fromPlan: 'standalone' } }}>
                                        <Button variant={"contained"} disableElevation>
                                            {t("continue_with_standalone")}
                                        </Button>
                                    </Link>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={1}></Grid>

                </>
            )
        }

        const footnotes = [
            t("footnote_1"),
            t("footnote_2"),
            t("footnote_3")
        ]

        return (
            <>
                <Grid container spacing={5} justifyContent="center" sx={{ my: 4 }}>

                    {renderStandaloneOption()}

                    {
                        plans && plans.map((plan: any, index: number) => {
                            return (<PlanPaper {...{plan, accountType, loading, styles, t}} key={index} />)
                        })
                    }

                    <Grid item xs={9}>
                        {footnotes.map((footnote: any, index: any) => {
                            return (
                                <Typography key={index} variant={"body2"} paragraph sx={{ mb: 0 }}>
                                    {footnote}
                                </Typography>
                            )
                        })}
                    </Grid>
                </Grid>
            </>
        )
    }

    return (
        <Container maxWidth={false} sx={styles.background}>
            <Container maxWidth={'xl'}>
                {/* HEADER */}
                {renderHeader()}
                {/* PLANS */}
                {renderPlansList()}
                {/* APPS FEATURES PER PLAN */}
                <Grid container justifyContent={"center"}>
                    <Grid item xs={12} md={9}>
                        <PlansDetailedFeatures {...{currency, t}} />
                    </Grid>
                </Grid>
            </Container>
        </Container>
    );
}
