export const styles = {
    dialogPadding: {
        p: 2
    },
    formSpacing: {
        mt: 2
    },
    divider: {
        my: 2
    },
    errorPaper: {
        backgroundColor: "#ef5350",
        borderColor: "#c62828",
        p: 2,
        mt: 1
    },
    rolePaperWrapper: {
        display: "flex",
        flexDirection: "column",
    },
    rolePaperButtonWrapper: {
        mx: { xs: 0, md: 5 },
        my: 1,
    },
    rolePaper: {
        width: "100%",
        p: 2,
    },
    selectedRolePaper: {
        border: "2px solid",
        width: "100%",
        p: 2,
        borderColor: "primary.main",
        backgroundColor: "rgba(0,0,0,0.03)"
    }
}
