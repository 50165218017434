import React from 'react'
import { Link } from 'react-router-dom'
import { Container, Typography, Paper, Grid, Button, Divider  } from '@mui/material';

import { ChevronLeft } from '@mui/icons-material'

import { useTranslation } from 'react-i18next'

export default function BillingCheckoutSuccess() {

    const {t} = useTranslation(['plans', 'common']);

    return (
        <>
            <Container maxWidth={false} style={{ height: "100vh", backgroundColor: "#e8e8e8" }}>
    
                <Container maxWidth="md" style={{ padding: "140px 0px 16px 0px" }}>
                    <Paper variant="outlined" style={{ padding: '40px' }}>
                        <Grid container spacing={3} justifyContent="center">
    
                            <Grid item xs={12}>
                                <Typography variant="h1" component="h2" align="center" style={{ fontWeight: 800, textTransform: "uppercase" }} color="primary">
                                    {t("Thank_you")}
                                </Typography>
                                <Typography variant="h4" component="h2" gutterBottom align="center">
                                    {t("For_purchasing_our_product")}
                                </Typography>
                                <Divider style={{ marginBottom: '24px' }} />
                                <Typography variant="h6" component="p" gutterBottom align="center">
                                    {t("Thank_you_page_general_features")}
                                </Typography>
                            </Grid>
    
    
                            <Grid item xs={12} style={{ textAlign: "center" }}>
                                <Button 
                                    component={Link} to="/"
                                    variant="contained" 
                                    size="large" 
                                    color="primary" 
                                    startIcon={<ChevronLeft />}
                                    style={{ padding: "16px 24px" }}
                                    aria-label="Go back home"
                                >
                                    {t("Home")}
                                </Button>
                            </Grid>
    
                        </Grid>
                    </Paper>
                </Container>
            
            </Container>
        </>
    )
}
