import React from "react"
import {CircularProgress, Dialog} from "@mui/material";

export default function LoadingBackdrop(props: any) {

    const styles = {
        dialog: {
            "& div": {
                overflow: "hidden"
            }
        },
        dialogPaper: {
            backgroundColor: 'transparent',
            boxShadow: 'none'
        },
        progress: {
            textAlign: "center"
        }
    }

    return (
        <Dialog open={props.open} sx={styles.dialog} PaperProps={{sx: styles.dialogPaper}}>
            <CircularProgress sx={styles.progress} />
        </Dialog>
    )
}