import React, {useEffect} from 'react';
// MUI
import {
    List,
    ListItem,
    ListItemIcon, ListItemSecondaryAction,
    ListItemText,
    Paper, Skeleton,
    Typography
} from "@mui/material";
import {
    People,
    Add
} from "@mui/icons-material";
// API
import {APIDelete} from "@api";
import {GetOrganisationResponse, Invitation} from "@interfaces";
// Unitag UI
import {Button} from "@components";
// Organisation Dialogs
import InviteUserDialog from "../dialogs/OrgInviteUserDialog";
import UserRoleDialog from "../dialogs/OrgChangeUserRoleDialog";
import RemoveUserDialog from "../dialogs/OrgRemoveUserDialog";
// Components
import OrgUsersDataTable from "../dataTables/OrgUsersDataTable";
import OrgUsersFilters from "../filters/OrgUsersFilters";
// Styles
import {styles} from "../styles";
// TRANSLATION
import {useTranslation} from "react-i18next";
// Context
import {useUser} from "@context";

const {REACT_APP_API_URL} = process.env;

export default function OrganisationsUsersTab(props: any) {

    const {t} = useTranslation(['settings', 'common']);
    const {handleSuccess, handleError} = props

    const {
        complexUser: user,
        organisation, getOrganisation,
        filteredOrganisationInvitations, getFilteredOrganisationInvitations
    } = useUser()

    const [loading, setLoading] = React.useState<boolean>(true);
    const [loadingUsers, setLoadingUsers] = React.useState<boolean>(false);
    // Organisation states
    const [currentOrg, setCurrentOrg] = React.useState<GetOrganisationResponse | null>(null);
    const [initOrg, setInitOrg] = React.useState<any>(null);
    const [selectedOrganisation, setSelectedOrganisation] = React.useState<any>("");
    // Sub-organisations states
    const [subOrganisations, setSubOrganisations] = React.useState<GetOrganisationResponse[] | null>(null);
    // Members list
    const [orgMembers, setOrgMembers] = React.useState<Array<Invitation> | null>(null);
    const [selectedUser, setSelectedUser] = React.useState<any>(null);
    // User Invitation
    const [openInvitationDialog, setOpenInvitationDialog] = React.useState<boolean>(false);
    // Role changing
    const [openRoleDialog, setOpenRoleDialog] = React.useState<boolean>(false);
    // Deleting users
    const [openDeleteUserDialog, setOpenDeleteUserDialog] = React.useState<boolean>(false);
    // Data Table pagination and filters
    const [params, setParams] = React.useState({
        page: 1,
        maxRows: 10,
        order: "desc",
        email: "",
        admin: false,
        member: false
    })

    const dt = React.useRef(null);

    // Init
    useEffect(() => {
        getOrganisation()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Get invitations
    useEffect(() => {
        if (!!selectedOrganisation) getFilteredOrganisationInvitations(selectedOrganisation.organisation_id, params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOrganisation, params])

    // Hydrate sub organisations and set global data
    useEffect(() => {
        // Behaviour if sub-organisation is returned
        if ((!!initOrg && !initOrg.is_root_org) || (!!initOrg && initOrg.sub_organisations === null)) {
            setSelectedOrganisation(initOrg);
            return;
        }

        // Behaviour if main org is returned
        if (initOrg !== null && !!initOrg.sub_organisations) {
            let subOrgs = initOrg.sub_organisations.sort((a: any, b: any) =>
                a.organisation_name.localeCompare(b.organisation_name)
            );
            setSubOrganisations(subOrgs);
            setSelectedOrganisation(initOrg);
            return;
        }
    }, [initOrg]);

    // Set local data if !!organisation
    useEffect(() => {
        if (!!organisation) {
            if (!!organisation.member_org) {
                setCurrentOrg(organisation.member_org);

            } else {
                setCurrentOrg(organisation);
                setInitOrg(organisation);
                setLoading(false);
            }
        }
    }, [organisation])

    // Set local data if !!organisationInvitations
    useEffect(() => {
        if (!!filteredOrganisationInvitations) {
            if (filteredOrganisationInvitations.length < 1 && params.page !== 1) setParams({...params, page: 1})
            else setOrgMembers(filteredOrganisationInvitations);

            setLoadingUsers(false);
        } else {
            setOrgMembers([]);
            setLoadingUsers(false);
            setParams({...params, page: 1})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filteredOrganisationInvitations])

    // Handle success for invitations
    const handleInvitationSuccess = () => {
        handleSuccess(t("Snackbar_invitation_sent"))
        setOpenInvitationDialog(false);
    }

    // Role changing dialog states
    const handleOpenRoleDialog = (userData: any) => {
        setSelectedUser(userData);
        setOpenRoleDialog(true);
    }

    const handleCloseRoleDialog = () => {
        setSelectedUser(null);
        setOpenRoleDialog(false);
    }

    // Delete User dialog states
    const handleOpenDeleteUserDialog = (userData: any) => {
        setSelectedUser(userData);
        setOpenDeleteUserDialog(true);
    }

    const handleCloseDeleteUserDialog = () => {
        setSelectedUser(null);
        setOpenDeleteUserDialog(false);
    }

    // Handle add member confirmation
    const handleAddMembers = (invitations: Invitation[]) => {
        if (orgMembers === null) {
            setOrgMembers(invitations);
        } else {
            let currentInvitations = orgMembers;
            currentInvitations.push(...invitations);
            setOrgMembers(currentInvitations);
        }
    };

    // Handle Role Change confirmation
    const handleConfirmChangeRole = () => {
        setOpenRoleDialog(false);
        getFilteredOrganisationInvitations(selectedOrganisation.organisation_id);
    }

    // Actions By Row
    const handleCancelInvitation = (invitation_id: string) => {
        const orgUUID = selectedOrganisation.organisation_id
        APIDelete(`${REACT_APP_API_URL}/org/${orgUUID}/invite/${invitation_id}`, {}).then((data) => {
            if (data.status >= 400) handleError(t("Snackbar_invitation_cancellation_error"))
            else {
                handleSuccess(t("Snackbar_invitation_cancelled"))
                getFilteredOrganisationInvitations(selectedOrganisation.organisation_id);
            }
        }).catch(() => handleError(t("Snackbar_server_error")));
    }

    const handleDelete = () => {
        handleSuccess(t("Snackbar_user_deleted"))
        handleCloseDeleteUserDialog();
        getFilteredOrganisationInvitations(selectedOrganisation.organisation_id);
    }

    // Display functions
    const returnSelfRole = () => {
        let role: string;
        // search for user role
        switch(currentOrg?.role) {
            case "OWNER": role = t("Owner_of"); break;
            case "ADMIN": role = t("Admin_of"); break;
            case "MEMBER": role = t("Member_of"); break;
            default: role = t("Member_of"); break;
        }

        return (
            <>
                <Paper variant={"outlined"} sx={styles.greyedPaper}>
                    <Typography>
                        {
                            loading ? <Skeleton variant={"text"} />
                            : t("You_are_currently") + role.toLowerCase() + currentOrg?.organisation_name + "."
                        }
                    </Typography>
                </Paper>
            </>
        )
    }

    // Data table display
    const returnMembersList = () => {

        if (loading) return <Skeleton variant={"rectangular"} height={150} sx={styles.blockBalance} />

        // In any other case, display the right thing
        if (currentOrg !== null && (currentOrg.role === "ADMIN" || currentOrg.role === "OWNER")) {
            return (
                <Paper variant="outlined" sx={styles.blockBalance}>
                    <List disablePadding>
                        <ListItem dense sx={styles.listHeader}>
                            <ListItemIcon><People /></ListItemIcon>
                            <ListItemText
                                primary={t("common:Users")}
                                secondary={t("Orga_members_text")}
                            />
                            <ListItemSecondaryAction>
                                <Button
                                    primary
                                    small
                                    dense
                                    startIcon={
                                        <Add />
                                    }
                                    onClick={() => setOpenInvitationDialog(true)}
                                >
                                    {t("Invite")}
                                </Button>
                            </ListItemSecondaryAction>
                        </ListItem>

                        <OrgUsersDataTable
                            {...{
                                orgMembers,
                                loadingUsers,
                                user,
                                params, setParams,
                                handleOpenRoleDialog,
                                handleOpenDeleteUserDialog,
                                handleCancelInvitation,
                                dt, t
                            }}
                        />
                    </List>
                </Paper>

            );
        }
    }

    return (
        <>
            {returnSelfRole()}

            <OrgUsersFilters
                {...{
                    initOrg,
                    params, setParams,
                    selectedOrganisation, setSelectedOrganisation,
                    subOrganisations,
                    t
                }}
            />

            {returnMembersList()}

            <InviteUserDialog
                onInvite={handleAddMembers}
                user={user}
                seatsLeft={initOrg?.seats_left}
                selectedOrg={selectedOrganisation}
                open={openInvitationDialog}
                onClose={() => setOpenInvitationDialog(false)}
                onSuccess={handleInvitationSuccess}
            />

            <UserRoleDialog
                user={selectedUser}
                organisation={selectedOrganisation}
                open={openRoleDialog}
                onClose={handleCloseRoleDialog}
                onConfirm={handleConfirmChangeRole}
                {...{handleError}}
            />

            <RemoveUserDialog
                user={selectedUser}
                userList={orgMembers}
                open={openDeleteUserDialog}
                organisation={selectedOrganisation}
                onClose={handleCloseDeleteUserDialog}
                onDelete={handleDelete}
                {...{handleError}}
            />
        </>
    )
}
