import React, { useEffect, useState } from 'react';
// MUI
import {Box, Typography, Paper} from '@mui/material';
import {Circle} from "@mui/icons-material";
// styles
import {styles} from "./styles";
// Trad
import {useTranslation, getI18n} from "react-i18next";
import DateDisplay from '../../Utils/DateDisplay';
import { useHistory } from 'react-router-dom';
import {Button} from "../"


export default function Notification(props: any) {
    const {t} = useTranslation(['notification', 'common']);
    const history = useHistory();

    const {published_at, level, title, section, recipients, uuid, acknowledgeNotifications, handleClose} = props;

    const [lang, setLang] = useState<string>("");

    useEffect(() => {
        setLang(getI18n().languages[1])
    }, [props]);

    const returnSeverityStyles = (severity: string) => {
        switch (severity) {
            case "warning": return  {...styles.paper, ...styles.notificationWarning};
            case "info": return  {...styles.paper, ...styles.notificationInfo};
            default: return {...styles.paper, ...styles.notificationDefault}
        }
    }

    const sliceString = (str: string, max: number) => {
        if (str.length <= max) return str;
        return str.slice(0, max) + "..."
    }

    const displayTitle = () => {
        if (title === null || title === undefined) {
            return (
                <Typography>
                    {t("no_title_found ")}
                </Typography>
            );
        } 
        if (title[lang] === undefined || title[lang] === "") {
            if (title["en"] !== "") {
                return (
                    <Typography>
                        {title[lang]}
                    </Typography>
                );
            }
            return (
                <Typography>
                    {t("no_title_found ")}
                </Typography>
            );
        }
        return (
            <Typography>
                {title[lang]}
            </Typography>
        );
    }

    const goToNotif = () => {
        if (recipients[0].acknowledged_at === null) {
            acknowledgeNotifications(uuid);
        }
        handleClose();
        history.push("/notifications-center", {uuid: uuid});
    }

    return (
        <Paper
            variant={"outlined"}
            sx={returnSeverityStyles(level.name)}
            onClick={() => {goToNotif()}}
        >
            <Box sx={styles.heading}>
                <Typography variant={"body1"}>
                    <b>{sliceString(section, 20)}</b>&nbsp;-&nbsp;
                    <DateDisplay originTime={published_at}/>&nbsp;-&nbsp;
                    <Button small text dense>{t("Read_more")}</Button>
                </Typography>
                { recipients[0].acknowledged_at === null && <Circle color={"secondary"} sx={styles.dot} /> }
            </Box>
            {displayTitle()}
        </Paper>
    )
}
