import React, {useEffect} from 'react';
import {Link} from "react-router-dom";
// MUI
import {
    Container,
    CircularProgress,
    Divider,
    Grid,
    ListItemText,
    Paper,
    Typography,
    Box,
    Tabs, Tab
} from '@mui/material';
// Unitag UI
import {Button} from '@components';
// TRANSLATION
import {useTranslation} from "react-i18next";
// Views
import OrganisationsOverviewTab from "../components/OrganisationsAndUsers/tabs/OrganisationsOverviewTab";
import OrganisationsUsersTab from "../components/OrganisationsAndUsers/tabs/OrganisationsUsersTab";
import InvitationsPanel from "../components/OrganisationsAndUsers/panels/OrganisationsAsInvited";
import MemberPanel from "../components/OrganisationsAndUsers/panels/OrganisationsAsMember";
import CreateOrganisationPanel from "../components/OrganisationsAndUsers/panels/OrganisationsAsLegacyCreator";
import OrganisationsWinelabelTab from "@/Views/Account/components/OrganisationsAndUsers/tabs/OrganisationsWinelabelTab";
// API
import {APIGet} from "@api";
import {Invitation} from "@interfaces";
// Context
import {useUser} from "@context";

const {REACT_APP_API_URL} = process.env;

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`organisations-tabpanel-${index}`}
            aria-labelledby={`organisations-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ my: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `organisations-tab-${index}`,
        'aria-controls': `organisations-tabpanel-${index}`,
    };
}

export default function SettingsOrganisationsTab(props: any) {

    const {t} = useTranslation(['settings', 'common']);
    // New context provider
    const {user, complexUser, getComplexUser, getCredits, credits} = useUser()
    const {handleSuccess, handleError} = props;

    // Tabs
    const [tabValue, setTabValue] = React.useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };
    // Status
    const [userStatus, setUserStatus] = React.useState<string>("");
    const [allowWinelabel, setAllowWinelabel] = React.useState<boolean>(false)
    // The loading state goes to false as soon as userStatus gets defined
    const [loading, setLoading] = React.useState<boolean>(true);
    const [pendingInvitation, setPendingInvitation] = React.useState<any>(null);

    const styles = {
        tabs: {
            borderRadius: "4px 4px 0 0"
        },
        winelabelTab: {
            display: allowWinelabel ? "inline-flex" : "none"
        },
        docTab: {
            ml: 'auto'
        },
        padding: {
            p: 2
        },
        defaultPanelButton: {
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            justifyContent: "center"
        },
        loaderWrapper: {
            width: "100%",
            display: "flex",
            justifyContent: "center",
            mt: 5
        },
        header: {
            py: 1
        }
    }

    // Get user status and display proper panel
    useEffect(() => {
        getCredits()
        if (!complexUser) getComplexUser()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Allow parts of the UI if credits are present
    useEffect(() => {
        if (!!credits) {
            const _winelabel = credits.find((credit: any) => credit.service_name === "winelabel" && credit.credits_bought > 0)
            setAllowWinelabel(Boolean(_winelabel))
        }
    }, [credits])

    // Manage user status
    useEffect(() => {
        if (!!complexUser) {
            if (complexUser.organisation_role) {
                // User has an organisation role
                setUserStatus(complexUser.organisation_role.toLowerCase())
                setLoading(false);
                return;
            }

            // WARNING: The following chunk is here to manage "out of bounds" users that did not follow the frontend payment flow
            // This WILL cause bugs in the future unless managed in the backend!
            // Consider with great caution!
            if (
                (complexUser.org === "" || complexUser.org === null) &&
                (complexUser.account_type === "gold" || complexUser.account_type === "platinum")
            ) {
                setUserStatus("out of bounds");
                setLoading(false);
                return;
            }
            // No role has been defined yet
            fetchInvitations();
        }
    }, [complexUser])

    const fetchInvitations = () => {
        // not catched for it will throw errors on any user which is not invited
        APIGet<Invitation>(REACT_APP_API_URL + "/org_invitations").then((data) => {
            if (data.status === 200 && data.parsedBody) {
                setUserStatus("invited");
                setPendingInvitation(data.parsedBody);
                setLoading(false);
            }
        }).finally(() => setLoading(false))
    }

    const returnOrganisationAdministrationPanel = () => {
        return (
            <Box>
                <Box>
                    <Tabs value={tabValue} onChange={handleChange} aria-label="organisation tabs">
                        <Tab label={t("Organisations")} sx={styles.tabs} {...a11yProps(0)} />
                        <Tab label={t("Users")} sx={styles.tabs} {...a11yProps(1)} disabled={!!user && user.account_type === "standalone"} />
                        <Tab label={t("Winelabel")} sx={{...styles.tabs, ...styles.winelabelTab}} {...a11yProps(2)} />

                        <Button
                            component={"a"}
                            href={"https://unitag.io/documentation/organisations/introduction"}
                            target={"_blank"}
                            rel={"noopener noreferrer"}
                            spacing
                            dense
                            primary
                            sx={styles.docTab}
                        >
                            {t("common:Documentation")}
                        </Button>
                    </Tabs>
                </Box>
                <TabPanel value={tabValue} index={0}>
                    <OrganisationsOverviewTab {...{allowWinelabel, handleError, handleSuccess}} />
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                    <OrganisationsUsersTab  {...{handleError, handleSuccess}} />
                </TabPanel>
                <TabPanel value={tabValue} index={2}>
                    <OrganisationsWinelabelTab {...{credits, getCredits, t}} />
                </TabPanel>
            </Box>
        )
    }

    const returnInvitationsPanel = () => {
        if (!!pendingInvitation) {
            return <InvitationsPanel invitation={pendingInvitation} {...{handleError}} />
        }
        return loader();
    }

    const returnMemberPanel = () => {
        return <MemberPanel {...{t}} />
    }

    const returnCreateOrganisationPanel = () => {
        return <CreateOrganisationPanel {...{handleError, t}} />
    }

    const returnDefaultPanel = () => {
        return (
            <>
                <Paper variant={"outlined"} sx={styles.padding}>
                    <Grid container justifyContent={"space-between"} spacing={2}>
                        <Grid item xs={12} md={8}>
                            <ListItemText
                                primary={t("Plan_display") + " " + t(complexUser?.account_type)}
                                secondary={t("Orga_plan_needed")}
                            />
                        </Grid>
                        <Grid item xs={8} md={4} sx={styles.defaultPanelButton}>
                            <Button primary component={Link} to="/plans">
                                {t("Upgrade")}
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </>
        )
    }

    const loader = () => {
        return (
            <Box sx={styles.loaderWrapper}>
                <CircularProgress />
            </Box>
        )
    }

    const displayProperPanel = () => {

        if (loading) return loader();

        switch (userStatus) {
            case "invited": return returnInvitationsPanel();
            case "admin": return returnOrganisationAdministrationPanel();
            case "owner": return returnOrganisationAdministrationPanel();
            case "member": return returnMemberPanel();
            case "out of bounds": return returnCreateOrganisationPanel();
            default: return returnDefaultPanel();
        }
    }

    return (
        <main>
            <Container maxWidth="xl">

                <Grid container justifyContent='flex-start'>
                    <Grid item xs={12} sx={styles.header}>
                        <Typography variant='h5' gutterBottom>{t('Organizations_and_users')}</Typography>
                        <Divider/>
                    </Grid>
                </Grid>

                {displayProperPanel()}

            </Container>
        </main>
    );
}