import React from "react";
// MUI
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Radio,
    RadioGroup,
    Grid,
    IconButton,
    useMediaQuery,
    TextField,
    Typography,
    Stepper, Step, StepLabel,
    Skeleton, Alert
} from '@mui/material';
// ICONS
import CloseIcon from "@mui/icons-material/Close";
// TRANSLATION
import {useTranslation} from "react-i18next";
import {useStyles} from "@/Context/Ui/DashboardUiProvider";

export default function UnsubscribeFromPlanDialog(props: any) {

    const {theme} = useStyles()
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const {t} = useTranslation(['settings', 'common']);
    const {loading, stripe} = props;

    const steps = [t("informations"), t("feedback")];
    const [activeStep, setActiveStep] = React.useState(0);

    const padding = { p: 3 };

    const handleNext = () => {
        setActiveStep((prevStep) => prevStep + 1);
    }

    const handlePrevious = () => {
        setActiveStep((prevStep) => prevStep - 1);
    }

    const handleClose = () => {
        props.setConfirmUnsubscribe(false);
        setActiveStep(0);
    }

    const returnStepContent = () => {
        switch(activeStep) {
            case 0:
                return(
                    <>
                    <Typography variant={"body1"}>
                        {t('Unsubscribe_explanation')}
                    </Typography>
                        <Alert
                            style={{marginTop: '16px'}}
                            severity="error"
                        >
                            <Typography variant={"body2"}>
                                {t('Unsubscribe_support')}
                            </Typography>
                        </Alert>
                    </>
                );
            case 1:
                return(
                    <>
                        <FormControl fullWidth id={"reason"}>
                            <Typography variant={"body1"}>{t("Why_unsubscribe")}</Typography>
                            <RadioGroup
                                defaultValue={1}
                                onChange={(e) => { props.setUnsubscribeReason((e.target as HTMLInputElement).value) }}
                                sx={{ pb: 2 }}
                            >
                                <FormControlLabel value={1} control={<Radio />} label={`${t("reason_1")}`} />
                                <FormControlLabel value={2} control={<Radio />} label={`${t("reason_2")}`} />
                                <FormControlLabel value={3} control={<Radio />} label={`${t("reason_3")}`} />
                                <FormControlLabel value={4} control={<Radio />} label={`${t("reason_4")}`} />
                                <FormControlLabel value={5} control={<Radio />} label={`${t("reason_5")}`} />
                                <FormControlLabel value={6} control={<Radio />} label={`${t("reason_6")}`} />
                            </RadioGroup>

                            <Typography variant={"body1"} gutterBottom>{t("Unsubscribe_feedback_label")}</Typography>
                            <TextField
                                multiline
                                fullWidth
                                rows={4}
                                onChange={(e) => { props.setUnsubscribeFeedback(e.target.value) } }
                                variant={"outlined"}
                                label={t("feedback")}
                            />
                            <FormHelperText>{t("Optional")}</FormHelperText>
                        </FormControl>
                    </>
                );
            default: return (
                <>
                    <Skeleton variant={"circular"} width={150} height={150} sx={{ margin: 'auto' }} />
                </>
            )
        }
    }

    return (
        <Dialog
            key={'confirmdelete'}
            fullWidth
            fullScreen={fullScreen}
            maxWidth={'sm'}
            open={props.confirmUnsubscribe}
            keepMounted
            onClose={handleClose}
            aria-labelledby="unsubscribe-from-unitag"
            aria-describedby="alert-dialog-slide-description"
            scroll="body"
        >
            <DialogTitle sx={padding}>
                <Box display="flex" alignItems="center">
                    <Box flexGrow={1}>{t("Confirm_unsubscribe")}</Box>
                    <IconButton onClick={handleClose}>
                        <CloseIcon/>
                    </IconButton>
                </Box>
            </DialogTitle>

            <DialogContent sx={padding}>

                <Stepper activeStep={activeStep}>
                    {steps.map((label, index) => {
                        const stepProps: {completed?: boolean} = {};
                        return (
                            <Step key={index} {...stepProps}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        )
                    })}
                </Stepper>

                <Grid container justifyContent={'center'} alignItems="center" mt={3}>
                    <Grid item xs={12} md={10}>
                        {returnStepContent()}
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions sx={padding}>
                <div style={{ flexGrow: 1 }}>
                    <Button
                        disableElevation
                        onClick={handleClose}
                        color="inherit"
                    >
                        {t("Cancel")}
                    </Button>
                </div>
                <div>
                    { activeStep !== 0 &&
                        <Button
                            onClick={handlePrevious}
                            color="inherit"
                        >
                            {t("common:Back")}
                        </Button>
                    }
                    &nbsp;&nbsp;

                    <Button
                        onClick={activeStep === steps.length -1 ? props.handleUnsubscribeClick : handleNext}
                        color={activeStep === steps.length -1 ? 'error' : 'primary'}
                        variant={"contained"}
                        disableElevation
                        disabled={loading || !stripe}
                    >
                        {loading ? t("common:loading") :
                            activeStep === steps.length - 1 ? t("Unsubscribe") : t("common:Next")
                        }
                    </Button>
                </div>
            </DialogActions>
        </Dialog>
    );
}
