// REACT
import React, {useEffect, useState} from 'react';
// MUI
import {Grid, Paper, Typography} from '@mui/material';
// COMPONENTS
import UnsubscribeFromPlanDialog from "./dialogs/UnsubscribeFromPlanDialog";
import BillingOverviewPaymentMethods from "./BillingOverviewPaymentMethods";
import BillingOverviewInformation from "./BillingOverviewInformation";
// API
import {APIPost} from "@api";
// Interfaces
import {GetCurrentInvoiceResponse, GetOrgAsMember, PaymentMethod, SubscriptionItems} from "@interfaces";
// TRANSLATION
import {useTranslation} from 'react-i18next';
// STRIPE
import {useStripe} from "@stripe/react-stripe-js";
// Unitag UI
import {Button} from '@components';
// Context
import {useUser} from "@context";
// Utils
import moment from "moment";

const {REACT_APP_API_URL} = process.env;

export default function BillingOverviewTab(props: any) {

    const {handleSuccess, handleError} = props;

    const {t} = useTranslation(['settings', 'common']);
    const stripe = useStripe();
    const {
        user, getUser,
        creditsDetails, getCreditsDetails,
        billingMethod, getBillingMethod,
        organisationAsMember, getOrganisationAsMember,
        billingInvoice, getBillingInvoice
    } = useUser()

    const styles = {
        trialPaper: { p: 2, width: '100%', my: 2, backgroundColor: "#c0e5bc" },
    };

    const [loading, setLoading] = useState<boolean>(false);

    const [currentInvoice, setCurrentInvoice] = useState<GetCurrentInvoiceResponse>({
        amount_due: 0,
        cancel_at: 0,
        due_date: 0,
        currency: "",
        trial_days_left: 0,
        is_in_trial: false,
        invoice_pdf: "",
        items: [] as SubscriptionItems[],
        subscription: {
            amount: 0,
            type: ""
        }
    });

    // Access
    const [canAccessPayment, setCanAccessPayment] = useState<boolean>(false);
    // User data
    const [currentOrg, setCurrentOrg] = useState<GetOrgAsMember | null>(null);
    const [renewalDate, setRenewalDate] = useState<string>('--');
    // Billing Method
    const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[] | null>(null);
    const [paymentLoading, setPaymentLoading] = useState<boolean>(true);
    // Unsubscribe
    const [confirmUnsubscribe, setConfirmUnsubscribe] = useState<boolean>(false);
    const [unsubscribeReason, setUnsubscribeReason] = useState<number | any>(1);
    const [unsubscribeFeedback, setUnsubscribeFeedback] = useState<string | null>(null);

    useEffect(() => {
        setPaymentLoading(true)
        getUser()
        getOrganisationAsMember()
        getCreditsDetails()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Load user data
    useEffect(() => {
        let _canAccessPayment: boolean;
        // The user is part of an org
        if (!!organisationAsMember && organisationAsMember.organisation_id !== '') {
            _canAccessPayment = organisationAsMember.is_root_organisation && organisationAsMember.role === "OWNER"
        } else _canAccessPayment = true

        setCanAccessPayment(_canAccessPayment)

        if (_canAccessPayment) {
            getBillingMethod()
            getBillingInvoice()
        } else setPaymentLoading(false)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organisationAsMember])

    // Load payment methods
    useEffect(() => {
        if (!!billingMethod) {
            // Set payment methods
            setPaymentMethods(billingMethod.payment_methods)
            // Or remove loading when no payment method is detected
        } else if (billingMethod === null) setPaymentLoading(false)
    }, [billingMethod])

    // Load invoice
    useEffect(() => {
        if (!!billingInvoice) {
            setCurrentInvoice(billingInvoice);
            const dueDate: number = billingInvoice.due_date;
            if (dueDate !== 0) {
                const _renewalDate = new Date(dueDate * 1000).toLocaleString(navigator.language);
                setRenewalDate(_renewalDate.split(",")[0]);
            }
        }
    }, [billingInvoice])

    // Specific use of loading
    useEffect(() => {
        if (!!paymentMethods && paymentMethods.length > 0) setPaymentLoading(false)
    }, [paymentMethods])

    // Organisation
    useEffect(() => {
        if (!!organisationAsMember) setCurrentOrg(organisationAsMember)
    }, [organisationAsMember])

    const handleUnsubscribeClick = () => {
        if (unsubscribeReason !== null) {
            setLoading(true);

            APIPost<any>(REACT_APP_API_URL + "/billing/unsubscribe", {
                reason: unsubscribeReason,
                feedback: unsubscribeFeedback
            }).then((data) => {

                if (data.status !== 200) handleError(t("error_unsubscribing"))
                else handleSuccess(t("success_unsubscribing"))

                setLoading(false);
                setConfirmUnsubscribe(false);
                setUnsubscribeFeedback(null);
                setUnsubscribeReason(1);
                return
            }).catch(() => handleError(t("error_unsubscribing"))
            ).finally(() => getCreditsDetails());
            return
        }

        setLoading(true);

        APIPost<any>(REACT_APP_API_URL + "/billing/unsubscribe", null).then((data) => {
            if (!data.ok) handleError(t("error_unsubscribing"))
            else handleSuccess(t("success_unsubscribing"))

            setLoading(false);
            setConfirmUnsubscribe(false);
        }).catch(() => handleError(t("error_unsubscribing"))
        ).finally(() => getCreditsDetails());
    }

    const renderUnsubscribe = () => {

        if (!canAccessPayment) return <></>

        if (!!currentInvoice && !!creditsDetails && creditsDetails.cancel_at !== 0) {
            if (!currentInvoice.is_in_trial) {
                return (<Grid><i style={{fontSize: '12px'}}>{t("Unsubscribe_definitive")}</i></Grid>)
            } else return (<Grid><i style={{fontSize: '12px'}}>{t("Unsubscribe_success")}</i></Grid>)
        }

        if (!!currentInvoice && (currentInvoice.items !== null || currentInvoice.subscription.amount !== 0)) {
            return (
                <Grid item xs={12} sx={{ textAlign: 'right' }}>
                    <Button
                        disabled={!canAccessPayment}
                        delete
                        onClick={() => setConfirmUnsubscribe(true)}
                    >
                        {t("Unsubscribe")}
                    </Button>
                </Grid>
            )
        }

        return <></>
    }

    return (
        <>

            <Grid container alignItems="center" alignContent="center" sx={{ my: 3 }}>

                {
                    (!!creditsDetails && creditsDetails.is_trial) &&
                    <Paper variant={"outlined"} sx={styles.trialPaper}>
                        <Typography variant={"body2"} align={"left"} sx={{mt: "2px"}} color={"success.dark"} fontWeight={700}>
                            {t("trial_still_ongoing_until")}{`${moment.unix(creditsDetails.day_trial_left).format("DD/MM/YYYY")}`}
                        </Typography>
                    </Paper>
                }


                {/* Payment methods*/}
                <BillingOverviewPaymentMethods
                    {...{
                        getBillingMethod,
                        paymentMethods,
                        paymentLoading, setPaymentLoading,
                        canAccessPayment,
                        handleSuccess, handleError,
                        stripe, loading, setLoading, t
                    }}
                />

                {/* Information */}
                <Grid container alignItems="center" alignContent="center" sx={{ my: 3 }}>
                    <BillingOverviewInformation
                        {...{
                            canAccessPayment,
                            handleSuccess, handleError,
                            currentInvoice, currentOrg,
                            user,
                            setConfirmUnsubscribe,
                            renewalDate,
                            paymentLoading, setLoading,
                            t
                        }}
                    />
                </Grid>
                {renderUnsubscribe()}
            </Grid>

            <UnsubscribeFromPlanDialog
                {...{
                    confirmUnsubscribe, setConfirmUnsubscribe,
                    unsubscribeReason, setUnsubscribeReason,
                    loading, stripe, handleUnsubscribeClick
                }}
            />
        </>
    );
}
