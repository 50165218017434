import React from "react"
// MUI
import {Tooltip, Typography} from "@mui/material";
// Translation
import {useTranslation} from "react-i18next";
// functions
import {checkLength, includesLowercase, includesNumber, includesSpecialCharacters, includesUppercase} from "@functions";

export default function PasswordCheckTooltip(props: any) {

    const {password, setIsPasswordValid} = props
    const {t} = useTranslation(["common"])

    const [passwordRules, setPasswordRules] = React.useState({
        length: false,
        uppercase: false,
        lowercase: false,
        number: false,
        characters: false,
    });

    // Check password on change
    // Return false if no password is entered
    React.useEffect(() => {
        if (!!password) checkPassword(password)
        else if (!password || password === "") {
            setIsPasswordValid(false)
            resetChecks()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [password])

    // Change prop state if password is valid
    React.useEffect(() => {
        if (!!password) {
            setIsPasswordValid(false);
            if (
                passwordRules.lowercase &&
                passwordRules.uppercase &&
                passwordRules.length &&
                passwordRules.number &&
                passwordRules.characters
            ) {
                setIsPasswordValid(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [passwordRules]);

    const resetChecks = () => {
        setPasswordRules({
            length: false,
            uppercase: false,
            lowercase: false,
            number: false,
            characters: false,
        })
    }

    const checkPassword = (password: string) => {
        if (password === "") resetChecks()

        if (password !== "") {
            let _password = password;

            setPasswordRules({
                length: checkLength(_password, 8),
                uppercase: includesUppercase(_password),
                lowercase: includesLowercase(_password),
                number: includesNumber(_password),
                characters: includesSpecialCharacters(_password)
            })
        }
    }

    const returnPasswordRules = () => {
        const rules = [
            { key: "Pwd_1_lowercase", check: passwordRules.lowercase },
            { key: "Pwd_8_characters", check: passwordRules.length },
            { key: "Pwd_1_uppercase", check: passwordRules.uppercase },
            { key: "Pwd_1_number", check: passwordRules.number },
            { key: "Pwd_1_special", check: passwordRules.characters },
        ];

        return (
            <>
                <Typography fontSize={14}>
                    {t("Pwd_rules")}
                </Typography>
                {rules.map((rule: any, index: number) => {
                    return (
                        <Typography fontSize={14} key={index} color={rule.check ? "primary" : "inherit"}>
                            {t(rule.key)}
                        </Typography>
                    )
                })}
            </>
        )
    }

    return (
        <>
            <Tooltip title={returnPasswordRules()} placement={props.placement ? props.placement : "right"} arrow>
                {props.children}
            </Tooltip>
        </>
    )
}