import React, {createContext, useContext, useEffect, useState} from "react"
import {Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useUser} from "@context";
import {APIGet, APIPut} from "@api";
import {useSnackbar} from "@hooks";
import {TourObject, TourServiceName} from "@/interfaces/variousInterfaces";

const {REACT_APP_API_URL} = process.env;

const TourContext = createContext<any>(null)

export const useTour = () => useContext(TourContext)

// How it works?
// Each tour has a named state (which represents whether it is displayed or not and its step)
// // and a [tour]steps object containing the tour's contents

export function TourProvider({children}: any) {

    const {t} = useTranslation("tours")
    const {user} = useUser()
    const {handleError} = useSnackbar()

    const styles = {
        defaultTextContent: {
            fontSize: 14
        }
    }

    // GENERAL SETTINGS
    const [userTours, setUserTours] = useState<null | TourObject[]>(null)

    useEffect(() => {
        // We make sure that user has preloaded in order to fetch tours
        if (!!user) getUserTours()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])

    const getUserTours = () => {
        // We get tours response from the API
        APIGet(REACT_APP_API_URL + "/user/setting").then((res: any) => {
            setUserTours(res.parsedBody.tours)
        }).catch(() => {
            handleError(t("error_getting_tour_progression"))
        })
    }

    // HOME TOUR
    const [homeTour, setHomeTour] = useState<number | undefined>(undefined)

    const homeTourSteps = [
        {
            step: 1,
            title: t("welcome_tour"),
            subtitle: t("your_summary"),
            content: <Typography sx={styles.defaultTextContent}>{t("your_summary_text")}</Typography>,
            position: "left"
        },
        {
            step: 2,
            title: t("welcome_tour"),
            subtitle: t("your_settings"),
            content: <Typography sx={styles.defaultTextContent}>{t("your_settings_text")}</Typography>,
            position: "bottom-end"
        },
        {
            step: 3,
            title: t("welcome_tour"),
            subtitle: t("app_qrcode"),
            content: <Typography sx={styles.defaultTextContent}>{t("app_qrcode_text")}</Typography>,
            position: "bottom"
        },
        {
            step: 4,
            title: t("welcome_tour"),
            subtitle: t("app_vcard"),
            content: <Typography sx={styles.defaultTextContent}>{t("app_vcard_text")}</Typography>,
            position: "bottom"
        },
        {
            step: 5,
            title: t("welcome_tour"),
            subtitle: t("app_atlas"),
            content: <Typography sx={styles.defaultTextContent}>{t("app_atlas_text")}</Typography>,
            position: "bottom"
        },
        {
            step: 6,
            title: t("welcome_tour"),
            subtitle: t("app_packaging"),
            content: <Typography sx={styles.defaultTextContent}>{t("app_packaging_text")}</Typography>,
            position: "bottom"
        },
        {
            step: 7,
            title: t("welcome_tour"),
            subtitle: t("app_analytics"),
            content: <Typography sx={styles.defaultTextContent}>{t("app_analytics_text")}</Typography>,
            position: "bottom"
        },
        {
            step: 8,
            title: t("welcome_tour"),
            subtitle: t("support"),
            content: <Typography sx={styles.defaultTextContent}>{t("support_text")}</Typography>,
            position: "top-start"
        },
        {
            step: 9,
            title: t("welcome_tour"),
            subtitle: t("tours"),
            content: <Typography sx={styles.defaultTextContent}>{t("tours_text")}</Typography>,
            position: "right"
        },

    ]

    // DISPATCH TOUR STATES
    useEffect(() => {
        if (!!userTours && userTours.length > 0) {
            // We individually check for each tour available and change states accordingly
            // The approach may seem counterintuitive, but we specifically carve for the `false` statement when values
            // are not undefined only.
            // Take that, optimisation!
            let homepage = userTours.find((tour: any) => tour.service_name === "homepage")
            if (homepage !== undefined && homepage.done === false) setHomeTour(1)
        }
    }, [userTours]);

    const handleTourEnd = (tour: TourServiceName) => {

        let body: TourObject = {
            service_name: tour,
            done: true
        }

        APIPut(REACT_APP_API_URL + "/user/tour", body).then().catch(() => {
            handleError(t("error_saving_tour_progression"))
        })
    }

    return (
        <TourContext.Provider value={{
            useTour,
            handleTourEnd,
            // Home Dashboard
            homeTour, setHomeTour, homeTourSteps
        }}>
            {children}
        </TourContext.Provider>
    )
}