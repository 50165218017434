import React, {useEffect, useState} from "react"
// UI
import {
    FormControl,
    Grid, IconButton, InputLabel,
    List,
    ListItem,
    ListItemIcon,
    ListItemText, MenuItem,
    Paper, Select,
    Skeleton, TextField, Tooltip,
    Typography
} from "@mui/material";
import {SelectChangeEvent} from "@mui/material/Select";
import {ControlPointDuplicate, People, SubdirectoryArrowRight} from "@mui/icons-material";
import {Button} from "@components";
// Primereact
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
// Styles
import {styles} from "@/Views/Account/components/OrganisationsAndUsers/styles";
// Types
import {GetOrganisationResponse, Invitation} from "@interfaces";
// Context
import {useUser} from "@context";
// API
import {APIPut} from "@api";
// Hooks
import {useSnackbar} from "@/hooks/useSnackbar";

const {REACT_APP_API_URL} = process.env;

export default function OrganisationsWinelabelTab(props: any) {

    const {t, credits, getCredits} = props;
    const {handleError, handleSuccess} = useSnackbar()
    const dt = React.useRef(null);

    const {
        organisation, getOrganisation,
        organisationInvitations, getOrganisationInvitations
    } = useUser()

    const [callLoading, setCallLoading] = useState<boolean>(false)
    const [blockingSubOrgAdmins, setBlockingSubOrgAdmins] = useState<boolean>(false)
    // credits
    const [winelabelCredits, setWinelabelCredits] = useState<any>(0)
    const [orgMembers, setOrgMembers] = useState<Array<Invitation> | null>(null);
    // org selection
    const [currentOrg, setCurrentOrg] = useState<any>(null);
    const [selectedOrganisation, setSelectedOrganisation] = useState<any>("");
    const [subOrganisations, setSubOrganisations] = useState<GetOrganisationResponse[] | null>(null);
    // User selection
    const [userSelection, setUserSelection] = useState<{ user: string | null, quantity: number}>({ user: null, quantity: 1 })

    useEffect(() => {
        getOrganisation()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // Set winelabel credits
    useEffect(() => {
        const _credits = credits.find((credit: any) => credit.service_name === "winelabel")
        setWinelabelCredits(_credits.credits_left)
    }, [credits])

    useEffect(() => {
        if (!!organisation) {
            if (!organisation.is_root_org) {
                setBlockingSubOrgAdmins(true)
            } else {
                setCurrentOrg(organisation);
            }

            if (!!organisation.sub_organisations) {
                let subOrgs = organisation.sub_organisations.sort((a: any, b: any) =>
                    a.organisation_name.localeCompare(b.organisation_name)
                );
                setSubOrganisations(subOrgs);
            }
            setSelectedOrganisation(organisation);
            getOrganisationInvitations(organisation.organisation_id)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organisation])

    useEffect(() => {
        if (!!selectedOrganisation) getOrganisationInvitations(selectedOrganisation.organisation_id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOrganisation])

    useEffect(() => {
        if (!!organisationInvitations) {
            let acceptedMembers = organisationInvitations.filter((invite: any) => invite.accepted)
            setOrgMembers(acceptedMembers)
        } else {
            setOrgMembers(null)
        }
    }, [organisationInvitations])

    // Main action
    const transferCreditsToUser = () => {
        setCallLoading(true)

        const body = {
            "quantity": +userSelection.quantity,
            "user_uuid": userSelection.user
        }

        APIPut(REACT_APP_API_URL + "/billing/credits", body).then((res) => {
            if (res.status === 200) {
                handleSuccess(t("success_transfer_winelabel_credits"))
                getCredits()
            } else handleError(t("error_transfer_winelabel_credits"))
        }).catch(() => {
            handleError(t("error_transfer_winelabel_credits"))
        }).finally(() => setCallLoading(false))
    }

    // Page parts
    const renderHeader = () => {
        return (
            <Grid item xs={12}>
                <Paper variant={"outlined"} sx={{ p: 2, backgroundColor: "#e8e8e8" }}>
                    <Typography variant={"body2"}>
                        {t("winelabel_give_credits")}
                    </Typography>
                    <Typography variant={"body1"}>
                        {t("winelabel_you_have")} <b>{winelabelCredits}</b> {t("winelabel_left")}
                    </Typography>
                </Paper>
            </Grid>
        )
    }

    const renderFilters = () => {

        return (
            <Grid item xs={12}>
                <Grid container justifyContent={"flex-start"} alignContent={"center"}>
                    <Grid item xs={10} sm={4}>
                        <FormControl size={"small"} fullWidth>
                            <InputLabel id={"organisations-label"}>{t("Organization")}</InputLabel>
                            <Select
                                labelId={"organisations-label"}
                                label={t("Organization")}
                                value={selectedOrganisation}
                                onChange={(e: SelectChangeEvent) => setSelectedOrganisation(e.target.value)}
                                disabled={!currentOrg}
                            >
                                {
                                    !!currentOrg &&
                                    <MenuItem value={currentOrg}>
                                        {currentOrg.organisation_name}
                                    </MenuItem>
                                }
                                {
                                    !!subOrganisations && subOrganisations?.map((orga: any, index: number) => {
                                        return (
                                            <MenuItem key={index} value={orga}>
                                                <SubdirectoryArrowRight fontSize={"inherit"} />&nbsp;{orga.organisation_name}
                                            </MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    const renderMembersList = () => {

        const emailBodyTemplate = (rowData: any) => {
            return (
                <ListItem dense disablePadding>
                    <ListItemText
                        primary={rowData.email_user}
                        primaryTypographyProps={{
                            variant: "subtitle2"
                        }}
                        secondary={t(rowData.user_role.toLowerCase())}
                        secondaryTypographyProps={{
                            sx: styles.userRole
                        }}
                    />
                </ListItem>
            )
        }

        const actionBodyTemplate = (rowData: any) => {

            if (userSelection.user === rowData.invite_uuid) {
                return (
                    <>
                        <Grid container>
                            <Grid item xs={12} md={8}>
                                <TextField
                                    size={"small"}
                                    type={"number"}
                                    value={userSelection.quantity}
                                    onChange={(e) => setUserSelection({
                                        ...userSelection,
                                        quantity: e.target.value as unknown as number
                                    })}
                                    inputProps={{ min: 1 }}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Button
                                    secondary
                                    dense
                                    sx={{ height: 40 }}
                                    disabled={userSelection.quantity < 1 || callLoading}
                                    onClick={() => transferCreditsToUser()}
                                >
                                    {t("transfer")}
                                </Button>
                            </Grid>
                        </Grid>
                    </>
                )
            }

            return (
                <Tooltip title={`${t("transfer_credits")}`} placement={"right"} arrow>
                    <IconButton onClick={() => setUserSelection({quantity: 1, user: rowData.invite_uuid})}>
                        <ControlPointDuplicate />
                    </IconButton>
                </Tooltip>
            )
        }

        return (
            <Grid item xs={12}>
                <Paper variant={"outlined"} sx={{ width: "100%" }}>
                    <List disablePadding>
                        <ListItem dense sx={{ backgroundColor: "#e8e8e8" }}>
                            <ListItemIcon><People /></ListItemIcon>
                            <ListItemText
                                primary={t("common:Users")}
                                secondary={t("Orga_members_text")}
                            />
                        </ListItem>
                        {
                            callLoading ? <Skeleton variant={"rectangular"} height={100} /> :
                                (
                                    <ListItem dense disablePadding>
                                        {orgMembers === null ?
                                            <Typography>
                                                {t("Orga_members_no_members")}
                                            </Typography>
                                            :
                                            <DataTable
                                                ref={dt}
                                                rowHover
                                                dataKey="email_user"
                                                value={orgMembers}
                                                className="p-datatable-sm"
                                                style={styles.dataTable}
                                                emptyMessage={t("No_records_found")}
                                            >
                                                <Column
                                                    field="email_user"
                                                    header={t("Email")}
                                                    body={emailBodyTemplate}
                                                />
                                                <Column
                                                    header={t("Action")}
                                                    body={actionBodyTemplate}
                                                    style={{ minWidth: 200 }}
                                                />

                                            </DataTable>
                                        }
                                    </ListItem>
                                )
                        }
                    </List>
                </Paper>
            </Grid>
        )
    }

    if (blockingSubOrgAdmins) return (
        <Grid item xs={12}>
            <Paper variant={"outlined"} sx={{ width: "100%" }}>
                <List disablePadding>
                    <ListItem dense sx={{ backgroundColor: "#e8e8e8" }}>
                        <ListItemIcon><People /></ListItemIcon>
                        <ListItemText
                            primary={t("winelabel_cant_access_admin_suborg")}
                        />
                    </ListItem>
                </List>
            </Paper>
        </Grid>
    )

    return (
        <>
            <Grid container spacing={3}>
                {renderHeader()}
                {renderFilters()}
                {renderMembersList()}
            </Grid>
        </>
    )
}