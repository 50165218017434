import React, {useEffect, useState} from "react";
// MUI
import {
    Alert,
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    IconButton, InputLabel, MenuItem, Select,
    Typography
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {SelectChangeEvent} from "@mui/material/Select";
// Styles
import {styles} from "./styles";
// Unitag UI
import {Button} from "@components";
// TRANSLATION
import {useTranslation} from "react-i18next";
// API
import {APIPost} from "@api";
import {TransferResponse} from "@interfaces";

const {REACT_APP_API_URL} = process.env;

export default function RemoveUserDialog(props: any) {

    const { open, onClose, onDelete, user, userList, organisation, handleError } = props;
    const {t} = useTranslation(['settings', 'common']);

    const [loading, setLoading] = useState<boolean>(false);
    // Other users list
    const [remainingUsers, setRemainingUsers] = useState<any[]>(props?.userList);
    // Target user for transfer
    const [targetTransferUser, setTargetTransferUser] = useState<any>("yourself");

    useEffect(() => {
        setLoading(true);
        // Sort and filter users
        if (userList) {
            let usersFiltering = userList.filter((users: any) => {
                return ((users.email_user !== user?.email_user) && (users.accepted));
            })
            setRemainingUsers(usersFiltering);
            setLoading(false);
            return;
        }
        setLoading(false);
        return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props])

    const handleClose = () => {
        if (loading) return null;
        setTargetTransferUser("yourself");
        onClose();
    }

    const deleteUser = () => {
        setLoading(true);
        let transferUserID = targetTransferUser === "yourself" ? "" : targetTransferUser;

        APIPost<TransferResponse>(REACT_APP_API_URL + "/org/" + organisation.organisation_id + "/remove_user", {
            user_id: user.invite_uuid,
            transfer_to_id: transferUserID,
        }).then(() => {
            setTargetTransferUser("yourself");
            setLoading(false);
            onDelete();
        }).catch(() => {
            handleError(t("Error_while_deleting_user"));
            setLoading(false);
        });
    }

    return (
        <>
            <Dialog
                onClose={handleClose}
                open={open}
                maxWidth={"sm"}
                fullWidth
            >
                <DialogTitle sx={styles.dialogPadding}>
                    <Box display="flex" alignItems="center">
                        <Box flexGrow={1}>{t("Orga_remove_user")}</Box>
                        <IconButton onClick={handleClose}><CloseIcon/></IconButton>
                    </Box>
                </DialogTitle>

                <DialogContent sx={styles.dialogPadding}>
                    <Typography>
                        {t("About_to_delete_following_user")}
                    </Typography>

                    <Typography fontWeight={700} align={"center"} sx={styles.formSpacing}>
                        {user?.email_user}
                    </Typography>

                    <Divider sx={styles.divider} />

                    <Typography>
                        {t("Assets_will_be_transferred_to")}{targetTransferUser === "yourself" ? t("common:Your_account").toLowerCase() : targetTransferUser}
                        {
                            targetTransferUser === "yourself" && t("Unless_you_decide_otherwise")
                        }
                    </Typography>

                    <FormControl fullWidth variant={"outlined"} sx={styles.formSpacing}>
                        <InputLabel id={"remaining-users"}>
                            {t("User_to_transfer_assets_to")}
                        </InputLabel>

                        <Select
                            labelId={"remaining-users"}
                            label={t("User_to_transfer_assets_to")}
                            value={targetTransferUser}
                            onChange={(e: SelectChangeEvent) => setTargetTransferUser(e.target.value)}
                        >
                            <MenuItem value={"yourself"}>
                                {t("common:Your_account")}
                            </MenuItem>
                            {
                                remainingUsers?.map((r_user: any, index: number) => (
                                    <MenuItem key={index} value={r_user.invite_uuid}>
                                        {r_user.email_user}
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>

                    <Alert severity={"warning"} sx={styles.formSpacing}>
                        {t("common:Definitive_text")}
                    </Alert>
                </DialogContent>

                <DialogActions sx={styles.dialogPadding}>
                    <Button onClick={handleClose} text>
                        {t("common:Cancel")}
                    </Button>
                    <Button
                        delete
                        onClick={deleteUser}
                        disabled={loading}
                    >
                        {t("Remove_user")}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
