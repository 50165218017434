import countryCodeToNameDict from "@/constants/country_codes_en"
import languageCodeToNameDict from "@/constants/languages_codes_en"

function CountryCodeToName(code: string) {
    return countryCodeToNameDict[code]
}

function LanguageCodeToName(code: string) {
    return languageCodeToNameDict[code]
}

export {CountryCodeToName, LanguageCodeToName}