import React, { useEffect } from 'react';
// MUI
import {
    Box,
    Button,
    IconButton,
    Grid,
    LinearProgress,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Paper,
    Typography
} from "@mui/material";
// MUI ICONS
import AddCircleIcon from "@mui/icons-material/AddCircle";
// TRANSLATION
import {useTranslation} from 'react-i18next';
// API
import {SubscriptionCredit, SubscriptionItem} from "@interfaces";


export default function ServiceBillingProgress(props: any) {

    const {t} = useTranslation(['settings', 'common']);
    const [service, setService] = React.useState<SubscriptionItem>(props?.service);
    const {currency, interval, unlimited} = props;

    useEffect(() => {
        if (!!props && !!props.service) {
            setService(props.service);
        }
    }, [props])

    const returnCreditUsage = (credits: SubscriptionCredit) => {

        if (!!credits) {
            let {bought, left, used} = credits;
            let totalCredits = used + left;

            return (
                <Typography variant="body2">
                    {t("Credits_total")} : {unlimited ? t("Unlimited") : totalCredits} / {t("Credits_bought")} : {bought} /  {t("Credits_used")} : {used} / <b>{t("Credits_remaining")} : {unlimited ? t("Unlimited") : left}</b>
                </Typography>
            )
        }
        return( <Typography>{t("No_billing_information")}</Typography> )
    }

    const returnCreditProgress = (credits: any) => {
        if (!!credits) {
            let {left, used} = credits;
            const totalCredits = used + left;
            return Math.round((used / totalCredits) * 100);
        }
        return 0;
    }

    return (
        <>
            <Grid item xs={12} >
                <Paper elevation={0} variant="outlined" sx={{ my: 2 }}>
                    <List sx={ props.deletable ? { py: 0 } : { pt: 0 }}>
                        <ListItem dense style={{backgroundColor: "#f6f8fa", padding: '16px'}}>
                            <ListItemIcon sx={{ minWidth: '40px', maxWidth: "40px" }}>
                                {service?.icon}
                            </ListItemIcon>
                            <ListItemText
                                primary={t(service?.type)}
                                primaryTypographyProps={{
                                    sx: {
                                        fontSize: 16,
                                        fontWeight: 600
                                    }
                            }}  />

                            <Box>
                                <Typography variant={"body2"}>
                                    {(!!interval && !!service?.price && service?.price > 0) && t(interval) + ": " + service?.price + " " + currency + " / "}
                                    {(!!service?.price_per_unit && service?.price_per_unit > 0) && `${service?.price_per_unit} ${currency} ${t("Per_unit")} `}
                                </Typography>
                            </Box>

                            <IconButton
                                color="secondary"
                                onClick={props.onAddCredit ? props.onAddCredit : null}
                                disabled={!props.onAddCredit}
                            >
                                <AddCircleIcon fontSize={"inherit"} sx={{ pb: "2px" }} />
                            </IconButton>

                        </ListItem>

                        <ListItem>
                            <Grid container spacing={3} style={{marginTop: 2}}>
                                <Grid item xs={12} style={{textAlign: "right"}}>
                                    {returnCreditUsage(service?.credit)}
                                </Grid>
                                <Grid item xs={12}>
                                    <LinearProgress
                                        variant="determinate"
                                        value={returnCreditProgress(service?.credit)}
                                        style={{
                                            borderRadius: '5px',
                                            height: '10px',
                                            backgroundColor: "rgba(0,0,0,0.15)"
                                    }}/>
                                </Grid>
                            </Grid>
                        </ListItem>

                        { props.deletable &&
                            <ListItem sx={{ borderTop: "1px solid #e5e5e5", mt: 3 }}>
                                <Box sx={{ width: '100%', textAlign: 'right'}}>
                                    <Button
                                        color={"error"}
                                        size={"small"}
                                        variant={"contained"}
                                        disableElevation
                                        onClick={props.onDeleteModule ? props.onDeleteModule : null}
                                        disabled={!props.onDeleteModule}
                                    >
                                        {t("Delete_module")}
                                    </Button>
                                </Box>
                            </ListItem>
                        }

                    </List>
                </Paper>
            </Grid>
        </>
    )
}
