import React, {ChangeEvent} from 'react';
// MUI
import {
    Box, CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    IconButton,
    TextField,
    Typography
} from "@mui/material";
import {Close} from "@mui/icons-material";
// Unitag UI
import {Button} from "@components";
// TRANSLATION
import {useTranslation} from "react-i18next";
// API
import {APIPost} from "@api";
// Interfaces
import {SendInvite} from "@interfaces";

const {REACT_APP_API_URL} = process.env;

export default function InviteUserDialog(props: any) {

    const styles = {
        padding: {
            p: 2
        },
        errorPaper: {
            backgroundColor: "#ef5350",
            borderColor: "#c62828",
            p: 2,
            mt: 1
        },
        blockSpacing: {
            mt: 2
        },
        divider: {
            my: 2
        }
    }

    const {t} = useTranslation(['settings', 'common'])

    const {open, onClose, onSuccess, onInvite, user, selectedOrg, seatsLeft, handleError} = props;

    const [loading, setLoading] = React.useState<boolean>(false);

    const [inviteeEmail, setInviteeEmail] = React.useState<string>("");
    const [isEmailValid, setIsEmailValid] = React.useState<boolean>(false);

    // Data verification
    const validateInviteeMail = (email: string) => {
        if (email !== user.email) {
            const validation = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return validation.test(String(email));
        }
    }

    const handleInviteeEmail = (event: ChangeEvent<{ value: string }>) => {
        if (validateInviteeMail(event.target.value)) {
            setInviteeEmail(event.target.value);
            setIsEmailValid(true);
        } else {
            setIsEmailValid(false);
        }
    };

    // Send invitation
    const sendInvite = () => {
        setLoading(true);

        if (selectedOrg !== null) {
            let orgID = selectedOrg.organisation_id;

            APIPost<SendInvite>(REACT_APP_API_URL + "/org/" + orgID + "/invite", {
                invitee_email: inviteeEmail,
            }).then((data) => {
                if (data.ok) {
                    onInvite([{
                        organisation_id: orgID,
                        invite_uuid: '',
                        invitation_id: '',
                        email_user: inviteeEmail,
                        accepted: false,
                        expired: false,
                        user_role: '',
                    }]);
                    // on success
                    onSuccess();
                } else {
                    if (data.parsedBody && data.parsedBody === "not enough seats left in Organisation") {
                        handleError(t("Org_invite_error_space"))
                    } else handleError(t("Org_invite_error"))
                }
                setLoading(false);
            }).catch(() => {
                handleError(t("Org_invite_global_error"))
                setLoading(false);
            });
        }
    }

    // Close and reset
    const handleClose = () => {
        if (loading) return null;
        onClose();
    }

    const returnDialogContent = () => {
        if (loading) {
            return (
                <Typography align={"center"} fontWeight={700} sx={styles.padding}>{t("sending_invite")}</Typography>
            )
        }

        return (
            <>
                <Typography>
                    {t("Invite_user_text")}
                </Typography>

                <Typography align={"center"} fontWeight={700} sx={styles.padding}>
                    {selectedOrg?.organisation_name}
                </Typography>

                <Divider sx={styles.divider} />

                <Typography align={"center"}>
                    {t("Orga_seats_left")} : <b>{seatsLeft}</b>
                </Typography>
            </>
        )
    }

    return (
        <>
            <Dialog
                onClose={() => {
                    if (loading) return
                    handleClose()
                }}
                open={open}
                maxWidth={"sm"}
                fullWidth
            >
                <DialogTitle sx={styles.padding}>
                    <Box display="flex" alignItems="center">
                        <Box flexGrow={1}>
                            {t("Invite_user")}
                        </Box>
                        <IconButton onClick={handleClose} disabled={loading}>
                            <Close />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent sx={styles.padding}>

                    {returnDialogContent()}

                    <FormControl fullWidth variant="outlined" sx={styles.blockSpacing}>
                        <TextField
                            label={t("Invitee_email")}
                            id="invitee-email"
                            defaultValue=''
                            margin="dense"
                            variant="outlined"
                            onChange={handleInviteeEmail}
                            disabled={(seatsLeft === 0 || seatsLeft === null || seatsLeft === undefined)}
                            InputProps={{
                                readOnly: loading,
                            }}
                        />
                    </FormControl>

                    <Divider sx={{ my: 2 }} />
                    <Typography variant={"body2"} align={"center"} sx={{ mt: 2 }}>
                        {t("invite_user_helper")}
                    </Typography>
                </DialogContent>
                <DialogActions sx={styles.padding}>
                    <Button onClick={handleClose} text disabled={loading}>
                        {t("common:Cancel")}
                    </Button>
                    <Button
                        primary
                        onClick={sendInvite}
                        disabled={
                            !(isEmailValid !== undefined && isEmailValid && inviteeEmail !== '' && inviteeEmail !== null && inviteeEmail !== undefined) ||
                            loading ||
                            (seatsLeft === 0 || seatsLeft === null || seatsLeft === undefined)
                        }
                    >
                        {loading ? <><CircularProgress size={24} /></> : t("Invite")}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
