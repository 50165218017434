import React, {useEffect, useState} from "react";
// MUI
import {
    Box,
    Button,
    Alert,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Grid,
    IconButton, Paper, Stack, Step, StepLabel, Stepper,
    Typography,
    useMediaQuery
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
// Translation
import {useTranslation} from "react-i18next";
// AWS
import {Auth} from "aws-amplify";
// API
import {APIPostBlob} from "@api";
// Interfaces
import {QRCodeGeneration} from "@interfaces";
// Assets
import QRCodePlaceholder from "@assets/generator/default_preview.png";
// Code Input
import ReactCodeInput from "react-code-input";
import {useSnackbar} from "@hooks";
import {useStyles} from "@/Context/Ui/DashboardUiProvider";

const {REACT_APP_API_URL} = process.env;

export default function TwoFASetup(props: any) {

    const {t} = useTranslation(['settings', 'qrcodes', 'common']);
    const {handleError} = useSnackbar()
    const {open, onClose} = props;

    const {theme} = useStyles()
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const styles = {
        text: { color: "#4f566b" },
        padding: { p: 3 },
        code: { fontWeight: 700, textAlign: 'center', color: '#4f566b', lineHeight: 1.2, mt:1 },
        scan: { fontWeight: 700, maxWidth: '200px', textAlign: 'center', color: '#4f566b', mt: -1, lineHeight: 1.2 },
        paper: { p: 1 }
    };

    const steps = [t("Security_2fa_Setup"), t("Security_2fa_Verify")];

    const [activeStep, setActiveStep] = useState(0);
    const [qrcode2FAImg, setQRCode2FAImg] = useState<any>(QRCodePlaceholder);
    const [tokenValid, setTokenValid] = useState(false);
    const [totpChecked, setTotpChecked] = useState(false);

    useEffect(() => {
        // Request secret from AWS
        Auth.currentAuthenticatedUser()
            .then(user => {

                Auth.setupTOTP(user).then(code => {
                    const payloadText = "otpauth://totp/" + user.username + "?secret=" + code + "&issuer=Unitag";

                    const qrcode: QRCodeGeneration = {
                        settings: {
                            frame: null,
                            template_id: '',
                            redundancy: 'M',
                            eyes: {
                                type: "leaf",
                                color_ext_top_left: '',
                                color_ext_top_right: '',
                                color_ext_bottom_left: '',
                                color_int_bottom_left: '',
                                color_int_top_right: '',
                                color_int_top_left: ''
                            },
                            layout: {
                                gradient_type: '',
                                type: 'single_color',
                                color_background: '#ffffff',
                                color_one: '#5393c1',
                                color_two: '#5393c1',
                                color_shadow: '#5393c1',
                                force_shadow: 'W' // W, M, S
                            },
                            logo: {excavate: false, url: '', width: 0, height: 0, x: 0, x_norm: 0, y: 0, y_norm: 0},
                            background: {url: '', contrast: 0.0, brightness: 0.0},
                            modules: {type: "simple"}
                        },
                        data: {
                            resolution: 'static',
                            type: 'text',
                            text: payloadText,
                            textRenderRaw: true
                        }
                    }

                    APIPostBlob<any>(REACT_APP_API_URL + "/qrcodes/preview", qrcode).then((data) => {
                        if (data.rawBlob !== undefined) {
                            setQRCode2FAImg(URL.createObjectURL(data.rawBlob));
                        }
                    }).catch(() => handleError(t("qrcodes:error_get_qrcode_preview")));

                })
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const handleNext = () => {
        setActiveStep((prevStep) => prevStep + 1);
    }

    const handlePrevious = () => {
        setActiveStep((prevStep) => prevStep - 1);
    }

    const handleClose = () => {
        setActiveStep(0)
        onClose()
    }

    const verifyTOTP = (token: string) => {

        if (token.length < 6) {
            return undefined;
        }

        Auth.currentAuthenticatedUser()
            .then(user => {
                Auth.verifyTotpToken(user, token).then(() => {
                    Auth.setPreferredMFA(user, 'TOTP').then();

                    setTotpChecked(true);
                    setTokenValid(true);

                }, () => {
                    setTotpChecked(true);
                    setTokenValid(false);
                })

            })
            .catch(() => {
                handleError(t("common:unknown_error"))
            })
    }

    const returnStepContent = () => {
        switch (activeStep) {
            case 0:
                return (
                    <Stack justifyContent="center" alignItems="center">
                        <Typography variant='body1' sx={styles.text}>
                            {t('Security_2fa_intro')}
                        </Typography>
                        <br/>
                        <Typography variant='body1' sx={styles.text}>
                            {t('Security_2fa_intro2')}
                        </Typography>
                        <br/>
                        <Paper variant={'outlined'} sx={styles.paper}>
                            <img
                                height='200px'
                                width='200px'
                                src={qrcode2FAImg}
                                alt=''
                                className="qrcodePreview"
                            />
                            <Typography variant={'body1'} sx={styles.scan}>
                                {t('Security_2fa_scan')}
                            </Typography>
                        </Paper>
                    </Stack>
                )
            case 1:
                return (
                    <>
                        <Stack justifyContent="center" alignItems="center">
                            <Typography variant='body1' sx={styles.text}>
                                {t('Security_2fa_validate')}
                            </Typography>
                            <br/>
                            <Paper variant={'outlined'} sx={{padding: 2}}>
                                <ReactCodeInput
                                    type='text'
                                    fields={6}
                                    onChange={(e) => verifyTOTP(e)}
                                    name="verificationCodeInput"
                                    inputMode={'latin'}
                                />
                                <Typography
                                    variant={'body1'}
                                    sx={styles.code}
                                >
                                    {t('Security_2fa_enter_code')}
                                </Typography>
                            </Paper>
                            <br/>
                            <br/>
                            {
                                !totpChecked ?
                                    null
                                    :
                                    tokenValid ?
                                        <Alert severity={'success'}>
                                            {t('Security_2fa_valid')})
                                        </Alert>
                                        :
                                        <Alert severity={'error'}>
                                            {t('Security_2fa_error')})
                                        </Alert>
                            }
                        </Stack>

                    </>
                )
        }
    }

    return (
        <Dialog
            key={'2fa_setup'}
            fullWidth
            fullScreen={fullScreen}
            maxWidth={'sm'}
            open={open}
            keepMounted
            onClose={handleClose}
            aria-labelledby="setup-2fa-for-unitag"
            aria-describedby="alert-dialog-setup-2fa-for-unitag"
            >
            <DialogTitle sx={styles.padding}>
                <Box display="flex" alignItems="center">
                    <Box flexGrow={1}>{t("Security_activate_2fa")}</Box>
                    <IconButton onClick={handleClose}>
                        <CloseIcon/>
                    </IconButton>
                </Box>
            </DialogTitle>

            <DialogContent sx={styles.padding}>
                <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((label, index) => {
                        const stepProps: {completed?: boolean} = {};
                        return (
                            <Step key={index} {...stepProps}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        )
                    })}
                </Stepper>

                <Grid container justifyContent={'center'} alignItems="center" mt={3}>
                    <Grid item xs={12} md={10}>
                        {returnStepContent()}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions sx={styles.padding}>
                <div style={{ flexGrow: 1 }}>
                    <Button
                        disableElevation
                        onClick={handleClose}
                        color="inherit"
                    >
                        {t("Cancel")}
                    </Button>
                </div>
                <div>
                    { activeStep !== 0 &&
                        <Button
                            onClick={handlePrevious}
                            color="inherit"
                        >
                            {t("common:Back")}
                        </Button>
                    }
                    &nbsp;&nbsp;

                    <Button
                        onClick={activeStep === steps.length -1 ? handleClose : handleNext}
                        color={'primary'}
                        variant={"contained"}
                        disabled={activeStep === steps.length -1 && !tokenValid}
                        disableElevation
                    >
                        {activeStep === steps.length - 1 ? t("Security_2fa_finish") : t("common:Next")}
                    </Button>
                </div>
            </DialogActions>
        </Dialog>
    )
}
