import React from "react";
// MUI
import {Grid, Typography} from "@mui/material";
// RCI
import ReactCodeInput from "react-code-input";
// Components
import {Button} from "@components";


export default function ValidationCodeDisplay(props: any) {
    const {t} = props

    return (
        <Grid item style={{padding: '24px'}}>
            <Grid
                container
                spacing={2}
                direction="column"
                justifyContent="center"
                alignItems="center"
                style={{marginTop: "50px", marginBottom: "50px"}}
            >
                <Grid item xs={12}>
                    <Typography align={"center"}>
                        {t("code_send_to")}<b>{props.email}</b>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <ReactCodeInput
                        type='text'
                        fields={6}
                        onChange={props.handleVerificationCodeChange}
                        name="verificationCodeInput"
                        inputMode={'latin'}
                    />
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Button secondary fullWidth onClick={props.verifyCode}>
                    {t("Verify_code")}
                </Button>
            </Grid>
        </Grid>
    );
}
