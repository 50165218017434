import React from "react"
import {SnackbarProvider} from "notistack";

export default function ErrorBoundaryProvider(props: any) {

    return (
        <SnackbarProvider
            autoHideDuration={4500}
            maxSnack={3}
        >
            {props.children}
        </SnackbarProvider>
    )
}