import React from "react";
// MUI
import {
    Box,
    CircularProgress,
    FormControl,
    Grid,
    InputLabel, MenuItem,
    Select,
    TextField,
} from "@mui/material";
// Components
import {Button} from "@components";
// Types
import {CompanyIndustry, CompanyPosition} from "@interfaces";

export default function CompanyAccountForm(props: any) {

    const {canFinalize, credentials, setCredentials, loading, finish, t} = props

    const company_position: CompanyPosition[] = [
        {key: 1, short: "Cust-Sv / Director", name: t("Customer_service_director")},
        {key: 2, short: "Cust-Sv / VP", name: t("Customer_service_vp")},
        {key: 3, short: "Cust-Sv / Manager", name: t("Customer_service_manager")},
        {key: 4, short: "Data - Director", name: t("Data_analytics_director")},
        {key: 5, short: "Data - Manager", name: t("Data_analytics_manager")},
        {key: 6, short: "Dev", name: t("Developer")},
        {key: 7, short: "Dgt-Mark / Director", name: t("Digital_marketing_director")},
        {key: 8, short: "Dgt-Mark / VP", name: t("Digital_marketing_vp")},
        {key: 9, short: "Dgt-Mark / Manager", name: t("Digital_marketing_manager")},
        {key: 10, short: "Editor", name: t("Editor")},
        {key: 11, short: "Finance / Director", name: t("Finance_director")},
        {key: 12, short: "Finance / Manager", name: t("Finance_manager")},
        {key: 13, short: "HR / Director", name: t("HR_Director")},
        {key: 14, short: "HR / VP", name: t("HR_VP")},
        {key: 15, short: "HR / Manager", name: t("HR_Manager")},
        {key: 16, short: "HR / Coord", name: t("HR_Coordinator")},
        {key: 17, short: "IT / Director", name: t("IT_Director")},
        {key: 18, short: "IT / Manager", name: t("IT_Manager")},
        {key: 19, short: "Mark / CMO", name: t("Marketing_CMO")},
        {key: 20, short: "Mark / Coord", name: t("Marketing_Coordinator")},
        {key: 21, short: "Mark / Director", name: t("Marketing_Director")},
        {key: 22, short: "Mark / Manager", name: t("Marketing_Manager")},
        {key: 23, short: "Mark / VP", name: t("Marketing_VP")},
        {key: 24, short: "Op / Director", name: t("Operations_Director")},
        {key: 25, short: "Op / Manager", name: t("Operations_Manager")},
        {key: 26, short: "CEO", name: t("Owner/CEO")},
        {key: 27, short: "Personal", name: t("Personnal/Blogger")},
        {key: 28, short: "PR / Director", name: t("PR_Director")},
        {key: 29, short: "PR / VP", name: t("PR_VP")},
        {key: 30, short: "PR / Manager", name: t("PR_Manager")},
        {key: 31, short: "PR / Coord", name: t("PR_Coordinator")},
        {key: 32, short: "Prod / CPO", name: t("Product_CPO")},
        {key: 33, short: "Prod / Director", name: t("Product_Director")},
        {key: 34, short: "Prod / VP", name: t("Product_VP")},
        {key: 35, short: "Prod / Manager", name: t("Product_Manager")},
        {key: 36, short: "Recruiter", name: t("Recruiter")},
        {key: 37, short: "Sales-Acc / Director", name: t("Sales_Account_Director")},
        {key: 38, short: "Sales-Acc / Exec", name: t("Sales_Account_Executive")},
        {key: 39, short: "Sales-Acc / Manager", name: t("Sales_Account_Manager")},
        {key: 40, short: "Secu / Director", name: t("Security_Director")},
        {key: 41, short: "Secu / Manager", name: t("Security_Manager")},
        {key: 42, short: "SocMed / Director", name: t("Social_Media_Director")},
        {key: 43, short: "SocMec / Manager", name: t("Social_Media_Manager")},
        {key: 44, short: "SocMed / Coord", name: t("Social_Media_Coordinator")},
        {key: 45, short: "CTO", name: t("Technology_CTO")},
        {key: 46, short: "Other", name: t("Other")}
    ];
    const company_industry: CompanyIndustry[] = [
        {key: 1, short: "Aerospace", name: t("Aerospace")},
        {key: 2, short: "Transport", name: t("Transport")},
        {key: 3, short: "Computer", name: t("Computer")},
        {key: 4, short: "Telecommunication", name: t("Telecommunication")},
        {key: 5, short: "Agriculture", name: t("Agriculture")},
        {key: 6, short: "Construction", name: t("Construction")},
        {key: 7, short: "Education", name: t("Education")},
        {key: 8, short: "Pharmaceutical", name: t("Pharmaceutical")},
        {key: 9, short: "Food", name: t("Food")},
        {key: 10, short: "Health_care", name: t("Health_care")},
        {key: 11, short: "Hospitality", name: t("Hospitality")},
        {key: 12, short: "Entertainment", name: t("Entertainment")},
        {key: 13, short: "News_Media", name: t("News_media")},
        {key: 14, short: "Energy", name: t("Energy")},
        {key: 15, short: "Manufacturing", name: t("Manufacturing")},
        {key: 16, short: "Music", name: t("Music")},
        {key: 17, short: "Mining", name: t("Mining")},
        {key: 18, short: "World_wide_web", name: t("World_wide_web")},
        {key: 19, short: "Electronics", name: t("Electronics")},
        {key: 20, short: "Others", name: t("Others")}
    ];

    return (
        <Box>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined">
                        <TextField
                            autoFocus
                            variant={"outlined"}
                            onChange={(e) => setCredentials({ ...credentials, attributes:
                                    { ...credentials.attributes, name: e.target.value }
                            })}
                            id="name"
                            label={t("First_name")}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined">
                        <TextField
                            onChange={(e) => setCredentials({ ...credentials, attributes:
                                    { ...credentials.attributes, family_name: e.target.value }
                            })}
                            id="surname"
                            label={t("Surname")}
                            variant="outlined"
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined">
                        <TextField
                            onChange={(e) => {
                                setCredentials({...credentials, attributes: {
                                        ...credentials.attributes, "custom:company": e.target.value
                                    }
                                })
                            }}
                            id="company_name"
                            label={t("Company_name")}
                            variant="outlined"
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel id="label_company_position">{t("Company_position")}</InputLabel>
                        <Select
                            labelId="label_company_position"
                            id="company_position"
                            label={t("Company_position")}
                            onChange={(e) => {
                                setCredentials({...credentials, attributes: {
                                    ...credentials.attributes, "custom:company_position": e.target.value
                                    }
                                })
                            }}
                        >
                            {company_position?.map((option: CompanyPosition) => {
                                return (
                                    <MenuItem key={option.key} value={option.short}>
                                        {option.name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel id="label_company_size">{t("Company_size")}</InputLabel>
                        <Select
                            labelId="label_company_size"
                            id="company_size"
                            label={t("Company_size")}
                            onChange={(e) => {
                                setCredentials({...credentials, attributes: {
                                        ...credentials.attributes, "custom:company_size": e.target.value
                                    }
                                })
                            }}
                        >
                            {["1-9", "10-99", "100-249", "250-999", "1000+"].map((option: string) => {
                                return (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel id="label_company_industry">{t("Company_industry")}</InputLabel>
                        <Select
                            labelId="label_company_industry"
                            id="company_industry"
                            label={t("Company_industry")}
                            onChange={(e) => {
                                setCredentials({...credentials, attributes: {
                                        ...credentials.attributes, "custom:company_industry": e.target.value
                                    }
                                })
                            }}
                        >
                            {company_industry?.map((option: CompanyIndustry) => {
                                return (
                                    <MenuItem key={option.key} value={option.short}>
                                        {option.name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Button secondary fullWidth disabled={!canFinalize || loading} onClick={finish}>
                        {loading ? <CircularProgress size={21}/> : t("Finish")}
                    </Button>
                </Grid>
            </Grid>

        </Box>
    );
}
