import React, {useEffect} from 'react';
import {Grid} from '@mui/material';
// AUTH
import {Auth} from "aws-amplify";
// Landing
import WineLabelLanding from "@apps/WineLabel/WineLabelLanding";
import {useUser} from "@context";
import {useTranslation} from "react-i18next";
import {useSnackbar} from "@hooks";

const {REACT_APP_WINELABEL_DOMAIN, REACT_APP_WINELABEL_ENVIRONMENT} = process.env;

export default function WineLabelAppComponent() {
    const [currentURL, setCurrentURL] = React.useState('');
    const [iframeHeight, setIframeHeight] = React.useState(0);
    const {user, getUser, organisationAsMember, getOrganisationAsMember} = useUser()
    const {t} = useTranslation(["common"])
    const {handleError} = useSnackbar()

    const styles = {
        contentIFrame: {
            flexGrow: 1,
            zIndex: 1,
            margin: 0,
            height: "100%",
        },
        iframe: {
            overflow: "hidden",
            width: "100%",
        }
    };

    useEffect(() => {
        getUser()
        window.addEventListener("message", wineLabelMessageHandler, false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {
        if (!!user) {
            if (!!user.org) getOrganisationAsMember()
            if (user?.account_type === "standard" || user?.account_type === "gold" || user?.account_type === "platinum" || (user?.org !== undefined && user?.org !== "")) {
                Auth.currentSession().then((sess) => {
                    setIframeHeight(window.outerHeight - 64);
                    setCurrentURL(REACT_APP_WINELABEL_DOMAIN + sess.getIdToken().getJwtToken() + REACT_APP_WINELABEL_ENVIRONMENT);
                }, () => {
                    handleError(t("error_get_identification"))
                }).catch(() => handleError(t("error_get_identification")))
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    const wineLabelMessageHandler = (event: any) => {
        // we only want message from vcard pro
        if (event.origin !== REACT_APP_WINELABEL_DOMAIN) {
            return
        }
        if (typeof event.data === 'string' && event.data === "refreshToken") {
            Auth.currentSession().then((sess) => {
                event.source.postMessage(sess.getIdToken().getJwtToken(), REACT_APP_WINELABEL_DOMAIN)
            }, () => {
                handleError(t("error_get_identification"))
            }).catch(() => handleError(t("error_get_identification")))
        }
    }

    if (!user) return <></>

    return (
        <main className="eq" style={{zIndex: 0}}>
            {
                user?.account_type === "free" && !organisationAsMember ?
                    <></>
                : user?.account_type === "standard" || user?.account_type === "gold" || user?.account_type === "platinum" || (user.org !== undefined && user.org !== "") ?
                    <Grid sx={styles.contentIFrame} style={{minHeight: iframeHeight}}>
                        <iframe
                            style={{minHeight: iframeHeight, ...styles.iframe}}
                            referrerPolicy="strict-origin-when-cross-origin"
                            src={currentURL}
                            title="Unitag ChefCloud"
                            allow="clipboard-write"
                        />
                    </Grid>
                : (user?.account_type === "free" && !organisationAsMember) ?
                    <WineLabelLanding />
                : null
            }
        </main>
    );
}
