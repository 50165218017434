import React from "react";
// MUI
import {Box, Dialog, DialogContent, DialogTitle, IconButton} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export default function CreateDomainDialog(props: any) {

    const {renderStepper, open, onClose, t} = props

    return (
        <Dialog
            maxWidth="md"
            fullWidth
            open={open}
        >
            <DialogTitle>
                <Box display="flex" alignItems="center">
                    <Box flexGrow={1}>{t("Domain_names_create")}</Box>
                    <IconButton onClick={onClose}>
                        <CloseIcon/>
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent>
                {renderStepper()}
            </DialogContent>
        </Dialog>
    )
}