import React, {useState} from "react"
import {Link} from "react-router-dom";
// MUI
import {
    Divider,
    Grid,
    List,
    ListItem, ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Menu, MenuItem,
    Paper, Skeleton,
    Typography
} from "@mui/material";
import {ArrowDropDown, Receipt} from "@mui/icons-material";
import StarIcon from "@mui/icons-material/Star";
// Unitag UI
import {Button} from "@components";
// Interfaces
import {PlanAccountType} from "@interfaces";
// Static
import {
    freeSubscription,
    goldSubscription,
    platinumSubscription,
    standaloneSubscription,
    standardSubscription
} from "@/constants/plans_en";
// Components
import {UnsubscribeFromModuleDialog} from "./dialogs";
// API
import {APIPost} from "@api";

const {REACT_APP_API_URL} = process.env;

export default function BillingOverviewInformation(props: any) {

    const {
        canAccessPayment,
        handleSuccess, handleError,
        currentInvoice, currentOrg,
        user,
        setConfirmUnsubscribe,
        renewalDate,
        paymentLoading,
        setLoading,
        t
    } = props

    const styles = {
        planTypeWrapper: {
            border: "1px solid rgba(0, 0, 0, 0.12)",
            backgroundColor: "#f6f8fa",
            borderRadius: '4px'
        },
        cancelConfirmationText: {
            color: 'rgb(226,120,33)',
            fontSize: '0.9rem',
            fontStyle: 'italic'
        },
        skeleton: { borderRadius: "8px" }
    }

    const [openModuleUnsubscribe, setModuleUnsubscribe] = useState<boolean>(false);
    const [unsubscribedModule, setUnsubscribedModule] = useState<string>("");
    const [anchorInvoiceMenu, setAnchorInvoiceMenu] = useState<null | HTMLElement>(null);

    // Unsubscribe from module
    const handleModuleUnsubscribe = () => {
        setLoading(true);
        APIPost<any>(REACT_APP_API_URL + "/billing/unsubscribe/" + unsubscribedModule, null).then((data) => {
            if (!data.ok) {
                handleError(t("error_unsubscribe_from_module"))
                return
            }

            handleSuccess(t("success_unsubscribe_from_module"))
            setLoading(false);
            setConfirmUnsubscribe(false);
            window.location.href = "/settings?billing";
        }).catch(() => handleError(t("error_unsubscribe_from_module")));
    }

    // Display
    const renderBillingBlocks = () => {

        const returnCurrency = (amount: any) => {
            if (!!currentInvoice && !!currentInvoice.currency) {
                switch (currentInvoice.currency) {
                    case "eur": return `${amount}€`
                    case "gbp": return `£${amount}`
                    default: return `$${amount}`
                }
            } else return `$${amount}`
        }

        const renderRenewalOrTrial = () => {
            if (currentInvoice.is_in_trial) {
                return (
                    <>
                        <Typography variant="body2">
                            {t("Trial_period")}{currentInvoice.trial_days_left}{' '}
                            {
                                currentInvoice.trial_days_left > 1 ?
                                    t("Days") : t("Day")
                            }
                            {' '}
                            {t("Left")}
                        </Typography>
                    </>
                );
            }

            return (
                <>
                    <Typography variant="body2">
                        {t("Renewal_date")}
                        {renewalDate}
                    </Typography>
                </>
            );
        }

        if (paymentLoading) {
            // loading...
            return <Skeleton variant={"rectangular"} height={250} sx={styles.skeleton} />
        } else if (!!currentOrg && currentOrg.is_root_organisation && currentOrg.role !== "OWNER") {
            // is member of an org
            return (
                <Grid item xs={12}>
                    <ListItem dense sx={styles.planTypeWrapper}>
                        <ListItemText
                            primary={t("Billing_orga_payment")}
                            secondary={t("Billing_orga_payment_text")}
                        />
                    </ListItem>
                </Grid>
            )
        } else {
            // is OWNER of the org
            return (
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Paper sx={{ p: 3 }} elevation={0} variant="outlined">
                            <Grid container justifyContent="space-between">
                                <Grid item xs={'auto'}>
                                    <Typography variant="body2" component="h6">
                                        {t("Current_monthly_invoice")}
                                    </Typography>
                                    <Typography variant="h6" component="p" style={{marginBottom: '16px'}}>
                                        {returnCurrency(currentInvoice.amount_due / 100)}
                                    </Typography>
                                    {renderRenewalOrTrial()}
                                </Grid>

                            </Grid>
                        </Paper>
                    </Grid>

                    {renderExtraModules()}
                </Grid>
            )
        }
    }

    const renderExtraModules = () => {

        const handleOpenUnsubscribeDialog = (id: string) => {
            setModuleUnsubscribe(true);
            setUnsubscribedModule(id);
        }

        if ((!currentInvoice.items || currentInvoice.items?.length === 0 || currentInvoice.items?.length === null) ||
            (!!currentInvoice.items && currentInvoice.items?.length === 1 && currentInvoice.items[0].type === "")
        ) {
            return <></>
        } else {
            return (
                <Grid item xs={12}>
                    <Paper sx={{ p: 3 }} elevation={0} variant="outlined">
                        <Typography variant="body2" component="h6">
                            {t("List_active_modules")}
                        </Typography>

                        <List>
                            {currentInvoice.items?.map((item: any, index: number) => {
                                if (item.type !== "") {
                                    return (
                                        <ListItem key={index}>
                                            <ListItemText>
                                                <Typography fontWeight={700}>
                                                    {item.type}
                                                </Typography>
                                            </ListItemText>
                                            <ListItemSecondaryAction>
                                                <Grid container alignItems="flex-end" justifyContent="center">
                                                    <Grid item>
                                                        <Button small spacing
                                                                onClick={() => handleOpenUnsubscribeDialog(item.sub_id)}
                                                                disabled={item.type === "domains" || item.type === "healthchecks"}
                                                        >
                                                            {t("Cancel_module")}
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    );
                                }
                                return <></>;
                            })}
                        </List>
                    </Paper>
                </Grid>
            );
        }
    }

    const renderInvoiceDownloader = () => {

        const downloadLatestInvoice = (path: string) => {
            window.open(path, "_blank")?.focus();
            setAnchorInvoiceMenu(null);
        }

        if (currentInvoice.invoice_pdf !== "" && !!currentInvoice.invoice_pdf) {
            let pdf: string = currentInvoice.invoice_pdf;

            return (
                <>
                    <Button
                        secondary
                        text
                        startIcon={<Receipt />}
                        endIcon={<ArrowDropDown />}
                        onClick={(e: any) => setAnchorInvoiceMenu(e.currentTarget)}
                    >
                        {t("Download_past_invoice")}
                    </Button>

                    <Menu
                        open={Boolean(anchorInvoiceMenu)}
                        anchorEl={anchorInvoiceMenu}
                        onClose={() => setAnchorInvoiceMenu(null)}
                    >
                        <MenuItem onClick={() => downloadLatestInvoice(pdf)}>
                            {t("Download_latest_invoice")}
                        </MenuItem>
                        <MenuItem disabled>
                            {t("Download_all_invoice")}
                        </MenuItem>
                        <MenuItem disabled>
                            {t("Download_invoice_at_date")}
                        </MenuItem>
                    </Menu>
                </>
            )
        }
    }

    const renderPlan = () => {

        const accountType = (account_type: string): PlanAccountType => {
            let starsIcon: any = [];
            let primaryText: string = '';
            let secondaryText: string = '';
            let subscriptionType: string = freeSubscription;

            switch (account_type) {
                case freeSubscription:
                case "free":
                    primaryText = t("Free");
                    secondaryText = t("Upgrade_paid_text");
                    subscriptionType = freeSubscription;
                    break;
                case standaloneSubscription:
                case "standalone":
                    primaryText = "Standalone";
                    secondaryText = t("Upgrade_paid_text");
                    subscriptionType = standaloneSubscription;
                    break;
                case standardSubscription:
                case "standard":
                    primaryText = t("Standard");
                    secondaryText = t("Upgrade_gold_text");
                    starsIcon.push(<StarIcon fontSize="small"/>);
                    subscriptionType = standardSubscription;
                    break;
                case goldSubscription:
                case "gold":
                    primaryText = t("Gold");
                    secondaryText = t("Upgrade_plat_text");
                    starsIcon.push(<StarIcon key={0} fontSize="small"/>);
                    starsIcon.push(<StarIcon key={1} fontSize="small"/>);
                    subscriptionType = goldSubscription;
                    break;
                case platinumSubscription:
                case "platinum":
                    primaryText = t("Platinum");
                    secondaryText = t("Upgrade_highest_text");
                    starsIcon.push(<StarIcon key={0} fontSize="small"/>);
                    starsIcon.push(<StarIcon key={1} fontSize="small"/>);
                    starsIcon.push(<StarIcon key={2} fontSize="small"/>);
                    subscriptionType = platinumSubscription;

                    break;
            }

            return {
                planTypePrimaryText: primaryText,
                planTypeSecondaryText: secondaryText,
                starsIcon: starsIcon,
                subscriptionType: subscriptionType
            };
        }

        let _account_type: string = '';
        let is_user: boolean = false;

        if (currentOrg !== null && currentOrg.org_account_type !== undefined && currentOrg.role !== "OWNER") {
            _account_type = currentOrg.org_account_type;
        } else if (user?.account_type !== undefined) {
            _account_type = user?.account_type;
            is_user = true;
        }

        const _planAccountType: PlanAccountType = accountType(_account_type);

        return (
            <>
                <ListItem dense sx={styles.planTypeWrapper}>
                    <ListItemIcon>
                        {_planAccountType.starsIcon}
                    </ListItemIcon>
                    <ListItemText
                        primary={_planAccountType.planTypePrimaryText}
                        secondary={_planAccountType.planTypeSecondaryText}
                        style={{paddingLeft: '16px'}}
                    />
                    {
                        (is_user && _planAccountType.subscriptionType !== platinumSubscription) &&
                        <ListItemSecondaryAction>
                            <Button primary component={Link} to="/plans">{t("Upgrade")}</Button>
                        </ListItemSecondaryAction>
                    }
                </ListItem>
            </>
        );
    }

    const renderCancellationText = () => {

        const returnCancellationDate = (): string => {
            return new Date(currentInvoice.cancel_at * 1000).toLocaleString(navigator.language);
        }

        if (currentInvoice.cancel_at !== 0) {
            return (
                <ListItem>
                    <Typography sx={styles.cancelConfirmationText}>
                        {t('Cancel_confirmation')}
                        {returnCancellationDate()}
                    </Typography>
                </ListItem>
            )
        } else return <></>
    }

    // If disabled
    if (!canAccessPayment) return <></>

    return (
        <>
            <Grid item xs={12} sx={{ py: 2 }}>
                <Divider textAlign={"left"}>
                    <Typography variant={"h6"} component={"p"}>
                        {t('Billing_information')}
                    </Typography>
                </Divider>
            </Grid>

            <Grid item xs={12}>
                {!paymentLoading && renderInvoiceDownloader()}
            </Grid>

            <Grid item xs={12}>
                {renderBillingBlocks()}
            </Grid>

            <Grid item xs={12}>
                <List>
                    {renderPlan()}
                    {renderCancellationText()}
                </List>
            </Grid>

            <UnsubscribeFromModuleDialog
                open={openModuleUnsubscribe}
                onClose={() => setModuleUnsubscribe(false)}
                onUnsubscribe={handleModuleUnsubscribe}
                {...{t}}
            />
        </>
    )
}