
function regexEmail(text: any) {
    if (text && text !== "") {
        // ## includes non-utf8 characters support
        // eslint-disable-next-line no-control-regex
        let reg = new RegExp("^(?:(?:[a-zA-Z]|\\d|[!#$%&'*+\\-\\/=?^_`{|}~]|[ -\uD7FF豈-\uFDCFﷰ-\uFFEF])+(?:\\.([a-zA-Z]|\\d|[!#$%&'*+\\-\\/=?^_`{|}~]|[ -\uD7FF豈-\uFDCFﷰ-\uFFEF])+)*|\"(?:(?:(?:[ \t]*\r\n)?[ \t]+)?(?:(?:[\u0001-\b\u000B\f\u000E-\u001F\u007F]|!|[#-[]|[]-~]|[ -\uD7FF豈-\uFDCFﷰ-\uFFEF])|(?:[\u0001-\t\u000B\f\r-\u007F]|[ -\uD7FF豈-\uFDCFﷰ-\uFFEF])))*(?:(?:[ \t]*\r\n)?([ \t])+)?\")@(?:(?:(?:[a-zA-Z]|\\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(?:[a-zA-Z]|\\d|[ -\uD7FF豈-\uFDCFﷰ-\uFFEF])(?:[a-zA-Z]|\\d|-|\\.|~|[ -\uD7FF豈-\uFDCFﷰ-\uFFEF])*(?:[a-zA-Z]|\\d|[ -\uD7FF豈-\uFDCFﷰ-\uFFEF]))\\.)+(?:(?:[a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(?:[a-zA-Z]|[ -\uD7FF豈-\uFDCFﷰ-\uFFEF])(?:[a-zA-Z]|\\d|-|\\.|~|[ -\uD7FF豈-\uFDCFﷰ-\uFFEF])*(?:[a-zA-Z]|[ -\uD7FF豈-\uFDCFﷰ-\uFFEF]))\\.?$")
        return reg.test(text);
    }
    return true
}

function regexValidUrl(text: any) {
    if (text && text !== "") {
        // eslint-disable-next-line no-control-regex
        let reg = new RegExp("^(ftp|http|https):\\/\\/[^ \"]+$")
        return reg.test(text)
    }

    return true
}

function regexPhoneNumber(number: any) {
    if (number && number !== "") {
        // eslint-disable-next-line no-control-regex
        let reg = new RegExp("^[+]?[(]?[0-9]{3}[)]?[-\\s.]?[0-9]{3}[-\\s.]?[0-9]{4,6}$")
        return reg.test(number)
    }
    return true
}

function includesNumber(text: any) {
    if (text && text !== "") {
        let reg = /\d/
        return reg.test(text)
    }
    return true
}

function includesSpecialCharacters(text: any) {
    if (text && text !== "") {
        let reg = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/
        return reg.test(text)
    }
    return true
}

function checkLength(text: any, desiredLength: number) {
    if (!text) return false
    return (text.length >= desiredLength && text.length <= 48)
}

function includesLowercase(text: any) {
    if (!text) return false
    return text.toUpperCase() !== text
}

function includesUppercase(text: any) {
    if (!text) return false
    return text.toLowerCase() !== text
}

export {
    includesNumber,
    regexPhoneNumber,
    regexEmail,
    regexValidUrl,
    includesSpecialCharacters,
    checkLength,
    includesUppercase,
    includesLowercase,
}
